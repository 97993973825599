// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllPaymentRequest = createAction('GET_ALL_PAYMENT_REQUEST');
export const getAllPaymentSuccess = createAction('GET_ALL_PAYMENT_SUCCESS');
export const getAllPaymentFailure = createAction('GET_ALL_PAYMENT_FAILURE');

export const createPaymentRequest = createAction('CREATE_PAYMENT_REQUEST');
export const createPaymentSuccess = createAction('CREATE_PAYMENT_SUCCESS');
export const createPaymentFailure = createAction('CREATE_PAYMENT_FAILURE');
export const resetCreatePaymentState = createAction('RESET_CREATE_PAYMENT_STATE');

export const updatePaymentRequest = createAction('UPDATE_PAYMENT_REQUEST');
export const updatePaymentSuccess = createAction('UPDATE_PAYMENT_SUCCESS');
export const updatePaymentFailure = createAction('UPDATE_PAYMENT_FAILURE');
export const resetUpdatePaymentState = createAction('RESET_UPDATE_PAYMENT_STATE');

export const deletePaymentRequest = createAction('DELETE_PAYMENT_REQUEST');
export const deletePaymentSuccess = createAction('DELETE_PAYMENT_SUCCESS');
export const deletePaymentFailure = createAction('DELETE_PAYMENT_FAILURE');
export const resetDeletePaymentState = createAction('RESET_DELETE_PAYMENT_STATE');

export const resetPaymentState = createAction('RESET_PAYMENT_STATE');
