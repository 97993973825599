// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllArticleRequest = createAction('GET_ALL_ARTICLE_REQUEST');
export const getAllArticleSuccess = createAction('GET_ALL_ARTICLE_SUCCESS');
export const getAllArticleFailure = createAction('GET_ALL_ARTICLE_FAILURE');

export const createArticleRequest = createAction('CREATE_ARTICLE_REQUEST');
export const createArticleSuccess = createAction('CREATE_ARTICLE_SUCCESS');
export const createArticleFailure = createAction('CREATE_ARTICLE_FAILURE');
export const resetCreateArticleState = createAction('RESET_CREATE_ARTICLE_STATE');

export const updateArticleRequest = createAction('UPDATE_ARTICLE_REQUEST');
export const updateArticleSuccess = createAction('UPDATE_ARTICLE_SUCCESS');
export const updateArticleFailure = createAction('UPDATE_ARTICLE_FAILURE');
export const resetUpdateArticleState = createAction('RESET_UPDATE_ARTICLE_STATE');

export const deleteArticleRequest = createAction('DELETE_ARTICLE_REQUEST');
export const deleteArticleSuccess = createAction('DELETE_ARTICLE_SUCCESS');
export const deleteArticleFailure = createAction('DELETE_ARTICLE_FAILURE');
export const resetDeleteArticleState = createAction('RESET_DELETE_ARTICLE_STATE');

export const resetArticleState = createAction('RESET_ARTICLE_STATE');
