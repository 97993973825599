// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllPaymentRequest: false,
  isGetAllPaymentSuccess: false,
  isGetAllPaymentFailure: false,
  getAllPaymentState: {},
  //
  isCreatePaymentRequest: false,
  isCreatePaymentSuccess: false,
  isCreatePaymentFailure: false,
  //
  isUpdatePaymentRequest: false,
  isUpdatePaymentSuccess: false,
  isUpdatePaymentFailure: false,
  //
  isDeletePaymentRequest: false,
  isDeletePaymentSuccess: false,
  isDeletePaymentFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all categories
    [Actions.getAllPaymentRequest]: (state) => ({
      ...state,
      isGetAllPaymentRequest: true,
      isGetAllPaymentSuccess: false,
      isGetAllPaymentFailure: false,
    }),
    [Actions.getAllPaymentSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllPaymentRequest: false,
      isGetAllPaymentSuccess: true,
      isGetAllPaymentFailure: false,
      getAllPaymentState: {
        ...payload,
      },
    }),
    [Actions.getAllPaymentFailure]: (state, { payload }) => ({
      ...state,
      isGetAllPaymentRequest: false,
      isGetAllPaymentSuccess: false,
      isGetAllPaymentFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create config
    [Actions.createPaymentRequest]: (state) => ({
      ...state,
      isCreatePaymentRequest: true,
      isCreatePaymentSuccess: false,
      isCreatePaymentFailure: false,
    }),
    [Actions.createPaymentSuccess]: (state, { payload }) => ({
      ...state,
      isCreatePaymentRequest: false,
      isCreatePaymentSuccess: true,
      isCreatePaymentFailure: false,
      getAllPaymentState: {
        ...state.getAllPaymentState,
        data: [
          ...state.getAllPaymentState?.data,
          payload.data,
        ],
      },
    }),
    [Actions.createPaymentFailure]: (state, { payload }) => ({
      ...state,
      isCreatePaymentRequest: false,
      isCreatePaymentSuccess: false,
      isCreatePaymentFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreatePaymentState]: (state) => ({
      ...state,
      isCreatePaymentRequest: false,
      isCreatePaymentSuccess: false,
      isCreatePaymentFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update config
    [Actions.updatePaymentRequest]: (state) => ({
      ...state,
      isUpdatePaymentRequest: true,
      isUpdatePaymentSuccess: false,
      isUpdatePaymentFailure: false,
    }),
    [Actions.updatePaymentSuccess]: (state) => ({
      ...state,
      isUpdatePaymentRequest: false,
      isUpdatePaymentSuccess: true,
      isUpdatePaymentFailure: false,
    }),
    [Actions.updatePaymentFailure]: (state, { payload }) => ({
      ...state,
      isUpdatePaymentRequest: false,
      isUpdatePaymentSuccess: false,
      isUpdatePaymentFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdatePaymentState]: (state) => ({
      ...state,
      isUpdatePaymentRequest: false,
      isUpdatePaymentSuccess: false,
      isUpdatePaymentFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete config
    [Actions.deletePaymentRequest]: (state) => ({
      ...state,
      isDeletePaymentRequest: true,
      isDeletePaymentSuccess: false,
      isDeletePaymentFailure: false,
    }),
    [Actions.deletePaymentSuccess]: (state) => ({
      ...state,
      isDeletePaymentRequest: false,
      isDeletePaymentSuccess: true,
      isDeletePaymentFailure: false,
    }),
    [Actions.deletePaymentFailure]: (state, { payload }) => ({
      ...state,
      isDeletePaymentRequest: false,
      isDeletePaymentSuccess: false,
      isDeletePaymentFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeletePaymentState]: (state) => ({
      ...state,
      isDeletePaymentRequest: false,
      isDeletePaymentSuccess: false,
      isDeletePaymentFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetPaymentState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
