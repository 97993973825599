// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllArticleRequest: false,
  isGetAllArticleSuccess: false,
  isGetAllArticleFailure: false,
  getAllArticleState: {},
  //
  isCreateArticleRequest: false,
  isCreateArticleSuccess: false,
  isCreateArticleFailure: false,
  //
  isUpdateArticleRequest: false,
  isUpdateArticleSuccess: false,
  isUpdateArticleFailure: false,
  //
  isDeleteArticleRequest: false,
  isDeleteArticleSuccess: false,
  isDeleteArticleFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all categories
    [Actions.getAllArticleRequest]: (state) => ({
      ...state,
      isGetAllArticleRequest: true,
      isGetAllArticleSuccess: false,
      isGetAllArticleFailure: false,
    }),
    [Actions.getAllArticleSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllArticleRequest: false,
      isGetAllArticleSuccess: true,
      isGetAllArticleFailure: false,
      getAllArticleState: {
        ...payload,
      },
    }),
    [Actions.getAllArticleFailure]: (state, { payload }) => ({
      ...state,
      isGetAllArticleRequest: false,
      isGetAllArticleSuccess: false,
      isGetAllArticleFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create config
    [Actions.createArticleRequest]: (state) => ({
      ...state,
      isCreateArticleRequest: true,
      isCreateArticleSuccess: false,
      isCreateArticleFailure: false,
    }),
    [Actions.createArticleSuccess]: (state, { payload }) => ({
      ...state,
      isCreateArticleRequest: false,
      isCreateArticleSuccess: true,
      isCreateArticleFailure: false,
      getAllArticleState: {
        ...state.getAllArticleState,
        data: [
          ...state.getAllArticleState?.data,
          payload.data,
        ],
      },
    }),
    [Actions.createArticleFailure]: (state, { payload }) => ({
      ...state,
      isCreateArticleRequest: false,
      isCreateArticleSuccess: false,
      isCreateArticleFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateArticleState]: (state) => ({
      ...state,
      isCreateArticleRequest: false,
      isCreateArticleSuccess: false,
      isCreateArticleFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update config
    [Actions.updateArticleRequest]: (state) => ({
      ...state,
      isUpdateArticleRequest: true,
      isUpdateArticleSuccess: false,
      isUpdateArticleFailure: false,
    }),
    [Actions.updateArticleSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateArticleRequest: false,
      isUpdateArticleSuccess: true,
      isUpdateArticleFailure: false,
      getAllArticleState: {
        ...state.getAllArticleState,
        data: [
          ...state.getAllArticleState?.data?.map((e) => {
            if (e.id === payload.data.id) {
              return payload.data;
            }
            return e;
          }),
        ],
      },
    }),
    [Actions.updateArticleFailure]: (state, { payload }) => ({
      ...state,
      isUpdateArticleRequest: false,
      isUpdateArticleSuccess: false,
      isUpdateArticleFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateArticleState]: (state) => ({
      ...state,
      isUpdateArticleRequest: false,
      isUpdateArticleSuccess: false,
      isUpdateArticleFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete config
    [Actions.deleteArticleRequest]: (state) => ({
      ...state,
      isDeleteArticleRequest: true,
      isDeleteArticleSuccess: false,
      isDeleteArticleFailure: false,
    }),
    [Actions.deleteArticleSuccess]: (state, { payload }) => ({
      ...state,
      isDeleteArticleRequest: false,
      isDeleteArticleSuccess: true,
      isDeleteArticleFailure: false,
      getAllArticleState: {
        ...state.getAllArticleState,
        data: [
          ...state.getAllArticleState?.data?.filter((e) => e.id !== payload),
        ],
      },
    }),
    [Actions.deleteArticleFailure]: (state, { payload }) => ({
      ...state,
      isDeleteArticleRequest: false,
      isDeleteArticleSuccess: false,
      isDeleteArticleFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteArticleState]: (state) => ({
      ...state,
      isDeleteArticleRequest: false,
      isDeleteArticleSuccess: false,
      isDeleteArticleFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetArticleState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
