/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllOrders({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/orders?include=products&${payload.params}` : '/orders?include=products&limit=0',
    ));
    yield put(Actions.getAllOrdersSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllOrdersFailure(messages));
    }
  }
}

function* createOrder({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/orders', payload));
    yield put(Actions.createOrderSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createOrderFailure(messages));
    }
  }
}

function* updateOrder({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/orders/${payload.id}`, payload.body));
    yield put(Actions.updateOrderSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateOrderFailure(messages));
    }
  }
}

function* deleteOrder({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/orders/${payload}`));
    yield put(Actions.deleteOrderSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteOrderFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllOrdersRequest, getAllOrders);
  yield takeLatest(Actions.createOrderRequest, createOrder);
  yield takeLatest(Actions.updateOrderRequest, updateOrder);
  yield takeLatest(Actions.deleteOrderRequest, deleteOrder);
}
