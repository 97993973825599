// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllAgenciesRequest: false,
  isGetAllAgenciesSuccess: false,
  isGetAllAgenciesFailure: false,
  getAllAgenciesState: {},
  //
  isCreateAgencyRequest: false,
  isCreateAgencySuccess: false,
  isCreateAgencyFailure: false,
  createAgencyResponse: {},
  //
  isUpdateAgencyRequest: false,
  isUpdateAgencySuccess: false,
  isUpdateAgencyFailure: false,
  //
  isDeleteAgencyRequest: false,
  isDeleteAgencySuccess: false,
  isDeleteAgencyFailure: false,
  //
  isAttachUsersToAgencyRequest: false,
  isAttachUsersToAgencySuccess: false,
  isAttachUsersToAgencyFailure: false,
  //
  isDetachUsersToAgencyRequest: false,
  isDetachUsersToAgencySuccess: false,
  isDetachUsersToAgencyFailure: false,
  //
  isGetUsersAgencyRequest: false,
  isGetUsersAgencySuccess: false,
  isGetUsersAgencyFailure: false,
  getUsersAgencyState: {},
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all agencies
    [Actions.getAllAgenciesRequest]: (state) => ({
      ...state,
      isGetAllAgenciesRequest: true,
      isGetAllAgenciesSuccess: false,
      isGetAllAgenciesFailure: false,
    }),
    [Actions.getAllAgenciesSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllAgenciesRequest: false,
      isGetAllAgenciesSuccess: true,
      isGetAllAgenciesFailure: false,
      getAllAgenciesState: {
        ...payload,
        data: payload.data
          .sort((a, b) => (a.sort > b.sort ? 0 : -1))
          .map((item) => ({
            ...item,
          })),
      },
    }),
    [Actions.getAllAgenciesFailure]: (state, { payload }) => ({
      ...state,
      isGetAllAgenciesRequest: false,
      isGetAllAgenciesSuccess: false,
      isGetAllAgenciesFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create agency
    [Actions.createAgencyRequest]: (state) => ({
      ...state,
      isCreateAgencyRequest: true,
      isCreateAgencySuccess: false,
      isCreateAgencyFailure: false,
    }),
    [Actions.createAgencySuccess]: (state, { payload }) => ({
      ...state,
      isCreateAgencyRequest: false,
      isCreateAgencySuccess: true,
      isCreateAgencyFailure: false,
      createAgencyResponse: payload,
    }),
    [Actions.createAgencyFailure]: (state, { payload }) => ({
      ...state,
      isCreateAgencyRequest: false,
      isCreateAgencySuccess: false,
      isCreateAgencyFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateAgencyState]: (state) => ({
      ...state,
      isCreateAgencyRequest: false,
      isCreateAgencySuccess: false,
      isCreateAgencyFailure: false,
      createAgencyResponse: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Update agency
    [Actions.updateAgencyRequest]: (state) => ({
      ...state,
      isUpdateAgencyRequest: true,
      isUpdateAgencySuccess: false,
      isUpdateAgencyFailure: false,
    }),
    [Actions.updateAgencySuccess]: (state) => ({
      ...state,
      isUpdateAgencyRequest: false,
      isUpdateAgencySuccess: true,
      isUpdateAgencyFailure: false,
    }),
    [Actions.updateAgencyFailure]: (state, { payload }) => ({
      ...state,
      isUpdateAgencyRequest: false,
      isUpdateAgencySuccess: false,
      isUpdateAgencyFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateAgencyState]: (state) => ({
      ...state,
      isUpdateAgencyRequest: false,
      isUpdateAgencySuccess: false,
      isUpdateAgencyFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete agency
    [Actions.deleteAgencyRequest]: (state) => ({
      ...state,
      isDeleteAgencyRequest: true,
      isDeleteAgencySuccess: false,
      isDeleteAgencyFailure: false,
    }),
    [Actions.deleteAgencySuccess]: (state) => ({
      ...state,
      isDeleteAgencyRequest: false,
      isDeleteAgencySuccess: true,
      isDeleteAgencyFailure: false,
    }),
    [Actions.deleteAgencyFailure]: (state, { payload }) => ({
      ...state,
      isDeleteAgencyRequest: false,
      isDeleteAgencySuccess: false,
      isDeleteAgencyFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteAgencyState]: (state) => ({
      ...state,
      isDeleteAgencyRequest: false,
      isDeleteAgencySuccess: false,
      isDeleteAgencyFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Attach users to agency
    [Actions.attachUsersToAgencyRequest]: (state) => ({
      ...state,
      isAttachUsersToAgencyRequest: true,
      isAttachUsersToAgencySuccess: false,
      isAttachUsersToAgencyFailure: false,
    }),
    [Actions.attachUsersToAgencySuccess]: (state) => ({
      ...state,
      isAttachUsersToAgencyRequest: false,
      isAttachUsersToAgencySuccess: true,
      isAttachUsersToAgencyFailure: false,
    }),
    [Actions.attachUsersToAgencyFailure]: (state, { payload }) => ({
      ...state,
      isAttachUsersToAgencyRequest: false,
      isAttachUsersToAgencySuccess: false,
      isAttachUsersToAgencyFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetAttachUsersToAgencyState]: (state) => ({
      ...state,
      isAttachUsersToAgencyRequest: false,
      isAttachUsersToAgencySuccess: false,
      isAttachUsersToAgencyFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Detach users to agency
    [Actions.detachUsersToAgencyRequest]: (state) => ({
      ...state,
      isDetachUsersToAgencyRequest: true,
      isDetachUsersToAgencySuccess: false,
      isDetachUsersToAgencyFailure: false,
    }),
    [Actions.detachUsersToAgencySuccess]: (state) => ({
      ...state,
      isDetachUsersToAgencyRequest: false,
      isDetachUsersToAgencySuccess: true,
      isDetachUsersToAgencyFailure: false,
    }),
    [Actions.detachUsersToAgencyFailure]: (state, { payload }) => ({
      ...state,
      isDetachUsersToAgencyRequest: false,
      isDetachUsersToAgencySuccess: false,
      isDetachUsersToAgencyFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDetachUsersToAgencyState]: (state) => ({
      ...state,
      isDetachUsersToAgencyRequest: false,
      isDetachUsersToAgencySuccess: false,
      isDetachUsersToAgencyFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Get users agency
    [Actions.getUsersAgencyRequest]: (state) => ({
      ...state,
      isGetUsersAgencyRequest: true,
      isGetUsersAgencySuccess: false,
      isGetUsersAgencyFailure: false,
    }),
    [Actions.getUsersAgencySuccess]: (state, { payload }) => ({
      ...state,
      isGetUsersAgencyRequest: false,
      isGetUsersAgencySuccess: true,
      isGetUsersAgencyFailure: false,
      getUsersAgencyState: payload,
    }),
    [Actions.getUsersAgencyFailure]: (state, { payload }) => ({
      ...state,
      isGetUsersAgencyRequest: false,
      isGetUsersAgencySuccess: false,
      isGetUsersAgencyFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetUsersAgencyState]: (state) => ({
      ...state,
      isGetUsersAgencyRequest: false,
      isGetUsersAgencySuccess: false,
      isGetUsersAgencyFailure: false,
      getUsersAgencyState: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetAgencyState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
