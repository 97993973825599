/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getProfile() {
  try {
    const response = yield call(() => axiosMicro.get('/profile'));
    yield put(Actions.getProfileUserSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getProfileUserFailure(messages));
    }
  }
}

function* updateInformationUser({ payload }) {
  try {
    const form = new FormData();
    if (payload?.name) {
      form.append('name', payload.name);
    }
    if (payload?.email) {
      form.append('email', payload.email);
    }
    if (payload?.gender) {
      form.append('gender', payload.gender);
    }
    if (payload?.marital_status) {
      form.append('marital_status', payload.marital_status);
    }
    if (payload?.birthday) {
      form.append('birthday', payload.birthday);
    }
    if (payload?.id_card) {
      form.append('id_card', payload.id_card);
    }
    if (payload?.id_card_date) {
      form.append('id_card_date', payload.id_card_date);
    }
    if (payload?.id_card_place) {
      form.append('id_card_place', payload.id_card_place);
    }
    if (payload?.phone) {
      form.append('phone', payload.phone);
    }
    if (payload?.zalo) {
      form.append('zalo', payload.zalo);
    }
    if (payload?.facebook) {
      form.append('facebook', payload.facebook);
    }
    if (payload?.address) {
      form.append('address', payload.address);
    }
    if (payload?.avatar) {
      form.append('avatar', payload.avatar);
    }
    yield call(() => axiosMicro.post(`/users/${payload.id}?_method=PATCH`, form));
    yield put(Actions.updateInformationUserSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateInformationUserFailure(messages));
    }
  }
}

function* getAllUsers({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/users?${payload.params}` : '/users?limit=0',
    ));
    yield put(Actions.getAllUsersSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllUsersFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getProfileUserRequest, getProfile);
  yield takeLatest(Actions.updateInformationUserRequest, updateInformationUser);
  yield takeLatest(Actions.getAllUsersRequest, getAllUsers);
}
