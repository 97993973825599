// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllSlidersRequest = createAction('GET_ALL_SLIDERS_REQUEST');
export const getAllSlidersSuccess = createAction('GET_ALL_SLIDERS_SUCCESS');
export const getAllSlidersFailure = createAction('GET_ALL_SLIDERS_FAILURE');

export const createSliderRequest = createAction('CREATE_SLIDER_REQUEST');
export const createSliderSuccess = createAction('CREATE_SLIDER_SUCCESS');
export const createSliderFailure = createAction('CREATE_SLIDER_FAILURE');
export const resetCreateSliderState = createAction('RESET_CREATE_SLIDER_STATE');

export const updateSliderRequest = createAction('UPDATE_SLIDER_REQUEST');
export const updateSliderSuccess = createAction('UPDATE_SLIDER_SUCCESS');
export const updateSliderFailure = createAction('UPDATE_SLIDER_FAILURE');
export const resetUpdateSliderState = createAction('RESET_UPDATE_SLIDER_STATE');

export const deleteSliderRequest = createAction('DELETE_SLIDER_REQUEST');
export const deleteSliderSuccess = createAction('DELETE_SLIDER_SUCCESS');
export const deleteSliderFailure = createAction('DELETE_SLIDER_FAILURE');
export const resetDeleteSliderState = createAction('RESET_DELETE_SLIDER_STATE');

export const resetSliderState = createAction('RESET_SLIDER_STATE');
