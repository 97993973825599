// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllFieldsRequest = createAction('GET_ALL_FIELDS_REQUEST');
export const getAllFieldsSuccess = createAction('GET_ALL_FIELDS_SUCCESS');
export const getAllFieldsFailure = createAction('GET_ALL_FIELDS_FAILURE');

export const createFieldRequest = createAction('CREATE_FIELD_REQUEST');
export const createFieldSuccess = createAction('CREATE_FIELD_SUCCESS');
export const createFieldFailure = createAction('CREATE_FIELD_FAILURE');
export const resetCreateFieldState = createAction('RESET_CREATE_FIELD_STATE');

export const updateFieldRequest = createAction('UPDATE_FIELD_REQUEST');
export const updateFieldSuccess = createAction('UPDATE_FIELD_SUCCESS');
export const updateFieldFailure = createAction('UPDATE_FIELD_FAILURE');
export const resetUpdateFieldState = createAction('RESET_UPDATE_FIELD_STATE');

export const deleteFieldRequest = createAction('DELETE_FIELD_REQUEST');
export const deleteFieldSuccess = createAction('DELETE_FIELD_SUCCESS');
export const deleteFieldFailure = createAction('DELETE_FIELD_FAILURE');
export const resetDeleteFieldState = createAction('RESET_DELETE_FIELD_STATE');

export const resetFieldState = createAction('RESET_FIELD_STATE');
