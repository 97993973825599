const config = {
  'admin.config.hetmet': 'Tất cả cấu hình',
  'admin.config.default': 'Cấu hình mặc định',
  'admin.config.watermark': 'Watermark',
  'admin.config.breadcrumb.heading': 'Quản lý cấu hình',
  'admin.config.breadcrumb.parent': 'Cấu hình',
  'admin.config.btn.add-new': 'Thêm cấu hình mới',
  'admin.config.noti.create-config.title.success': 'Thêm cấu hình mới thành công!',
  'admin.config.noti.create-config.title.failure': 'Thêm cấu hình mới không thành công! Vui lòng thử lại sau.',
  'admin.config.noti.update-config.title.success': 'Cập nhật cấu hình thành công!',
  'admin.config.noti.update-config.title.failure': 'Cập nhật cấu hình không thành công! Vui lòng thử lại sau.',
  'admin.config.noti.delete-config.title.success': 'Xóa cấu hình thành công!',
  'admin.config.noti.delete-config.title.failure': 'Xóa cấu hình không thành công! Vui lòng thử lại sau.',
  'admin.config.modal.add.title': 'Thêm cấu hình mới',
  'admin.config.modal.update.title': 'Cập nhật thông tin cấu hình',
  'admin.config.modal.detail.title': 'Thông tin chi tiết cấu hình',
  'admin.config.modal.delete.title': 'Xác nhận xóa cấu hình!',
  'admin.config.modal.delete.sub-title': 'Bạn chắc chắn muốn xóa cấu hình này?',
};

const vnAdminConfigLangLib = {
  ...config,
};

export default vnAdminConfigLangLib;
