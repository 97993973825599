// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllRolesRequest = createAction('GET_ALL_ROLES_REQUEST');
export const getAllRolesSuccess = createAction('GET_ALL_ROLES_SUCCESS');
export const getAllRolesFailure = createAction('GET_ALL_ROLES_FAILURE');

export const createRoleRequest = createAction('CREATE_ROLE_REQUEST');
export const createRoleSuccess = createAction('CREATE_ROLE_SUCCESS');
export const createRoleFailure = createAction('CREATE_ROLE_FAILURE');
export const resetCreateRoleState = createAction('RESET_CREATE_ROLE_STATE');

export const deleteRoleRequest = createAction('DELETE_ROLE_REQUEST');
export const deleteRoleSuccess = createAction('DELETE_ROLE_SUCCESS');
export const deleteRoleFailure = createAction('DELETE_ROLE_FAILURE');
export const resetDeleteRoleState = createAction('RESET_DELETE_ROLE_STATE');

export const getAllPermissionsRequest = createAction('GET_ALL_PERMISSIONS_REQUEST');
export const getAllPermissionsSuccess = createAction('GET_ALL_PERMISSIONS_SUCCESS');
export const getAllPermissionsFailure = createAction('GET_ALL_PERMISSIONS_FAILURE');

export const attachPermissionsToRoleRequest = createAction('ATTACH_PERMISSIONS_TO_TOLE_REQUEST');
export const attachPermissionsToRoleSuccess = createAction('ATTACH_PERMISSIONS_TO_TOLE_SUCCESS');
export const attachPermissionsToRoleFailure = createAction('ATTACH_PERMISSIONS_TO_TOLE_FAILURE');
export const resetAttachPermissionsToRoleState = createAction('RESET_ATTACH_PERMISSIONS_TO_TOLE_STATE');

export const editPermissionsToRoleRequest = createAction('EDIT_PERMISSIONS_TO_TOLE_REQUEST');
export const editPermissionsToRoleSuccess = createAction('EDIT_PERMISSIONS_TO_TOLE_SUCCESS');
export const editPermissionsToRoleFailure = createAction('EDIT_PERMISSIONS_TO_TOLE_FAILURE');
export const resetEditPermissionsToRoleState = createAction('RESET_EDIT_PERMISSIONS_TO_TOLE_STATE');

export const resetRoleState = createAction('RESET_ROLE_STATE');
