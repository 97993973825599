const product = {
  'admin.product.hetmet': 'Tất cả danh mục',
  'admin.product.breadcrumb.heading': 'Quản lý sản phẩm',
  'admin.product.breadcrumb.parent': 'Sản phẩm',
  'admin.product.btn.add-new': 'Thêm sản phẩm mới',
  'admin.product.noti.create-product.title.success': 'Thêm sản phẩm mới thành công!',
  'admin.product.noti.create-product.title.failure': 'Thêm sản phẩm mới không thành công! Vui lòng thử lại sau.',
  'admin.product.noti.update-product.title.success': 'Cập nhật sản phẩm thành công!',
  'admin.product.noti.update-product.title.failure': 'Cập nhật sản phẩm không thành công! Vui lòng thử lại sau.',
  'admin.product.noti.delete-product.title.success': 'Xóa sản phẩm thành công!',
  'admin.product.noti.delete-product.title.failure': 'Xóa sản phẩm không thành công! Vui lòng thử lại sau.',
  'admin.product.modal.add.title': 'Thêm sản phẩm mới',
  'admin.product.modal.update.title': 'Cập nhật thông tin sản phẩm',
  'admin.product.modal.detail.title': 'Thông tin chi tiết sản phẩm',
  'admin.product.modal.delete.title': 'Xác nhận xóa sản phẩm!',
  'admin.product.modal.delete.sub-title': 'Bạn chắc chắn muốn xóa sản phẩm này?',
};

const category = {
  'admin.category.hetmet': 'Tất cả danh mục',
  'admin.category.breadcrumb.heading': 'Quản lý danh mục',
  'admin.category.breadcrumb.parent': 'Danh mục sản phẩm',
  'admin.category.btn.add-new': 'Thêm danh mục mới',
  'admin.category.noti.create-category.title.success': 'Thêm danh mục mới thành công!',
  'admin.category.noti.create-category.title.failure': 'Thêm danh mục mới không thành công! Vui lòng thử lại sau.',
  'admin.category.noti.update-category.title.success': 'Cập nhật danh mục thành công!',
  'admin.category.noti.update-category.title.failure': 'Cập nhật danh mục không thành công! Vui lòng thử lại sau.',
  'admin.category.noti.delete-category.title.success': 'Xóa danh mục thành công!',
  'admin.category.noti.delete-category.title.failure': 'Xóa danh mục không thành công! Vui lòng thử lại sau.',
  'admin.category.modal.add.title': 'Thêm danh mục mới',
  'admin.category.modal.update.title': 'Cập nhật thông tin danh mục',
  'admin.category.modal.detail.title': 'Thông tin chi tiết danh mục',
  'admin.category.modal.delete.title': 'Xác nhận xóa danh mục!',
  'admin.category.modal.delete.sub-title': 'Bạn chắc chắn muốn xóa danh mục này?',
};

const vnAdminProductLangLib = {
  ...product,
  ...category,
};

export default vnAdminProductLangLib;
