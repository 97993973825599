const comment = {
  'admin.comment.hetmet': 'Phản hồi',
  'admin.comment.breadcrumb.heading': 'Danh sách các phản hồi',
  'admin.comment.breadcrumb.parent': 'Phản hồi',
  'admin.comment.column.author': 'Tác giả',
  'admin.comment.column.comment': 'Phản hồi',
  'admin.comment.label.message': 'Nội dung',
  'admin.comment.error.message': 'Vui lòng nhập nội dung!',
  'admin.comment.column.reply-for': 'Trả lời cho',
  'admin.comment.column.created_at': 'Đã đăng vào',
  'admin.comment.reply-for': 'Trả lời tới',
  'admin.comment.noti.reply-comment.success': 'Trả lời phản hồi thành công!',
  'admin.comment.noti.reply-comment.failure': 'Trả lời phản hồi không thành công! Vui lòng thử lại sau.',
  'admin.comment.noti.update-comment.success': 'Chỉnh sửa phản hồi thành công!',
  'admin.comment.noti.update-comment.failure': 'Chỉnh sửa phản hồi không thành công! Vui lòng thử lại sau.',
  'admin.comment.noti.delete-comment.success': 'Xoá phản hồi thành công!',
  'admin.comment.noti.delete-comment.failure': 'Xoá phản hồi không thành công! Vui lòng thử lại sau.',
  'admin.comment.modal.action.title.reply': 'Trả lời tới $x',
  'admin.comment.modal.action.title.update': 'Chỉnh sửa',
  'admin.comment.modal.action.btn.reply': 'Trả lời',
  'admin.comment.modal.action.btn.update': 'Cập nhật phản hồi',
  'admin.comment.modal.delete.title': 'Xác nhận xoá phản hồi!',
  'admin.comment.modal.delete.description': 'Bạn chắc chắn muốn xoá phản hồi này?',
};

const vnAdminCommentLangLib = {
  ...comment,
};

export default vnAdminCommentLangLib;
