/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllSliders({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/sliders?${payload.params}` : '/sliders?limit=0',
    ));
    yield put(Actions.getAllSlidersSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllSlidersFailure(messages));
    }
  }
}

function* createSlider({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/sliders', payload));
    yield put(Actions.createSliderSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createSliderFailure(messages));
    }
  }
}

function* updateSlider({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/sliders/${payload.id}`, payload.body));
    yield put(Actions.updateSliderSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateSliderFailure(messages));
    }
  }
}

function* deleteSlider({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/sliders/${payload}`));
    yield put(Actions.deleteSliderSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteSliderFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllSlidersRequest, getAllSliders);
  yield takeLatest(Actions.createSliderRequest, createSlider);
  yield takeLatest(Actions.updateSliderRequest, updateSlider);
  yield takeLatest(Actions.deleteSliderRequest, deleteSlider);
}
