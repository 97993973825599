// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllPackageRequest = createAction('GET_ALL_PACKAGE_REQUEST');
export const getAllPackageSuccess = createAction('GET_ALL_PACKAGE_SUCCESS');
export const getAllPackageFailure = createAction('GET_ALL_PACKAGE_FAILURE');

export const createPackageRequest = createAction('CREATE_PACKAGE_REQUEST');
export const createPackageSuccess = createAction('CREATE_PACKAGE_SUCCESS');
export const createPackageFailure = createAction('CREATE_PACKAGE_FAILURE');
export const resetCreatePackageState = createAction('RESET_CREATE_PACKAGE_STATE');

export const updatePackageRequest = createAction('UPDATE_PACKAGE_REQUEST');
export const updatePackageSuccess = createAction('UPDATE_PACKAGE_SUCCESS');
export const updatePackageFailure = createAction('UPDATE_PACKAGE_FAILURE');
export const resetUpdatePackageState = createAction('RESET_UPDATE_PACKAGE_STATE');

export const deletePackageRequest = createAction('DELETE_PACKAGE_REQUEST');
export const deletePackageSuccess = createAction('DELETE_PACKAGE_SUCCESS');
export const deletePackageFailure = createAction('DELETE_PACKAGE_FAILURE');
export const resetDeletePackageState = createAction('RESET_DELETE_PACKAGE_STATE');

export const resetPackageState = createAction('RESET_PACKAGE_STATE');
