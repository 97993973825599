export const STORAGE_KEY = {
  GET_ALL_WIDGET_STATE: 'GET_ALL_WIDGET_STATE',
  GET_ALL_TYPES_STATE: 'GET_ALL_TYPES_STATE',
  DIRECTION: 'DIRECTION',
  CURRENT_LANGUAGE: 'CURRENT_LANGUAGE',
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  EXPIRES_IN: 'EXPIRES_IN',
  IS_LOGIN: 'IS_LOGIN',
};
