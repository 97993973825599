const config = {
  'admin.config.hetmet': 'All configs',
  'admin.config.default': 'Default config',
  'admin.config.watermark': 'Watermark',
  'admin.config.breadcrumb.heading': 'Manage configs',
  'admin.config.breadcrumb.parent': 'Config',
  'admin.config.btn.add-new': 'Add new config',
  'admin.config.noti.create-config.title.success': 'Add new configs successful!',
  'admin.config.noti.create-config.title.failure': 'Adding new configs failed! Please try again later.',
  'admin.config.noti.update-config.title.success': 'Successful configs update!',
  'admin.config.noti.update-config.title.failure': 'Config updates are unsuccessful! Please try again later.',
  'admin.config.noti.delete-config.title.success': 'Delete configs successfully!',
  'admin.config.noti.delete-config.title.failure': 'Delete configs unsuccessful! Please try again later.',
  'admin.config.modal.add.title': 'Add new config',
  'admin.config.modal.update.title': 'Update config information',
  'admin.config.modal.detail.title': 'Config details',
  'admin.config.modal.delete.title': 'Confirm to delete config!',
  'admin.config.modal.delete.sub-title': 'You definitely want to delete this config?',
};

const usAdminConfigLangLib = {
  ...config,
};

export default usAdminConfigLangLib;
