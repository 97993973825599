const agency = {
  'admin.agency.menus.hetmet': 'Agencies List - Agency Manager',
  'admin.agency.menus.breadcrumb.heading': 'Agencies List',
  'admin.agency.menus.breadcrumb.parent': 'Agency Manager',
  'admin.agency.btn.add': 'Add new agency',
  'admin.agency.noti.create-agency.success': 'Add new agency successfully!',
  'admin.agency.noti.create-agency.failure': 'Add new agency unsuccessfully!',
  'admin.agency.noti.update-agency.success': 'Update agency successfully!',
  'admin.agency.noti.update-agency.failure': 'Update agency unsuccessfully!',
  'admin.agency.noti.delete-agency.success': 'Delete agency successfully!',
  'admin.agency.noti.delete-agency.failure': 'Delete agency unsuccessfully!',
  'admin.agency.noti.attach-users-to-agency.success': 'Update users to Agency successfully!',
  'admin.agency.noti.attach-users-to-agency.failure': 'Update users to Agency unsuccessfully!',
  'admin.agency.noti.detach-users-to-agency.success': 'Update users to Agency successfully!',
  'admin.agency.noti.detach-users-to-agency.failure': 'Update users to Agency unsuccessfully!',
  'admin.agency.column.soft': 'Order',
  'admin.agency.column.name': 'Agency name',
  'admin.agency.column.description': 'Description',
  'admin.agency.modal.delete.title': 'Confirm delete agency!',
  'admin.agency.modal.delete.description': 'Are you sure you want to delete this agency?',
  'admin.agency.error-msg.name': 'Please enter dealer name!',
  'admin.agency.modal.action.title.create': 'Add a new agency',
  'admin.agency.modal.action.title.update': 'Update agency',
  'admin.agency.modal.action.title.details': 'Detail agency',
  'admin.agency.modal.action.btn.create': 'Add new',
  'admin.agency.modal.action.btn.update': 'Update',
  'admin.agency.modal.action.label.name': 'Agency name',
  'admin.agency.modal.action.label.description': 'Description',
  'admin.agency.modal.action.label.users': 'Select users for Agency',
  'admin.agency.modal.action.placeholder.users': 'Select users',
};

const usAdminAgencyLangLib = {
  ...agency,
};

export default usAdminAgencyLangLib;
