const common = {
  'common.content-empty': '(Chưa có dữ liệu)',
  'common.content-hi': 'Chào buổi',
};

const topNavDropdownMenu = {
  'common.top-nav-dropdown-menu.account': 'Tài khoản',
  'common.top-nav-dropdown-menu.change-password': 'Đổi mật khẩu',
  'common.top-nav-dropdown-menu.support': 'Hỗ trợ',
  'common.top-nav-dropdown-menu.log-out': 'Đăng xuất',
};

const langOptions = {
  'common.lang-options.us': 'English (United States)',
  'common.lang-options.vn': 'Tiếng Việt',
};

const sidebarMain = {
  'common.sidebar-main.dashboard': 'Bảng tin',
  'common.sidebar-main.dashboard.home': 'Trang chủ',
  //
  'common.sidebar-main.agencies': 'Đại lý',
  //
  'common.sidebar-main.posts': 'Bài viết',
  'common.sidebar-main.posts.all-posts.article': 'Tất cả bài viết',
  'common.sidebar-main.posts.all-posts.product': 'Tất cả sản phẩm',
  'common.sidebar-main.posts.all-posts.course': 'Tất cả khóa học',
  'common.sidebar-main.posts.add-new.article': 'Viết bài mới',
  'common.sidebar-main.posts.add-new.product': 'Sản phẩm mới',
  'common.sidebar-main.posts.add-new.course': 'Khóa học mới',
  'common.sidebar-main.posts.post-category': 'Danh mục bài viết',
  'common.sidebar-main.posts.product-category': 'Danh mục sản phẩm',
  'common.sidebar-main.posts.course-category': 'Danh mục khóa học',
  'common.sidebar-main.posts.post-data-group': 'Nhóm dữ liệu',
  'common.sidebar-main.posts.post-article-group': 'Nhóm bài viết',
  'common.sidebar-main.posts.product-trademark': 'Thương hiệu',
  'common.sidebar-main.posts.course-trademark': 'Thương hiệu',
  'common.sidebar-main.posts.product-article-group': 'Nhóm sản phẩm',
  'common.sidebar-main.posts.course-article-group': 'Nhóm khóa học',
  'common.sidebar-main.posts.product-discount': 'Giảm giá',
  'common.sidebar-main.posts.course-discount': 'Giảm giá',
  'common.sidebar-main.posts.product-order': 'Đơn hàng',
  //
  'common.sidebar-main.media': 'Media',
  'common.sidebar-main.media.media-library': 'Thư viện',
  'common.sidebar-main.media.add-new': 'Thêm media mới',
  //
  'common.sidebar-main.users': 'Thành viên',
  'common.sidebar-main.users.list': 'Tất cả người dùng',
  'common.sidebar-main.users.clients': 'Tất cả khách hàng',
  'common.sidebar-main.users.role': 'Vai trò',
  'common.sidebar-main.users.permissions': 'Phân quyền',
  //
  'common.sidebar-main.settings': 'Cài đặt',
  'common.sidebar-main.settings.dynamic': 'Tùy chọn khác',
  'common.sidebar-main.settings.smtp': 'SMTP',
  'common.sidebar-main.settings.general': 'Cấu hình',
  'common.sidebar-main.settings.script': 'Header & tracker',
  'common.sidebar-main.settings.company': 'Thông tin công ty',
  'common.sidebar-main.settings.custom': 'Mạng xã hội',
  'common.sidebar-main.settings.copyright': 'Bản quyền',
  'common.sidebar-main.settings.email': 'Email',
  'common.sidebar-main.settings.redirect': 'Điều hướng',

  //
  'common.sidebar-main.appearance': 'Giao diện',
  'common.sidebar-main.appearance.menu': 'Menu',
  'common.sidebar-main.appearance.slider': 'Slider',
  //
  'common.sidebar-main.post-type': 'Loại bài đăng',
  //
  'common.sidebar-main.widgets': 'Widgets',
  //
  'common.sidebar-main.comment': 'Phản hồi',
  //
  'common.sidebar-main.feedback': 'Yêu cầu',
  //
  'common.sidebar-main.seo': 'Quản lý SEO',
  //
  'common.sidebar-main.warehouse': 'Quản lý Kho',
  'common.sidebar-main.warehouse-change': 'Xuất nhập Kho',
  'common.sidebar-main.supplier': 'Nhà cung cấp',
  //
  'common.sidebar-main.rating': 'Xếp hạng',
  //
  'common.sidebar-main.products': 'Sản phẩm',
  'common.sidebar-main.products.manage': 'Quản lý sản phẩm',
  'common.sidebar-main.products.category': 'Danh mục',
  //
  'common.sidebar-main.packages': 'Gói',
  'common.sidebar-main.packages.manage': 'Quản lý gói',
  //
  'common.sidebar-main.payments': 'Thanh toán',
  'common.sidebar-main.payments.manage': 'Quản lý thanh toán',
  //
  'common.sidebar-main.articles': 'Bài viết',
  'common.sidebar-main.articles.manage': 'Quản lý bài viết',
  //
  'common.sidebar-main.transactions': 'Giao dịch',
  'common.sidebar-main.transactions.manage': 'Quản lý giao dịch',
};

const label = {
  'common.label.email': 'Email',
  'common.label.password': 'Mật khẩu',
  'common.label.captcha': 'Mã bảo vệ',
};

const errorMsgInput = {
  'common.error-msg-input.email-require': 'Vui lòng nhập địa chỉ email!',
  'common.error-msg-input.email-format': 'Địa chỉ email của bạn không hợp lệ!',
  'common.error-msg-input.email-exist': 'Địa chỉ email của bạn đã tồn tại! Vui lòng nhập địa chỉ email khác.',
  //
  'common.error-msg-input.password-require': 'Vui lòng nhập mật khẩu!',
  'common.error-msg-input.password-length-5': 'Mật khẩu ít nhất có 5 ký tự!',
  //
  'common.error-msg-input.captcha-require': 'Vui lòng nhập mã bảo vệ!',
  'common.error-msg-input.captcha-incorrect': 'Mã bảo vệ không đúng!',
  //
  'common.error-msg-input.hotline-incorrect': 'Hotline phải bao gồm 10 ký tự!',
  'common.error-msg-input.phone-incorrect': 'Số điện thoại phải bao gồm 10 ký tự!',
  //
  'common.error-msg-input.key-require': 'Vui lòng nhập khóa!',
  //
  'common.error-msg-input.price-incorrect': 'Giá phải lớn hơn hoặc bằng 0!',
  //
  'common.error-msg-input.name-require': 'Vui lòng nhập họ và tên!',
  'common.error-msg-input.name-length-min-2': 'Ít nhât 2 ký tự!',
  'common.error-msg-input.name-length-max-200': 'Tối đa 200 ký tự!',
  //
  'common.error-msg-input.value-require': 'Vui lòng điền trường này!',
  'common.error-msg-input.number-length-min': 'Giá trị phải lớn hơn hoặc bằng ',
  'common.error-msg-input.number-length-max': 'Giá trị phải nhỏ hơn hoặc bằng ',
  'common.error-msg-input.length-min-2': 'At least 2 characters!',
  'common.error-msg-input.length-max-200': 'Maximum of 200 characters!',
  //
  'common.error-msg-input.config-name-require': 'Vui lòng nhập tên cấu hình!',
  'common.error-msg-input.config-name-length-min-2': 'Ít nhât 2 ký tự!',
  'common.error-msg-input.config-name-length-max-200': 'Tối đa 200 ký tự!',
  //
  'common.error-msg-input.category-require': 'Vui lòng chọn danh mục!',
  'common.error-msg-input.category-name-require': 'Vui lòng nhập tên danh mục!',
  'common.error-msg-input.category-name-length-min-2': 'Ít nhât 2 ký tự!',
  'common.error-msg-input.category-name-length-max-200': 'Tối đa 200 ký tự!',
  //
  'common.error-msg-input.product-name-require': 'Vui lòng nhập tên sản phẩm!',
  'common.error-msg-input.product-name-length-min-2': 'Ít nhât 2 ký tự!',
  'common.error-msg-input.product-name-length-max-200': 'Tối đa 200 ký tự!',
  //
  'common.error-msg-input.title-require': 'Vui lòng nhập tiêu đề!',
  //
  'common.error-msg-input.group-require': 'Vui lòng chọn ít nhất 1 lựa chọn!',
  //
  'common.error-msg-input.image-require': 'Vui lòng tải hình ảnh!',
  'common.error-msg-input.image-size': 'Hình ảnh bạn chọn quá lớn! Vui lòng chọn ảnh khác.',
  //
  'common.error-msg-input.list-image-require': 'Tải lên ít nhất 1 hình!',
  //
  'common.error-msg-input.require': 'Vui lòng điền trường này!',
  //
  'common.error-msg-input.role-name-require': 'Vui lòng nhập tên vai trò!',
  'common.error-msg-input.role-name-length-min-2': 'Ít nhất 2 ký tự!',
  'common.error-msg-input.role-name-length-max-20': 'Tối đa 20 ký tự!',
  //
  'common.error-msg-input.role-description-require': 'Vui lòng nhập mô tả cho vai trò!',
  'common.error-msg-input.role-description-length-max-255': 'Tối đa 255 ký tự!',
  //
  'common.error-msg-input.role-permissions-require': 'Vui lòng chọn ít nhất 1 quyền!',
  //
  'common.error-msg-input.permission-user-require': 'Vui lòng chọn người dùng!',
  'common.error-msg-input.permission-roles-require': 'Vui lòng chọn ít nhất 1 vai trò!',
};

const rowHeadingPage = {
  'common.row-heading-page.dropdown-mobile.title': 'Thêm',
  'common.row-heading-page.order-by.title': 'Sắp xếp theo',
  'common.row-heading-page.order-by.name': 'Tên',
  'common.row-heading-page.order-by.package': 'Gói',
  'common.row-heading-page.order-by.payment': 'Thanh toán',
  'common.row-heading-page.order-by.bank': 'Ngân hàng',
  'common.row-heading-page.order-by.type': 'Loại',
  'common.row-heading-page.order-by.status': 'Trạng thái',
  'common.row-heading-page.order-by.date': 'Ngày',
  'common.row-heading-page.order-by.style': 'Loại',
  'common.row-heading-page.order-by.money': 'Tổng tiền',
  'common.row-heading-page.order-by.supplier': 'Nhà cung cấp',
  'common.row-heading-page.order-by.user': 'Nhân viên',
  'common.row-heading-page.order-by.warehouse': 'Kho',
  'common.row-heading-page.order-by.province': 'Tỉnh/Thành',
  'common.row-heading-page.order-by.sort': 'Thứ tự',
  'common.row-heading-page.order-by.titleS': 'Tiêu đề',
  'common.row-heading-page.order-by.key': 'Khóa',
  'common.row-heading-page.order-by.code': 'Mã',
  'common.row-heading-page.order-by.price': 'Giá',
  'common.row-heading-page.order-by.number': 'Số lượng',
  'common.row-heading-page.order-by.in_stock': 'Tồn kho',
  'common.row-heading-page.order-by.export': 'Xuất kho',
  'common.row-heading-page.order-by.expiry': 'Hạn sử dụng',
  'common.row-heading-page.order-by.expired': 'Hết hạn',
  'common.row-heading-page.order-by.revenue': 'Doanh thu',
  'common.row-heading-page.order-by.profit': 'Lợi nhuận',
  'common.row-heading-page.sort-by.title': 'Thứ tự',
  'common.row-heading-page.sort-by.asc': 'Tăng dần',
  'common.row-heading-page.sort-by.desc': 'Giảm dần',
  'common.row-heading-page.search.placeholder': 'Tìm kiếm...',
  'common.row-heading-page.view.empty': 'Không có dữ liệu',
  'common.row-heading-page.view.viewing-title': 'Đang xem',
  'common.row-heading-page.view.total-title': 'Tổng',
  'common.row-heading-page.view.total-title.unit': 'Mục',
};

const notification = {
  'common.notification.title-basic': 'Thông báo',
};

const dropzoneDropzone = {
  'common.dropzone-component.placeholder': 'Thả hoặc bấm vào chọn các tập tin để tải lên',
  'common.dropzone-component.queuecomplete.notification.content': 'Thêm mới tập tin thành công',
};

const button = {
  'common.button.back': 'Quay lại',
  'common.button.load-more': 'Tải thêm',
  'common.button.loading': 'Đang tải',
  'common.button.add': 'Thêm',
  'common.button.delete': 'Xóa',
  'common.button.confirm': 'Xác nhận',
  'common.button.cancel': 'Hủy',
  'common.button.complete': 'Hoàn thành',
  'common.button.edit': 'Chỉnh sửa',
  'common.button.save-change': 'Lưu thay đổi',
  'common.button.cancel-change': 'Hủy thay đổi',
  'common.button.upload-image': 'Tải ảnh lên',
  'common.button.reload-page': 'Tải lại trang',
};

const column = {
  'common.column.key': 'Khóa',
  'common.column.title': 'Tiêu đề',
  'common.column.bank': 'Ngân hàng',
  'common.column.account': 'Tài khoản',
  'common.column.owner': 'Chủ sở hữu',
  'common.column.qrcode': 'QR Code',
  'common.column.sort': 'Thứ tự',
  'common.column.action': 'Hành động',
  'common.column.date': 'Ngày',
  'common.column.note': 'Ghi chú',
  'common.column.list-image': 'Danh sách ảnh',
  'common.column.slug': 'Đường dẫn',
  'common.column.image': 'Hình ảnh',
  'common.column.file': 'Tệp bán',
  'common.column.style': 'Loại',
  'common.column.group-title': 'Tên nhóm dữ liệu',
  'common.column.group-title-1': 'Tên $x',
  'common.column.group-product-title': 'Tên nhóm sản phẩm',
  'common.column.group-product-title-1': 'Tên $x',
  'common.column.all': 'Tất cả',
  'common.column.layout': 'Hiển thị theo',
  'common.column.category-parent': 'Danh mục cha',
  'common.column.category': 'Danh mục',
  'common.column.start-date': 'Ngày bắt đầu',
  'common.column.start-end': 'Ngày kết thúc',
  'common.column.status': 'Trạng thái',
  'common.column.status.disabled': 'Vô hiệu hóa',
  'common.column.status.enabled': 'Kích hoạt',
  'common.column.id': 'ID',
  'common.column.code': 'Mã',
  'common.column.third_code': 'Mã giao dịch',
  'common.column.credit': 'Credit',
  'common.column.name': 'Họ và tên',
  'common.column.month': 'Tháng',
  'common.column.type': 'Loại',
  'common.column.org-name': 'Tên',
  'common.column.product-name': 'Tên sản phẩm',
  'common.column.email': 'Email',
  'common.column.gender': 'Giới tính',
  'common.column.gender.male': 'Nam',
  'common.column.gender.female': 'Nữ',
  'common.column.gender.unspecified': 'Trống',
  'common.column.birthday': 'Ngày sinh',
  'common.column.phone': 'Số điện thoại',
  'common.column.price': 'Giá',
  'common.column.role': 'Vai trò',
  'common.column.password': 'Mật khẩu',
  'common.column.zalo': 'Zalo',
  'common.column.facebook': 'Facebook',
  'common.column.address': 'Địa chỉ',
  'common.column.avatar': 'Hình đại diện',
  'common.column.roles': 'Danh sách vai trò',
  'common.column.role-name': 'Tên vai trò',
  'common.column.description': 'Mô tả',
  'common.column.value': 'Giá trị',
  'common.column.permissions': 'Danh sách quyền',
  'common.column.user': 'Chọn thành viên',
  'common.column.customer': 'Khách hàng',
  'common.column.content': 'Nội dung',
  'common.column.custom': 'Tùy chỉnh',
};

const vnCommonLangLib = {
  ...common,
  ...topNavDropdownMenu,
  ...langOptions,
  ...sidebarMain,
  ...label,
  ...errorMsgInput,
  ...rowHeadingPage,
  ...notification,
  ...dropzoneDropzone,
  ...button,
  ...column,
};

export default vnCommonLangLib;
