/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllConfig({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/configs?${payload.params}` : '/configs?limit=0',
    ));
    yield put(Actions.getAllConfigSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllConfigFailure(messages));
    }
  }
}

function* createConfig({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/configs?_method=PATCH', payload));
    yield put(Actions.createConfigSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createConfigFailure(messages));
    }
  }
}

function* createWatermarkConfig({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/configs/watermark?_method=PATCH', payload));
    yield put(Actions.createWatermarkConfigSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createWatermarkConfigFailure(messages));
    }
  }
}

function* updateWatermarkConfig({ payload }) {
  try {
    if (payload.watermark_logo && !payload.logo) {
      const imgData = new FormData();
      imgData.append('module', 'Config');
      imgData.append('type', 'file');
      imgData.append('files[]', payload.watermark_logo);
      const imgUrl = yield call(() => axiosMicro.post('/files/upload', imgData));
      payload.logo = imgUrl?.data?.data[0];
    }
    const response = yield call(() => axiosMicro.post('/configs/watermark?_method=PATCH', payload));
    yield put(Actions.updateConfigSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateConfigFailure(messages));
    }
  }
}

function* updateConfig({ payload }) {
  try {
    if (payload.data.site_logo && typeof payload.data.site_logo !== 'string') {
      const imgData = new FormData();
      imgData.append('module', 'Config');
      imgData.append('type', 'file');
      imgData.append('files[]', payload.data.site_logo);
      const imgUrl = yield call(() => axiosMicro.post('/files/upload', imgData));
      payload.data.site_logo = imgUrl?.data?.data[0];
    }
    if (payload.data.site_favicon && typeof payload.data.site_favicon !== 'string') {
      const imgData = new FormData();
      imgData.append('module', 'Config');
      imgData.append('type', 'file');
      imgData.append('files[]', payload.data.site_favicon);
      const imgUrl = yield call(() => axiosMicro.post('/files/upload', imgData));
      payload.data.site_favicon = imgUrl?.data?.data[0];
    }
    if (payload.data.seo_image && typeof payload.data.seo_image !== 'string') {
      const imgData = new FormData();
      imgData.append('module', 'Config');
      imgData.append('type', 'file');
      imgData.append('files[]', payload.data.seo_image);
      const imgUrl = yield call(() => axiosMicro.post('/files/upload', imgData));
      payload.data.seo_image = imgUrl?.data?.data[0];
    }
    const response = yield call(() => axiosMicro.post('/configs?_method=PATCH', payload));
    yield put(Actions.updateConfigSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateConfigFailure(messages));
    }
  }
}

function* deleteConfig({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/configs/${payload}`));
    yield put(Actions.deleteConfigSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteConfigFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllConfigRequest, getAllConfig);
  yield takeLatest(Actions.createConfigRequest, createConfig);
  yield takeLatest(Actions.createWatermarkConfigRequest, createWatermarkConfig);
  yield takeLatest(Actions.updateWatermarkConfigRequest, updateWatermarkConfig);
  yield takeLatest(Actions.updateConfigRequest, updateConfig);
  yield takeLatest(Actions.deleteConfigRequest, deleteConfig);
}
