// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  //
  isGetAllMembersRequest: false,
  isGetAllMembersSuccess: false,
  isGetAllMembersFailure: false,
  getAllMembersState: {},
  //
  isAddMemberRequest: false,
  isAddMemberSuccess: false,
  isAddMemberFailure: false,
  //
  isUpdateMemberRequest: false,
  isUpdateMemberSuccess: false,
  isUpdateMemberFailure: false,
  //
  isDeleteMemberRequest: false,
  isDeleteMemberSuccess: false,
  isDeleteMemberFailure: false,
  //
  isGetDetailMemberRequest: false,
  isGetDetailMemberSuccess: false,
  isGetDetailMemberFailure: false,
  getDetailMemberState: {},
  //
  isCheckEmailExistRequest: false,
  isCheckEmailExistSuccess: false,
  isCheckEmailExistFailure: false,
  emailIsExist: false,
  //
  isResetPasswordMemberRequest: false,
  isResetPasswordMemberSuccess: false,
  isResetPasswordMemberFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all members
    [Actions.getAllMembersRequest]: (state) => ({
      ...state,
      isGetAllMembersRequest: true,
      isGetAllMembersSuccess: false,
      isGetAllMembersFailure: false,
    }),
    [Actions.getAllMembersSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllMembersRequest: false,
      isGetAllMembersSuccess: true,
      isGetAllMembersFailure: false,
      getAllMembersState: payload,
    }),
    [Actions.getAllMembersFailure]: (state, { payload }) => ({
      ...state,
      isGetAllMembersRequest: false,
      isGetAllMembersSuccess: false,
      isGetAllMembersFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Add member
    [Actions.addMemberRequest]: (state) => ({
      ...state,
      isAddMemberRequest: true,
      isAddMemberSuccess: false,
      isAddMemberFailure: false,
    }),
    [Actions.addMemberSuccess]: (state, { payload }) => ({
      ...state,
      isAddMemberRequest: false,
      isAddMemberSuccess: true,
      isAddMemberFailure: false,
      getAllMembersState:
        state.getAllMembersState?.meta?.pagination?.current_page === 1
          ? {
            ...state.getAllMembersState,
            data:
              state.getAllMembersState.meta.pagination.count
                === state.getAllMembersState.meta.pagination.per_page
                ? [payload.data].concat(
                  state.getAllMembersState.data.slice(
                    0,
                    state.getAllMembersState.data.length - 1,
                  ),
                )
                : [payload.data].concat(state.getAllMembersState.data),
            meta: {
              ...state.getAllMembersState.meta,
              pagination: {
                ...state.getAllMembersState.meta.pagination,
                count:
                  state.getAllMembersState.meta.pagination.count
                    === state.getAllMembersState.meta.pagination.per_page
                    ? state.getAllMembersState.meta.pagination.per_page
                    : state.getAllMembersState.meta.pagination.count + 1,
                total: state.getAllMembersState.meta.pagination.total + 1,
                total_pages: Math.ceil(
                  (state.getAllMembersState.meta.pagination.total + 1)
                  / state.getAllMembersState.meta.pagination.per_page,
                ),
              },
            },
          }
          : { ...state.getAllMembersState },
    }),
    [Actions.addMemberFailure]: (state, { payload }) => ({
      ...state,
      isAddMemberRequest: false,
      isAddMemberSuccess: false,
      isAddMemberFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetAddMemberState]: (state) => ({
      ...state,
      isAddMemberRequest: false,
      isAddMemberSuccess: false,
      isAddMemberFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update member
    [Actions.updateMemberRequest]: (state) => ({
      ...state,
      isUpdateMemberRequest: true,
      isUpdateMemberSuccess: false,
      isUpdateMemberFailure: false,
    }),
    [Actions.updateMemberSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateMemberRequest: false,
      isUpdateMemberSuccess: true,
      isUpdateMemberFailure: false,
      getAllMembersState: {
        ...state.getAllMembersState,
        data: state.getAllMembersState.data.map((item) => item.id === payload.data.id ? payload.data : item),
      },
    }),
    [Actions.updateMemberFailure]: (state, { payload }) => ({
      ...state,
      isUpdateMemberRequest: false,
      isUpdateMemberSuccess: false,
      isUpdateMemberFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateMemberState]: (state) => ({
      ...state,
      isUpdateMemberRequest: false,
      isUpdateMemberSuccess: false,
      isUpdateMemberFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete member
    [Actions.deleteMemberRequest]: (state) => ({
      ...state,
      isDeleteMemberRequest: true,
      isDeleteMemberSuccess: false,
      isDeleteMemberFailure: false,
    }),
    [Actions.deleteMemberSuccess]: (state) => ({
      ...state,
      isDeleteMemberRequest: false,
      isDeleteMemberSuccess: true,
      isDeleteMemberFailure: false,
    }),
    [Actions.deleteMemberFailure]: (state, { payload }) => ({
      ...state,
      isDeleteMemberRequest: false,
      isDeleteMemberSuccess: false,
      isDeleteMemberFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteMemberState]: (state) => ({
      ...state,
      isDeleteMemberRequest: false,
      isDeleteMemberSuccess: false,
      isDeleteMemberFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Get detail member
    [Actions.getDetailMemberRequest]: (state) => ({
      ...state,
      isGetDetailMemberRequest: true,
      isGetDetailMemberSuccess: false,
      isGetDetailMemberFailure: false,
    }),
    [Actions.getDetailMemberSuccess]: (state, { payload }) => ({
      ...state,
      isGetDetailMemberRequest: false,
      isGetDetailMemberSuccess: true,
      isGetDetailMemberFailure: false,
      getDetailMemberState: payload,
    }),
    [Actions.getDetailMemberFailure]: (state, { payload }) => ({
      ...state,
      isGetDetailMemberRequest: false,
      isGetDetailMemberSuccess: false,
      isGetDetailMemberFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetDetailMemberState]: (state) => ({
      ...state,
      isGetDetailMemberRequest: false,
      isGetDetailMemberSuccess: false,
      isGetDetailMemberFailure: false,
      getDetailMemberState: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Check email exist
    [Actions.checkEmailExistRequest]: (state) => ({
      ...state,
      isCheckEmailExistRequest: true,
      isCheckEmailExistSuccess: false,
      isCheckEmailExistFailure: false,
    }),
    [Actions.checkEmailExistSuccess]: (state, { payload }) => ({
      ...state,
      isCheckEmailExistRequest: false,
      isCheckEmailExistSuccess: true,
      isCheckEmailExistFailure: false,
      emailIsExist: payload?.data?.length > 0,
    }),
    [Actions.checkEmailExistFailure]: (state, { payload }) => ({
      ...state,
      isCheckEmailExistRequest: false,
      isCheckEmailExistSuccess: false,
      isCheckEmailExistFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCheckEmailExistState]: (state) => ({
      ...state,
      isCheckEmailExistRequest: false,
      isCheckEmailExistSuccess: false,
      isCheckEmailExistFailure: false,
      emailIsExist: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Reset password member
    [Actions.resetPasswordMemberRequest]: (state) => ({
      ...state,
      isResetPasswordMemberRequest: true,
      isResetPasswordMemberSuccess: false,
      isResetPasswordMemberFailure: false,
    }),
    [Actions.resetPasswordMemberSuccess]: (state) => ({
      ...state,
      isResetPasswordMemberRequest: false,
      isResetPasswordMemberSuccess: true,
      isResetPasswordMemberFailure: false,
    }),
    [Actions.resetPasswordMemberFailure]: (state, { payload }) => ({
      ...state,
      isResetPasswordMemberRequest: false,
      isResetPasswordMemberSuccess: false,
      isResetPasswordMemberFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetResetPasswordMemberState]: (state) => ({
      ...state,
      isResetPasswordMemberRequest: false,
      isResetPasswordMemberSuccess: false,
      isResetPasswordMemberFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetMemberState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
