// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getProfileUserRequest = createAction('GET_PROFILE_REQUEST');
export const getProfileUserSuccess = createAction('GET_PROFILE_SUCCESS');
export const getProfileUserFailure = createAction('GET_PROFILE_FAILURE');
export const resetProfileUserState = createAction('RESET_PROFILE_USER_STATE');

export const updateInformationUserRequest = createAction(
  'UPDATE_INFORMATION_USER_REQUEST',
);
export const updateInformationUserSuccess = createAction(
  'UPDATE_INFORMATION_USER_SUCCESS',
);
export const updateInformationUserFailure = createAction(
  'UPDATE_INFORMATION_USER_FAILURE',
);
export const resetUpdateInformationUser = createAction(
  'RESET_UPDATE_INFORMATION_USER_STATE',
);

export const getAllUsersRequest = createAction('GET_ALL_USERS_REQUEST');
export const getAllUsersSuccess = createAction('GET_ALL_USERS_SUCCESS');
export const getAllUsersFailure = createAction('GET_ALL_USERS_FAILURE');

export const resetUserState = createAction('RESET_USER_STATE');
