// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllWarehousesRequest: false,
  isGetAllWarehousesSuccess: false,
  isGetAllWarehousesFailure: false,
  getAllWarehousesState: {},
  //
  isGetWarehouseProductsRequest: false,
  isGetWarehouseProductsSuccess: false,
  isGetWarehouseProductsFailure: false,
  getWarehouseProductsState: {},
  //
  isGetWarehouseProductHistoryRequest: false,
  isGetWarehouseProductHistorySuccess: false,
  isGetWarehouseProductHistoryFailure: false,
  getWarehouseProductHistoryState: {},
  //
  isCreateWarehouseRequest: false,
  isCreateWarehouseSuccess: false,
  isCreateWarehouseFailure: false,
  //
  isUpdateWarehouseRequest: false,
  isUpdateWarehouseSuccess: false,
  isUpdateWarehouseFailure: false,
  //
  isDeleteWarehouseRequest: false,
  isDeleteWarehouseSuccess: false,
  isDeleteWarehouseFailure: false,
  //
  isGetWarehouseChangesRequest: false,
  isGetWarehouseChangesSuccess: false,
  isGetWarehouseChangesFailure: false,
  getWarehouseChangesState: {},
  //
  isCreateWarehouseChangeRequest: false,
  isCreateWarehouseChangeSuccess: false,
  isCreateWarehouseChangeFailure: false,
  //
  isCancelWarehouseChangeRequest: false,
  isCancelWarehouseChangeSuccess: false,
  isCancelWarehouseChangeFailure: false,
  //
  isGetAllSuppliersRequest: false,
  isGetAllSuppliersSuccess: false,
  isGetAllSuppliersFailure: false,
  getAllSuppliersState: {},
  //
  isGetSupplierProductsRequest: false,
  isGetSupplierProductsSuccess: false,
  isGetSupplierProductsFailure: false,
  getSupplierProductsState: {},
  //
  isCreateSupplierRequest: false,
  isCreateSupplierSuccess: false,
  isCreateSupplierFailure: false,
  //
  isUpdateSupplierRequest: false,
  isUpdateSupplierSuccess: false,
  isUpdateSupplierFailure: false,
  //
  isDeleteSupplierRequest: false,
  isDeleteSupplierSuccess: false,
  isDeleteSupplierFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all warehouses
    [Actions.getAllWarehousesRequest]: (state) => ({
      ...state,
      isGetAllWarehousesRequest: true,
      isGetAllWarehousesSuccess: false,
      isGetAllWarehousesFailure: false,
    }),
    [Actions.getAllWarehousesSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllWarehousesRequest: false,
      isGetAllWarehousesSuccess: true,
      isGetAllWarehousesFailure: false,
      getAllWarehousesState: payload,
    }),
    [Actions.getAllWarehousesFailure]: (state, { payload }) => ({
      ...state,
      isGetAllWarehousesRequest: false,
      isGetAllWarehousesSuccess: false,
      isGetAllWarehousesFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get all warehouse products
    [Actions.getWarehouseProductsRequest]: (state) => ({
      ...state,
      isGetWarehouseProductsRequest: true,
      isGetWarehouseProductsSuccess: false,
      isGetWarehouseProductsFailure: false,
    }),
    [Actions.getWarehouseProductsSuccess]: (state, { payload }) => ({
      ...state,
      isGetWarehouseProductsRequest: false,
      isGetWarehouseProductsSuccess: true,
      isGetWarehouseProductsFailure: false,
      getWarehouseProductsState: payload,
    }),
    [Actions.getWarehouseProductsFailure]: (state, { payload }) => ({
      ...state,
      isGetWarehouseProductsRequest: false,
      isGetWarehouseProductsSuccess: false,
      isGetWarehouseProductsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get all warehouse product history
    [Actions.getWarehouseProductHistoryRequest]: (state) => ({
      ...state,
      isGetWarehouseProductHistoryRequest: true,
      isGetWarehouseProductHistorySuccess: false,
      isGetWarehouseProductHistoryFailure: false,
    }),
    [Actions.getWarehouseProductHistorySuccess]: (state, { payload }) => ({
      ...state,
      isGetWarehouseProductHistoryRequest: false,
      isGetWarehouseProductHistorySuccess: true,
      isGetWarehouseProductHistoryFailure: false,
      getWarehouseProductHistoryState: payload,
    }),
    [Actions.getWarehouseProductHistoryFailure]: (state, { payload }) => ({
      ...state,
      isGetWarehouseProductHistoryRequest: false,
      isGetWarehouseProductHistorySuccess: false,
      isGetWarehouseProductHistoryFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create warehouse
    [Actions.createWarehouseRequest]: (state) => ({
      ...state,
      isCreateWarehouseRequest: true,
      isCreateWarehouseSuccess: false,
      isCreateWarehouseFailure: false,
    }),
    [Actions.createWarehouseSuccess]: (state, { payload }) => ({
      ...state,
      isCreateWarehouseRequest: false,
      isCreateWarehouseSuccess: true,
      isCreateWarehouseFailure: false,
      getAllWarehousesState:
        state.getAllWarehousesState?.meta?.pagination?.current_page === 1
          ? {
            ...state.getAllWarehousesState,
            data:
              state.getAllWarehousesState.meta.pagination.count
                === state.getAllWarehousesState.meta.pagination.per_page
                ? [payload.data].concat(
                  state.getAllWarehousesState.data.slice(
                    0,
                    state.getAllWarehousesState.data.length - 1,
                  ),
                )
                : [payload.data].concat(state.getAllWarehousesState.data),
            meta: {
              ...state.getAllWarehousesState.meta,
              pagination: {
                ...state.getAllWarehousesState.meta.pagination,
                count:
                  state.getAllWarehousesState.meta.pagination.count
                    === state.getAllWarehousesState.meta.pagination.per_page
                    ? state.getAllWarehousesState.meta.pagination.per_page
                    : state.getAllWarehousesState.meta.pagination.count + 1,
                total: state.getAllWarehousesState.meta.pagination.total + 1,
                total_pages: Math.ceil(
                  (state.getAllWarehousesState.meta.pagination.total + 1)
                  / state.getAllWarehousesState.meta.pagination.per_page,
                ),
              },
            },
          }
          : { ...state.getAllWarehousesState },
    }),
    [Actions.createWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isCreateWarehouseRequest: false,
      isCreateWarehouseSuccess: false,
      isCreateWarehouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateWarehouseState]: (state) => ({
      ...state,
      isCreateWarehouseRequest: false,
      isCreateWarehouseSuccess: false,
      isCreateWarehouseFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update warehouse
    [Actions.updateWarehouseRequest]: (state) => ({
      ...state,
      isUpdateWarehouseRequest: true,
      isUpdateWarehouseSuccess: false,
      isUpdateWarehouseFailure: false,
    }),
    [Actions.updateWarehouseSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateWarehouseRequest: false,
      isUpdateWarehouseSuccess: true,
      isUpdateWarehouseFailure: false,
      getAllWarehousesState: {
        ...state.getAllWarehousesState,
        data: state.getAllWarehousesState.data.map((item) => item.id === payload.data.id ? payload.data : item),
      },
    }),
    [Actions.updateWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isUpdateWarehouseRequest: false,
      isUpdateWarehouseSuccess: false,
      isUpdateWarehouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateWarehouseState]: (state) => ({
      ...state,
      isUpdateWarehouseRequest: false,
      isUpdateWarehouseSuccess: false,
      isUpdateWarehouseFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete warehouse
    [Actions.deleteWarehouseRequest]: (state) => ({
      ...state,
      isDeleteWarehouseRequest: true,
      isDeleteWarehouseSuccess: false,
      isDeleteWarehouseFailure: false,
    }),
    [Actions.deleteWarehouseSuccess]: (state) => ({
      ...state,
      isDeleteWarehouseRequest: false,
      isDeleteWarehouseSuccess: true,
      isDeleteWarehouseFailure: false,
    }),
    [Actions.deleteWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isDeleteWarehouseRequest: false,
      isDeleteWarehouseSuccess: false,
      isDeleteWarehouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteWarehouseState]: (state) => ({
      ...state,
      isDeleteWarehouseRequest: false,
      isDeleteWarehouseSuccess: false,
      isDeleteWarehouseFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetWarehouseState]: () => initialState,
    // #endregion
    // #region : Get warehouse changes
    [Actions.getWarehouseChangesRequest]: (state) => ({
      ...state,
      isGetWarehouseChangesRequest: true,
      isGetWarehouseChangesSuccess: false,
      isGetWarehouseChangesFailure: false,
    }),
    [Actions.getWarehouseChangesSuccess]: (state, { payload }) => ({
      ...state,
      isGetWarehouseChangesRequest: false,
      isGetWarehouseChangesSuccess: true,
      isGetWarehouseChangesFailure: false,
      getWarehouseChangesState: payload,
    }),
    [Actions.getWarehouseChangesFailure]: (state, { payload }) => ({
      ...state,
      isGetWarehouseChangesRequest: false,
      isGetWarehouseChangesSuccess: false,
      isGetWarehouseChangesFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create warehouse change
    [Actions.createWarehouseChangeRequest]: (state) => ({
      ...state,
      isCreateWarehouseChangeRequest: true,
      isCreateWarehouseChangeSuccess: false,
      isCreateWarehouseChangeFailure: false,
    }),
    [Actions.createWarehouseChangeSuccess]: (state, { payload }) => ({
      ...state,
      isCreateWarehouseChangeRequest: false,
      isCreateWarehouseChangeSuccess: true,
      isCreateWarehouseChangeFailure: false,
      getWarehouseChangesState:
        state.getWarehouseChangesState?.meta?.pagination?.current_page === 1
          ? {
            ...state.getWarehouseChangesState,
            data:
              state.getWarehouseChangesState.meta.pagination.count
                === state.getWarehouseChangesState.meta.pagination.per_page
                ? [payload.data].concat(
                  state.getWarehouseChangesState.data.slice(
                    0,
                    state.getWarehouseChangesState.data.length - 1,
                  ),
                )
                : [payload.data].concat(state.getWarehouseChangesState.data),
            meta: {
              ...state.getWarehouseChangesState.meta,
              pagination: {
                ...state.getWarehouseChangesState.meta.pagination,
                count:
                  state.getWarehouseChangesState.meta.pagination.count
                    === state.getWarehouseChangesState.meta.pagination.per_page
                    ? state.getWarehouseChangesState.meta.pagination.per_page
                    : state.getWarehouseChangesState.meta.pagination.count + 1,
                total: state.getWarehouseChangesState.meta.pagination.total + 1,
                total_pages: Math.ceil(
                  (state.getWarehouseChangesState.meta.pagination.total + 1)
                  / state.getWarehouseChangesState.meta.pagination.per_page,
                ),
              },
            },
          }
          : { ...state.getWarehouseChangesState },
    }),
    [Actions.createWarehouseChangeFailure]: (state, { payload }) => ({
      ...state,
      isCreateWarehouseChangeRequest: false,
      isCreateWarehouseChangeSuccess: false,
      isCreateWarehouseChangeFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateWarehouseChangeState]: (state) => ({
      ...state,
      isCreateWarehouseChangeRequest: false,
      isCreateWarehouseChangeSuccess: false,
      isCreateWarehouseChangeFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Cancel warehouse change
    [Actions.cancelWarehouseChangeRequest]: (state) => ({
      ...state,
      isCancelWarehouseChangeRequest: true,
      isCancelWarehouseChangeSuccess: false,
      isCancelWarehouseChangeFailure: false,
    }),
    [Actions.cancelWarehouseChangeSuccess]: (state) => ({
      ...state,
      isCancelWarehouseChangeRequest: false,
      isCancelWarehouseChangeSuccess: true,
      isCancelWarehouseChangeFailure: false,
    }),
    [Actions.cancelWarehouseChangeFailure]: (state, { payload }) => ({
      ...state,
      isCancelWarehouseChangeRequest: false,
      isCancelWarehouseChangeSuccess: false,
      isCancelWarehouseChangeFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCancelWarehouseChangeState]: (state) => ({
      ...state,
      isCancelWarehouseChangeRequest: false,
      isCancelWarehouseChangeSuccess: false,
      isCancelWarehouseChangeFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Get all suppliers
    [Actions.getAllSuppliersRequest]: (state) => ({
      ...state,
      isGetAllSuppliersRequest: true,
      isGetAllSuppliersSuccess: false,
      isGetAllSuppliersFailure: false,
    }),
    [Actions.getAllSuppliersSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllSuppliersRequest: false,
      isGetAllSuppliersSuccess: true,
      isGetAllSuppliersFailure: false,
      getAllSuppliersState: payload,
    }),
    [Actions.getAllSuppliersFailure]: (state, { payload }) => ({
      ...state,
      isGetAllSuppliersRequest: false,
      isGetAllSuppliersSuccess: false,
      isGetAllSuppliersFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create supplier
    [Actions.createSupplierRequest]: (state) => ({
      ...state,
      isCreateSupplierRequest: true,
      isCreateSupplierSuccess: false,
      isCreateSupplierFailure: false,
    }),
    [Actions.createSupplierSuccess]: (state, { payload }) => ({
      ...state,
      isCreateSupplierRequest: false,
      isCreateSupplierSuccess: true,
      isCreateSupplierFailure: false,
      getAllSuppliersState:
        state.getAllSuppliersState?.meta?.pagination?.current_page === 1
          ? {
            ...state.getAllSuppliersState,
            data:
              state.getAllSuppliersState.meta.pagination.count
                === state.getAllSuppliersState.meta.pagination.per_page
                ? [payload.data].concat(
                  state.getAllSuppliersState.data.slice(
                    0,
                    state.getAllSuppliersState.data.length - 1,
                  ),
                )
                : [payload.data].concat(state.getAllSuppliersState.data),
            meta: {
              ...state.getAllSuppliersState.meta,
              pagination: {
                ...state.getAllSuppliersState.meta.pagination,
                count:
                  state.getAllSuppliersState.meta.pagination.count
                    === state.getAllSuppliersState.meta.pagination.per_page
                    ? state.getAllSuppliersState.meta.pagination.per_page
                    : state.getAllSuppliersState.meta.pagination.count + 1,
                total: state.getAllSuppliersState.meta.pagination.total + 1,
                total_pages: Math.ceil(
                  (state.getAllSuppliersState.meta.pagination.total + 1)
                  / state.getAllSuppliersState.meta.pagination.per_page,
                ),
              },
            },
          }
          : { ...state.getAllSuppliersState },
    }),
    [Actions.createSupplierFailure]: (state, { payload }) => ({
      ...state,
      isCreateSupplierRequest: false,
      isCreateSupplierSuccess: false,
      isCreateSupplierFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateSupplierState]: (state) => ({
      ...state,
      isCreateSupplierRequest: false,
      isCreateSupplierSuccess: false,
      isCreateSupplierFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update supplier
    [Actions.updateSupplierRequest]: (state) => ({
      ...state,
      isUpdateSupplierRequest: true,
      isUpdateSupplierSuccess: false,
      isUpdateSupplierFailure: false,
    }),
    [Actions.updateSupplierSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateSupplierRequest: false,
      isUpdateSupplierSuccess: true,
      isUpdateSupplierFailure: false,
      getAllSuppliersState: {
        ...state.getAllSuppliersState,
        data: state.getAllSuppliersState.data.map((item) => item.id === payload.data.id ? payload.data : item),
      },
    }),
    [Actions.updateSupplierFailure]: (state, { payload }) => ({
      ...state,
      isUpdateSupplierRequest: false,
      isUpdateSupplierSuccess: false,
      isUpdateSupplierFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateSupplierState]: (state) => ({
      ...state,
      isUpdateSupplierRequest: false,
      isUpdateSupplierSuccess: false,
      isUpdateSupplierFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete supplier
    [Actions.deleteSupplierRequest]: (state) => ({
      ...state,
      isDeleteSupplierRequest: true,
      isDeleteSupplierSuccess: false,
      isDeleteSupplierFailure: false,
    }),
    [Actions.deleteSupplierSuccess]: (state) => ({
      ...state,
      isDeleteSupplierRequest: false,
      isDeleteSupplierSuccess: true,
      isDeleteSupplierFailure: false,
    }),
    [Actions.deleteSupplierFailure]: (state, { payload }) => ({
      ...state,
      isDeleteSupplierRequest: false,
      isDeleteSupplierSuccess: false,
      isDeleteSupplierFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteSupplierState]: (state) => ({
      ...state,
      isDeleteSupplierRequest: false,
      isDeleteSupplierSuccess: false,
      isDeleteSupplierFailure: false,
      errorMessages: [],
    }),
    // #endregion
  },
  initialState,
);

export default reducer;
