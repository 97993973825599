/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllMembers({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload?.params ? `/users?${payload.params}` : '/users'));
    yield put(Actions.getAllMembersSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllMembersFailure(messages));
    }
  }
}

function* addMember({ payload }) {
  try {
    if (payload.avatar) {
      const imgData = new FormData();
      imgData.append('module', 'User');
      imgData.append('type', 'file');
      imgData.append('files[]', payload.avatar);
      const imgUrl = yield call(() => axiosMicro.post('/files/upload', imgData));
      payload.avatar = imgUrl?.data?.data[0];
    }
    const response = yield call(() => axiosMicro.post('/users', payload));
    yield put(Actions.addMemberSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.addMemberFailure(messages));
    }
  }
}

function* updateMember({ payload }) {
  try {
    const form = new FormData();

    form.append('name', payload.name);
    form.append('email', payload.email);

    if (payload.gender) {
      form.append('gender', payload.gender);
    }
    if (payload.birthday) {
      form.append('birthday', payload.birthday);
    }
    if (payload.avatar) {
      if (typeof payload.avatar !== 'string') {
        const imgData = new FormData();
        imgData.append('module', 'User');
        imgData.append('type', 'file');
        imgData.append('files[]', payload.avatar);
        const imgUrl = yield call(() => axiosMicro.post('/files/upload', imgData));
        form.set('avatar', imgUrl?.data?.data[0]);
      } else {
        form.append('avatar', payload.avatar);
      }
    }
    if (payload.phone) {
      form.append('phone', payload.phone);
    }
    if (payload.address !== '') {
      form.append('address', payload.address);
    }
    if (payload.status) {
      form.append('status', payload.status || 'disable');
    }

    const response = yield call(() => axiosMicro.post(`/users/${payload.id}?_method=PATCH`, form));
    yield put(Actions.updateMemberSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateMemberFailure(messages));
    }
  }
}

function* deleteMember({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/users/${payload}`));
    yield put(Actions.deleteMemberSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteMemberFailure(messages));
    }
  }
}

function* getDetailMember({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/users/${payload}`));
    yield put(Actions.getDetailMemberSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getDetailMemberFailure(messages));
    }
  }
}

function* checkEmailExist({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/users?search=email:${payload}`));
    yield put(Actions.checkEmailExistSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.checkEmailExistFailure(messages));
    }
  }
}

function* resetPasswordMember({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/users/${payload.id}`, { password: payload.password }));
    yield put(Actions.resetPasswordMemberSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.resetPasswordMemberFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllMembersRequest, getAllMembers);
  yield takeLatest(Actions.addMemberRequest, addMember);
  yield takeLatest(Actions.updateMemberRequest, updateMember);
  yield takeLatest(Actions.deleteMemberRequest, deleteMember);
  yield takeLatest(Actions.getDetailMemberRequest, getDetailMember);
  yield takeLatest(Actions.checkEmailExistRequest, checkEmailExist);
  yield takeLatest(Actions.resetPasswordMemberRequest, resetPasswordMember);
}
