const login = {
  'auth.login.hetmet': 'Trang đăng nhập',
  'auth.login.title': 'Trang đăng nhập',
  'auth.login.sub-title-1': 'Đăng nhập bằng thông tin đăng nhập',
  'auth.login.sub-title-2': 'của bạn để truy cập vào hệ thống!',
  'auth.login.btn-login': 'Đăng nhập',
  'auth.login.err-msg': 'Email hoặc mật khẩu của bạn đã sai. Vui lòng kiểm tra lại.',
};

const vnAuthLoginLangLib = {
  ...login,
};

export default vnAuthLoginLangLib;
