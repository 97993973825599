// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllSlidersRequest: false,
  isGetAllSlidersSuccess: false,
  isGetAllSlidersFailure: false,
  getAllSlidersState: {},
  //
  isCreateSliderRequest: false,
  isCreateSliderSuccess: false,
  isCreateSliderFailure: false,
  //
  isUpdateSliderRequest: false,
  isUpdateSliderSuccess: false,
  isUpdateSliderFailure: false,
  //
  isDeleteSliderRequest: false,
  isDeleteSliderSuccess: false,
  isDeleteSliderFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all sliders
    [Actions.getAllSlidersRequest]: (state) => ({
      ...state,
      isGetAllSlidersRequest: true,
      isGetAllSlidersSuccess: false,
      isGetAllSlidersFailure: false,
    }),
    [Actions.getAllSlidersSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllSlidersRequest: false,
      isGetAllSlidersSuccess: true,
      isGetAllSlidersFailure: false,
      getAllSlidersState: payload,
    }),
    [Actions.getAllSlidersFailure]: (state, { payload }) => ({
      ...state,
      isGetAllSlidersRequest: false,
      isGetAllSlidersSuccess: false,
      isGetAllSlidersFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create slider
    [Actions.createSliderRequest]: (state) => ({
      ...state,
      isCreateSliderRequest: true,
      isCreateSliderSuccess: false,
      isCreateSliderFailure: false,
    }),
    [Actions.createSliderSuccess]: (state, { payload }) => ({
      ...state,
      isCreateSliderRequest: false,
      isCreateSliderSuccess: true,
      isCreateSliderFailure: false,
      getAllSlidersState:
        state.getAllSlidersState?.meta?.pagination?.current_page === 1
          ? {
            ...state.getAllSlidersState,
            data:
                state.getAllSlidersState.meta.pagination.count
                === state.getAllSlidersState.meta.pagination.per_page
                  ? [payload.data].concat(
                    state.getAllSlidersState.data.slice(
                      0,
                      state.getAllSlidersState.data.length - 1,
                    ),
                  )
                  : [payload.data].concat(state.getAllSlidersState.data),
            meta: {
              ...state.getAllSlidersState.meta,
              pagination: {
                ...state.getAllSlidersState.meta.pagination,
                count:
                    state.getAllSlidersState.meta.pagination.count
                    === state.getAllSlidersState.meta.pagination.per_page
                      ? state.getAllSlidersState.meta.pagination.per_page
                      : state.getAllSlidersState.meta.pagination.count + 1,
                total: state.getAllSlidersState.meta.pagination.total + 1,
                total_pages: Math.ceil(
                  (state.getAllSlidersState.meta.pagination.total + 1)
                      / state.getAllSlidersState.meta.pagination.per_page,
                ),
              },
            },
          }
          : { ...state.getAllSlidersState },
    }),
    [Actions.createSliderFailure]: (state, { payload }) => ({
      ...state,
      isCreateSliderRequest: false,
      isCreateSliderSuccess: false,
      isCreateSliderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateSliderState]: (state) => ({
      ...state,
      isCreateSliderRequest: false,
      isCreateSliderSuccess: false,
      isCreateSliderFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update slider
    [Actions.updateSliderRequest]: (state) => ({
      ...state,
      isUpdateSliderRequest: true,
      isUpdateSliderSuccess: false,
      isUpdateSliderFailure: false,
    }),
    [Actions.updateSliderSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateSliderRequest: false,
      isUpdateSliderSuccess: true,
      isUpdateSliderFailure: false,
      getAllSlidersState: {
        ...state.getAllSlidersState,
        data: state.getAllSlidersState.data.map((item) => item.id === payload.data.id ? payload.data : item),
      },
    }),
    [Actions.updateSliderFailure]: (state, { payload }) => ({
      ...state,
      isUpdateSliderRequest: false,
      isUpdateSliderSuccess: false,
      isUpdateSliderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateSliderState]: (state) => ({
      ...state,
      isUpdateSliderRequest: false,
      isUpdateSliderSuccess: false,
      isUpdateSliderFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete slider
    [Actions.deleteSliderRequest]: (state) => ({
      ...state,
      isDeleteSliderRequest: true,
      isDeleteSliderSuccess: false,
      isDeleteSliderFailure: false,
    }),
    [Actions.deleteSliderSuccess]: (state) => ({
      ...state,
      isDeleteSliderRequest: false,
      isDeleteSliderSuccess: true,
      isDeleteSliderFailure: false,
    }),
    [Actions.deleteSliderFailure]: (state, { payload }) => ({
      ...state,
      isDeleteSliderRequest: false,
      isDeleteSliderSuccess: false,
      isDeleteSliderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteSliderState]: (state) => ({
      ...state,
      isDeleteSliderRequest: false,
      isDeleteSliderSuccess: false,
      isDeleteSliderFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetSliderState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
