/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllPayment({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/payments?${payload.params}` : '/payments?limit=0',
    ));
    yield put(Actions.getAllPaymentSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllPaymentFailure(messages));
    }
  }
}

function* createPayment({ payload }) {
  try {
    if (payload.image) {
      const imgData = new FormData();
      imgData.append('module', 'Payment');
      imgData.append('type', 'file');
      imgData.append('files[]', payload.image);
      const imgUrl = yield call(() => axiosMicro.post('/files/upload', imgData));
      payload.image = imgUrl?.data?.data[0];
    }
    if (payload.qrcode) {
      const imgData = new FormData();
      imgData.append('module', 'Payment');
      imgData.append('type', 'file');
      imgData.append('files[]', payload.qrcode);
      const imgUrl = yield call(() => axiosMicro.post('/files/upload', imgData));
      payload.qrcode = imgUrl?.data?.data[0];
    }
    const response = yield call(() => axiosMicro.post('/payments', payload));
    yield put(Actions.createPaymentSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createPaymentFailure(messages));
    }
  }
}

function* updatePayment({ payload }) {
  try {
    if (payload.data.image && typeof payload.data.image !== 'string') {
      const imgData = new FormData();
      imgData.append('module', 'Payment');
      imgData.append('type', 'file');
      imgData.append('files[]', payload.data.image);
      const imgUrl = yield call(() => axiosMicro.post('/files/upload', imgData));
      payload.data.image = imgUrl?.data?.data[0];
    }
    if (payload.data.qrcode && typeof payload.data.qrcode !== 'string') {
      const imgData = new FormData();
      imgData.append('module', 'Payment');
      imgData.append('type', 'file');
      imgData.append('files[]', payload.data.qrcode);
      const imgUrl = yield call(() => axiosMicro.post('/files/upload', imgData));
      payload.data.qrcode = imgUrl?.data?.data[0];
    }
    const response = yield call(() => axiosMicro.patch(`/payments/${payload.id}`, payload.data));
    yield put(Actions.updatePaymentSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updatePaymentFailure(messages));
    }
  }
}

function* deletePayment({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/payments/${payload}`));
    yield put(Actions.deletePaymentSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deletePaymentFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllPaymentRequest, getAllPayment);
  yield takeLatest(Actions.createPaymentRequest, createPayment);
  yield takeLatest(Actions.updatePaymentRequest, updatePayment);
  yield takeLatest(Actions.deletePaymentRequest, deletePayment);
}
