import { combineReducers } from 'redux';

import agency from './agency/reducer';
import auth from './auth/reducer';
import category from './category/reducer';
import comment from './comment/reducer';
import dataField from './dataField/reducer';
import dataGroup from './dataGroup/reducer';
import discount from './discount/reducer';
import feedback from './feedback/reducer';
import rating from './rating/reducer';
import general from './general/reducer';
import media from './media/reducer';
import member from './member/reducer';
import menu from './menu/reducer';
import menuItem from './menuItem/reducer';
import order from './order/reducer';
import permission from './permission/reducer';
import post from './post/reducer';
import role from './role/reducer';
import seo from './seo/reducer';
import settings from './settings/reducer';
import slider from './slider/reducer';
import postType from './type/reducer';
import user from './user/reducer';
import warehouse from './warehouse/reducer';
import widgets from './widget/reducer';
import product from './product/reducer';
import file from './file/reducer';
import config from './config/reducer';
import packages from './package/reducer';
import article from './article/reducer';
import payment from './payment/reducer';
import transaction from './transaction/reducer';

const rootReducer = combineReducers({
  agency,
  auth,
  category,
  comment,
  dataField,
  dataGroup,
  discount,
  feedback,
  rating,
  general,
  media,
  member,
  menu,
  menuItem,
  order,
  permission,
  post,
  role,
  seo,
  settings,
  slider,
  postType,
  user,
  warehouse,
  widgets,
  product,
  file,
  config,
  packages,
  article,
  payment,
  transaction,
});

export default rootReducer;
