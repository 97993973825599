/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllRatings({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/ratings?include=user,post&${payload.params}` : '/ratings?limit=0&include=user,post',
    ));
    yield put(Actions.getAllRatingsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllRatingsFailure(messages));
    }
  }
}

function* createRating({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/ratings', payload));
    yield put(Actions.createRatingSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createRatingFailure(messages));
    }
  }
}

function* deleteRating({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/ratings/${payload}`));
    yield put(Actions.deleteRatingSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteRatingFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllRatingsRequest, getAllRatings);
  yield takeLatest(Actions.createRatingRequest, createRating);
  yield takeLatest(Actions.deleteRatingRequest, deleteRating);
}
