const notFound = {
  'other.not-found.hetmet': 'Không tìm thấy trang',
  'other.not-found.title': 'Rất tiếc... có vẻ như đã xảy ra lỗi!',
  'other.not-found.code-title': 'Mã lỗi',
  'other.not-found.btn-back': 'Quay lại trang chủ',
};

const vnOtherNotFoundLangLib = {
  ...notFound,
};

export default vnOtherNotFoundLangLib;
