// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllWarehousesRequest = createAction('GET_ALL_WAREHOUSE_REQUEST');
export const getAllWarehousesSuccess = createAction('GET_ALL_WAREHOUSE_SUCCESS');
export const getAllWarehousesFailure = createAction('GET_ALL_WAREHOUSE_FAILURE');

export const getWarehouseProductsRequest = createAction('GET_WAREHOUSE_PRODUCT_REQUEST');
export const getWarehouseProductsSuccess = createAction('GET_WAREHOUSE_PRODUCT_SUCCESS');
export const getWarehouseProductsFailure = createAction('GET_WAREHOUSE_PRODUCT_FAILURE');

export const getWarehouseProductHistoryRequest = createAction('GET_WAREHOUSE_PRODUCT_HISTORY_REQUEST');
export const getWarehouseProductHistorySuccess = createAction('GET_WAREHOUSE_PRODUCT_HISTORY_SUCCESS');
export const getWarehouseProductHistoryFailure = createAction('GET_WAREHOUSE_PRODUCT_HISTORY_FAILURE');

export const createWarehouseRequest = createAction('CREATE_WAREHOUSE_REQUEST');
export const createWarehouseSuccess = createAction('CREATE_WAREHOUSE_SUCCESS');
export const createWarehouseFailure = createAction('CREATE_WAREHOUSE_FAILURE');
export const resetCreateWarehouseState = createAction('RESET_CREATE_WAREHOUSE_STATE');

export const updateWarehouseRequest = createAction('UPDATE_WAREHOUSE_REQUEST');
export const updateWarehouseSuccess = createAction('UPDATE_WAREHOUSE_SUCCESS');
export const updateWarehouseFailure = createAction('UPDATE_WAREHOUSE_FAILURE');
export const resetUpdateWarehouseState = createAction('RESET_UPDATE_WAREHOUSE_STATE');

export const deleteWarehouseRequest = createAction('DELETE_WAREHOUSE_REQUEST');
export const deleteWarehouseSuccess = createAction('DELETE_WAREHOUSE_SUCCESS');
export const deleteWarehouseFailure = createAction('DELETE_WAREHOUSE_FAILURE');
export const resetDeleteWarehouseState = createAction('RESET_DELETE_WAREHOUSE_STATE');

export const resetWarehouseState = createAction('RESET_WAREHOUSE_STATE');

export const getWarehouseChangesRequest = createAction('GET_WAREHOUSE_CHANGE_REQUEST');
export const getWarehouseChangesSuccess = createAction('GET_WAREHOUSE_CHANGE_SUCCESS');
export const getWarehouseChangesFailure = createAction('GET_WAREHOUSE_CHANGE_FAILURE');

export const createWarehouseChangeRequest = createAction('CREATE_WAREHOUSE_CHANGE_REQUEST');
export const createWarehouseChangeSuccess = createAction('CREATE_WAREHOUSE_CHANGE_SUCCESS');
export const createWarehouseChangeFailure = createAction('CREATE_WAREHOUSE_CHANGE_FAILURE');
export const resetCreateWarehouseChangeState = createAction('RESET_CREATE_WAREHOUSE_CHANGE_STATE');

export const cancelWarehouseChangeRequest = createAction('GET_ALL_WAREHOUSE_CHANGE_REQUEST');
export const cancelWarehouseChangeSuccess = createAction('GET_ALL_WAREHOUSE_CHANGE_SUCCESS');
export const cancelWarehouseChangeFailure = createAction('GET_ALL_WAREHOUSE_CHANGE_FAILURE');
export const resetCancelWarehouseChangeState = createAction('RESET_CANCEL_WAREHOUSE_CHANGE_STATE');

export const getAllSuppliersRequest = createAction('GET_ALL_SUPPLIER_REQUEST');
export const getAllSuppliersSuccess = createAction('GET_ALL_SUPPLIER_SUCCESS');
export const getAllSuppliersFailure = createAction('GET_ALL_SUPPLIER_FAILURE');

export const createSupplierRequest = createAction('CREATE_SUPPLIER_REQUEST');
export const createSupplierSuccess = createAction('CREATE_SUPPLIER_SUCCESS');
export const createSupplierFailure = createAction('CREATE_SUPPLIER_FAILURE');
export const resetCreateSupplierState = createAction('RESET_CREATE_SUPPLIER_STATE');

export const updateSupplierRequest = createAction('UPDATE_SUPPLIER_REQUEST');
export const updateSupplierSuccess = createAction('UPDATE_SUPPLIER_SUCCESS');
export const updateSupplierFailure = createAction('UPDATE_SUPPLIER_FAILURE');
export const resetUpdateSupplierState = createAction('RESET_UPDATE_SUPPLIER_STATE');

export const deleteSupplierRequest = createAction('DELETE_SUPPLIER_REQUEST');
export const deleteSupplierSuccess = createAction('DELETE_SUPPLIER_SUCCESS');
export const deleteSupplierFailure = createAction('DELETE_SUPPLIER_FAILURE');
export const resetDeleteSupplierState = createAction('RESET_DELETE_SUPPLIER_STATE');
