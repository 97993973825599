// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllMediaRequest: false,
  isGetAllMediaSuccess: false,
  isGetAllMediaFailure: false,
  getAllMediaState: {},
  //
  isCreateMediaRequest: false,
  isCreateMediaSuccess: false,
  isCreateMediaFailure: false,
  createMediaResponse: {},
  //
  isUpdateMediaRequest: false,
  isUpdateMediaSuccess: false,
  isUpdateMediaFailure: false,
  //
  isDeleteMediaRequest: false,
  isDeleteMediaSuccess: false,
  isDeleteMediaFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all media
    [Actions.getAllMediaRequest]: (state) => ({
      ...state,
      isGetAllMediaRequest: true,
      isGetAllMediaSuccess: false,
      isGetAllMediaFailure: false,
    }),
    [Actions.getAllMediaSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllMediaRequest: false,
      isGetAllMediaSuccess: true,
      isGetAllMediaFailure: false,
      getAllMediaState: payload,
    }),
    [Actions.getAllMediaFailure]: (state, { payload }) => ({
      ...state,
      isGetAllMediaRequest: false,
      isGetAllMediaSuccess: false,
      isGetAllMediaFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create media
    [Actions.createMediaRequest]: (state) => ({
      ...state,
      isCreateMediaRequest: true,
      isCreateMediaSuccess: false,
      isCreateMediaFailure: false,
    }),
    [Actions.createMediaSuccess]: (state, { payload }) => ({
      ...state,
      isCreateMediaRequest: false,
      isCreateMediaSuccess: true,
      isCreateMediaFailure: false,
      createMediaResponse: payload,
      getAllMediaState: {
        ...state.getAllMediaState,
        data: [payload.data, ...state.getAllMediaState.data],
        meta: {
          ...state.getAllMediaState.meta,
          pagination: {
            ...state.getAllMediaState.meta.pagination,
            count: state.getAllMediaState.meta.pagination.count + 1,
            total: state.getAllMediaState.meta.pagination.total + 1,
            per_page: state.getAllMediaState.meta.pagination.per_page + 1,
          },
        },
      },
    }),
    [Actions.createMediaFailure]: (state, { payload }) => ({
      ...state,
      isCreateMediaRequest: false,
      isCreateMediaSuccess: false,
      isCreateMediaFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateMediaState]: (state) => ({
      ...state,
      isCreateMediaRequest: false,
      isCreateMediaSuccess: false,
      isCreateMediaFailure: false,
      createMediaResponse: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Update media
    [Actions.updateMediaRequest]: (state) => ({
      ...state,
      isUpdateMediaRequest: true,
      isUpdateMediaSuccess: false,
      isUpdateMediaFailure: false,
    }),
    [Actions.updateMediaSuccess]: (state) => ({
      ...state,
      isUpdateMediaRequest: false,
      isUpdateMediaSuccess: true,
      isUpdateMediaFailure: false,
    }),
    [Actions.updateMediaFailure]: (state, { payload }) => ({
      ...state,
      isUpdateMediaRequest: false,
      isUpdateMediaSuccess: false,
      isUpdateMediaFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateMediaState]: (state) => ({
      ...state,
      isUpdateMediaRequest: false,
      isUpdateMediaSuccess: false,
      isUpdateMediaFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete media
    [Actions.deleteMediaRequest]: (state) => ({
      ...state,
      isDeleteMediaRequest: true,
      isDeleteMediaSuccess: false,
      isDeleteMediaFailure: false,
    }),
    [Actions.deleteMediaSuccess]: (state) => ({
      ...state,
      isDeleteMediaRequest: false,
      isDeleteMediaSuccess: true,
      isDeleteMediaFailure: false,
    }),
    [Actions.deleteMediaFailure]: (state, { payload }) => ({
      ...state,
      isDeleteMediaRequest: false,
      isDeleteMediaSuccess: false,
      isDeleteMediaFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteMediaState]: (state) => ({
      ...state,
      isDeleteMediaRequest: false,
      isDeleteMediaSuccess: false,
      isDeleteMediaFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetMediaState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
