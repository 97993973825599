const user = {};

const member = {
  'admin.user.member.hetmet': 'Thành viên - Tất cả người dùng',
  'admin.user.member.breadcrumb.heading': 'Tất cả người dùng',
  'admin.user.member.breadcrumb.parent': 'Thành viên',
  'admin.user.member.btn.add-new': 'Thêm người dùng mới',
  'admin.user.member.noti.create-member.title.success': 'Thêm thành viên mới thành công!',
  'admin.user.member.noti.create-member.title.failure': 'Thêm thành viên mới không thành công! Vui lòng thử lại sau.',
  'admin.user.member.noti.update-member.title.success': 'Cập nhật thành viên thành công!',
  'admin.user.member.noti.update-member.title.failure': 'Cập nhật thành viên không thành công! Vui lòng thử lại sau.',
  'admin.user.member.noti.delete-member.title.success': 'Xóa thành viên thành công!',
  'admin.user.member.noti.delete-member.title.failure': 'Xóa thành viên không thành công! Vui lòng thử lại sau.',
  'admin.user.member.noti.reset-password-member.title.success': 'Đặt lại mật khẩu của thành viên thành công!',
  'admin.user.member.noti.reset-password-member.title.failure': 'Đặt lại mật khẩu của thành viên không thành công! Vui lòng thử lại sau.',
  'admin.user.member.modal.add.title': 'Thêm người dùng mới',
  'admin.user.member.modal.update.title': 'Cập nhật thông tin người dùng',
  'admin.user.member.modal.detail.title': 'Thông tin chi tiết người dùng',
  'admin.user.member.modal.delete.title': 'Xác nhận xóa thành viên!',
  'admin.user.member.modal.delete.sub-title': 'Bạn chắc chắn muốn xóa thành viên này?',
  'admin.user.member.modal.reset-password.title': 'Xác nhận đặt lại mật khẩu cho thành viên này!',
  'admin.user.member.modal.reset-password.label': 'Chọn cách nhận mật khẩu mới:',
  'admin.user.member.modal.reset-password.input-1': 'Đặt lại mật khẩu mặc định',
  'admin.user.member.modal.reset-password.input-2': 'Gửi đến Gmail',
  'admin.user.member.modal.reset-password.noti-1': 'Mật khẩu mới:',
};

const clients = {
  'admin.user.clients.hetmet': 'Thành viên - Tất cả khách hàng',
  'admin.user.clients.breadcrumb.heading': 'Tất cả khách hàng',
  'admin.user.clients.breadcrumb.parent': 'Thành viên',
  'admin.user.clients.btn.add-new': 'Thêm khách hàng mới',
  'admin.user.clients.noti.create-clients.title.success': 'Thêm khách hàng mới thành công!',
  'admin.user.clients.noti.create-clients.title.failure': 'Thêm khách hàng mới không thành công! Vui lòng thử lại sau.',
  'admin.user.clients.noti.update-clients.title.success': 'Cập nhật khách hàng thành công!',
  'admin.user.clients.noti.update-clients.title.failure': 'Cập nhật khách hàng không thành công! Vui lòng thử lại sau.',
  'admin.user.clients.noti.delete-clients.title.success': 'Xóa khách hàng thành công!',
  'admin.user.clients.noti.delete-clients.title.failure': 'Xóa khách hàng không thành công! Vui lòng thử lại sau.',
  'admin.user.clients.noti.reset-password-clients.title.success': 'Đặt lại mật khẩu của khách hàng thành công!',
  'admin.user.clients.noti.reset-password-clients.title.failure': 'Đặt lại mật khẩu của khách hàng không thành công! Vui lòng thử lại sau.',
  'admin.user.clients.modal.add.title': 'Thêm khách hàng mới',
  'admin.user.clients.modal.update.title': 'Cập nhật thông tin khách hàng',
  'admin.user.clients.modal.detail.title': 'Thông tin chi tiết khách hàng',
  'admin.user.clients.modal.delete.title': 'Xác nhận xóa khách hàng!',
  'admin.user.clients.modal.delete.sub-title': 'Bạn chắc chắn muốn xóa khách hàng này?',
  'admin.user.clients.modal.reset-password.title': 'Xác nhận đặt lại mật khẩu cho khách hàng này!',
  'admin.user.clients.modal.reset-password.label': 'Chọn cách nhận mật khẩu mới:',
  'admin.user.clients.modal.reset-password.input-1': 'Đặt lại mật khẩu mặc định',
  'admin.user.clients.modal.reset-password.input-2': 'Gửi đến Gmail',
  'admin.user.clients.modal.reset-password.noti-1': 'Mật khẩu mới:',
};

const role = {
  'admin.user.role.hetmet': 'Thành viên - Vai trò',
  'admin.user.role.breadcrumb.heading': 'Vai trò',
  'admin.user.role.breadcrumb.parent': 'Thành viên',
  'admin.user.role.btn.add-new': 'Thêm vai trò mới',
  'admin.user.role.noti.create-role.title.success': 'Thêm vai trò mới thành công!',
  'admin.user.role.noti.create-role.title.failure': 'Thêm vai trò mới không thành công! Vui lòng thử lại sau.',
  'admin.user.role.noti.update-role.title.success': 'Cập nhật vai trò thành công!',
  'admin.user.role.noti.update-role.title.failure': 'Cập nhật vai trò không thành công! Vui lòng thử lại sau.',
  'admin.user.role.noti.delete-role.title.success': 'Xóa vai trò thành công!',
  'admin.user.role.noti.delete-role.title.failure': 'Xóa vai trò không thành công! Vui lòng thử lại sau.',
  'admin.user.role.modal.add.title': 'Thêm vai trò mới',
  'admin.user.role.modal.update.title': 'Cập nhật quyền cho vai trò',
  'admin.user.role.modal.delete.title': 'Xác nhận xóa vai trò!',
  'admin.user.role.modal.delete.sub-title': 'Bạn chắc chắn muốn xóa vai trò này?',
};

const permissions = {
  'admin.user.permissions.hetmet': 'Thành viên - Phân quyền',
  'admin.user.permissions.breadcrumb.heading': 'Phân quyền',
  'admin.user.permissions.breadcrumb.parent': 'Thành viên',
  'admin.user.permissions.btn.add-new': 'Phân quyền cho người dùng',
  'admin.user.permissions.noti.attach-role.title.success': 'Phân quyền cho người dùng thành công!',
  'admin.user.permissions.noti.attach-role.title.failure': 'Phân quyền cho người dùng không thành công! Vui lòng thử lại sau.',
  'admin.user.permissions.noti.revoke-role.title.success': 'Xóa phân quyền của người dùng thành công!',
  'admin.user.permissions.noti.revoke-role.title.failure': 'Xóa phân quyền của người dùng không thành công! Vui lòng thử lại sau.',
  'admin.user.permissions.modal.attach.title': 'Phân quyền cho người dùng',
  'admin.user.permissions.modal.revoke.title': 'Xác nhận xóa phân quyền của người dùng!',
  'admin.user.permissions.modal.revoke.sub-title': 'Bạn chắc chắn muốn xóa phân quyền của người dùng này?',
};

const vnAdminUserLangLib = {
  ...user,
  ...member,
  ...clients,
  ...role,
  ...permissions,
};

export default vnAdminUserLangLib;
