/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllCategories({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/categories?${payload.params}` : '/categories?limit=0',
    ));
    yield put(Actions.getAllCategoriesSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllCategoriesFailure(messages));
    }
  }
}

function* getCategoryParentList({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/categories?${payload.params}` : '/categories?limit=0',
    ));
    yield put(Actions.getCategoryParentListSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getCategoryParentListFailure(messages));
    }
  }
}

function* createCategory({ payload }) {
  try {
    if (payload.image) {
      const imgData = new FormData();
      imgData.append('module', 'Category');
      imgData.append('type', 'file');
      imgData.append('files[]', payload.image);
      const imgUrl = yield call(() => axiosMicro.post('/files/upload', imgData));
      payload.image = imgUrl?.data?.data[0];
    }
    const response = yield call(() => axiosMicro.post('/categories', payload));
    yield put(Actions.createCategorySuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createCategoryFailure(messages));
    }
  }
}

function* updateCategory({ payload }) {
  try {
    if (payload.body.image && typeof payload.body.image !== 'string') {
      const imgData = new FormData();
      imgData.append('module', 'Config');
      imgData.append('type', 'file');
      imgData.append('files[]', payload.body.image);
      const imgUrl = yield call(() => axiosMicro.post('/files/upload', imgData));
      payload.body.image = imgUrl?.data?.data[0];
    }
    const response = yield call(() => axiosMicro.patch(`/categories/${payload.id}`, payload.body));
    yield put(Actions.updateCategorySuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateCategoryFailure(messages));
    }
  }
}

function* deleteCategory({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/categories/${payload}`));
    yield put(Actions.deleteCategorySuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteCategoryFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllCategoriesRequest, getAllCategories);
  yield takeLatest(Actions.getCategoryParentListRequest, getCategoryParentList);
  yield takeLatest(Actions.createCategoryRequest, createCategory);
  yield takeLatest(Actions.updateCategoryRequest, updateCategory);
  yield takeLatest(Actions.deleteCategoryRequest, deleteCategory);
}
