// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllMediaRequest = createAction('GET_ALL_MEDIA_REQUEST');
export const getAllMediaSuccess = createAction('GET_ALL_MEDIA_SUCCESS');
export const getAllMediaFailure = createAction('GET_ALL_MEDIA_FAILURE');

export const createMediaRequest = createAction('CREATE_MEDIA_REQUEST');
export const createMediaSuccess = createAction('CREATE_MEDIA_SUCCESS');
export const createMediaFailure = createAction('CREATE_MEDIA_FAILURE');
export const resetCreateMediaState = createAction('RESET_CREATE_MEDIA_STATE');

export const updateMediaRequest = createAction('UPDATE_MEDIA_REQUEST');
export const updateMediaSuccess = createAction('UPDATE_MEDIA_SUCCESS');
export const updateMediaFailure = createAction('UPDATE_MEDIA_FAILURE');
export const resetUpdateMediaState = createAction('RESET_UPDATE_MEDIA_STATE');

export const deleteMediaRequest = createAction('DELETE_MEDIA_REQUEST');
export const deleteMediaSuccess = createAction('DELETE_MEDIA_SUCCESS');
export const deleteMediaFailure = createAction('DELETE_MEDIA_FAILURE');
export const resetDeleteMediaState = createAction('RESET_DELETE_MEDIA_STATE');

export const resetMediaState = createAction('RESET_MEDIA_STATE');
