// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllConfigRequest = createAction('GET_ALL_CONFIG_REQUEST');
export const getAllConfigSuccess = createAction('GET_ALL_CONFIG_SUCCESS');
export const getAllConfigFailure = createAction('GET_ALL_CONFIG_FAILURE');

export const createConfigRequest = createAction('CREATE_CONFIG_REQUEST');
export const createConfigSuccess = createAction('CREATE_CONFIG_SUCCESS');
export const createConfigFailure = createAction('CREATE_CONFIG_FAILURE');
export const resetCreateConfigState = createAction('RESET_CREATE_CONFIG_STATE');

export const createWatermarkConfigRequest = createAction('CREATE_WATERMARK_CONFIG_REQUEST');
export const updateWatermarkConfigRequest = createAction('UPDATE_WATERMARK_CONFIG_REQUEST');
export const createWatermarkConfigSuccess = createAction('CREATE_WATERMARK_CONFIG_SUCCESS');
export const createWatermarkConfigFailure = createAction('CREATE_WATERMARK_CONFIG_FAILURE');
export const resetCreateWatermarkConfigState = createAction('RESET_CREATE_WATERMARK_CONFIG_STATE');

export const updateConfigRequest = createAction('UPDATE_CONFIG_REQUEST');
export const updateConfigSuccess = createAction('UPDATE_CONFIG_SUCCESS');
export const updateConfigFailure = createAction('UPDATE_CONFIG_FAILURE');
export const resetUpdateConfigState = createAction('RESET_UPDATE_CONFIG_STATE');

export const deleteConfigRequest = createAction('DELETE_CONFIG_REQUEST');
export const deleteConfigSuccess = createAction('DELETE_CONFIG_SUCCESS');
export const deleteConfigFailure = createAction('DELETE_CONFIG_FAILURE');
export const resetDeleteConfigState = createAction('RESET_DELETE_CONFIG_STATE');

export const resetConfigState = createAction('RESET_CONFIG_STATE');
