const widget = {
  'admin.widgets.hetmet': 'List widgets',
  'admin.widgets.breadcrumb.heading': 'Manage widgets',
  'admin.widgets.breadcrumb.parent': 'Widgets',
  'admin.widgets.btn.add': 'Add new widget',
  'admin.widget.modal.action.label.key': 'Key',
  'admin.widget.error-msg.key': 'Please enter key',
  'admin.widget.error-msg.title': 'Please enter title',
  'admin.widget.modal.action.label.title': 'Title',
  'admin.widget.modal.action.label.icon': 'Icon',
  'admin.widget.modal.action.label.group': 'Group',
  'admin.widget.noti.create-widget.failure': 'Create widget failure',
  'admin.widget.noti.update-widget.failure': 'Update widget failure',
  'admin.widget.noti.delete-widget.failure': 'Delete widget failure',
  'admin.widget.modal.action.title.create': 'Create widget',
  'admin.widget.modal.action.title.update': 'Update widget',
  'admin.widget.modal.action.title.details': 'Detail widget',
  'admin.widget.modal.delete.title': 'Confirmation of delete',
  'admin.widget.modal.delete.description': 'Are you sure to delete this widget',
  'admin.widget.noti.delete-widget.success': 'Delete widget successfully',
  'admin.widget.noti.create-widget.success': 'Create widget successfully',
  'admin.widget.noti.update-widget.success': 'Update widget successfully',
  'admin.widget.noti.duplicate': 'Key is duplicate',
};

const usAdminWidgetLangLib = {
  ...widget,
};

export default usAdminWidgetLangLib;
