/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllProducts({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload?.params ? `/products?${payload.params}` : '/products'));
    yield put(Actions.getAllProductsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllProductsFailure(messages));
    }
  }
}

function* addProduct({ payload }) {
  try {
    if (payload.image) {
      const imgData = new FormData();
      imgData.append('module', 'Product');
      imgData.append('type', 'file');
      imgData.append('files[]', payload.image);
      const imgUrl = yield call(() => axiosMicro.post('/files/upload', imgData));
      payload.image = imgUrl?.data?.data[0];
    }
    if (payload.file) {
      const imgData = new FormData();
      imgData.append('module', 'Product');
      imgData.append('type', 'file');
      imgData.append('files[]', payload.file);
      const fileUrl = yield call(() => axiosMicro.post('/files/upload', imgData));
      payload.file = fileUrl?.data?.data[0];
    }
    for (let i = 0; i < payload.assets.length; i += 1) {
      const asset = payload.assets[i];
      if (asset.image && typeof asset.image !== 'string') {
        const imgData = new FormData();
        imgData.append('module', 'Product');
        imgData.append('type', 'file');
        imgData.append('files[]', asset.image);
        const imgUrl = yield call(() => axiosMicro.post('/files/upload', imgData));
        asset.image = imgUrl?.data?.data[0];
      }
      if (asset.file && typeof asset.file !== 'string') {
        const imgData = new FormData();
        imgData.append('module', 'Product');
        imgData.append('type', 'file');
        imgData.append('files[]', asset.file);
        const imgUrl = yield call(() => axiosMicro.post('/files/upload', imgData));
        asset.file = imgUrl?.data?.data[0];
      }
    }
    const response = yield call(() => axiosMicro.post('/products', payload));
    yield put(Actions.addProductSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.addProductFailure(messages));
    }
  }
}

function* updateProduct({ payload }) {
  try {
    if (payload.data.image && typeof payload.data.image !== 'string') {
      const imgData = new FormData();
      imgData.append('module', 'Product');
      imgData.append('type', 'file');
      imgData.append('files[]', payload.data.image);
      const imgUrl = yield call(() => axiosMicro.post('/files/upload', imgData));
      payload.data.image = imgUrl?.data?.data[0];
    }
    if (payload.data.file && typeof payload.data.file !== 'string') {
      const imgData = new FormData();
      imgData.append('module', 'Product');
      imgData.append('type', 'file');
      imgData.append('files[]', payload.data.file);
      const fileUrl = yield call(() => axiosMicro.post('/files/upload', imgData));
      payload.data.file = fileUrl?.data?.data[0];
    }
    for (let i = 0; i < payload.data.assets.length; i += 1) {
      const asset = payload.data.assets[i];
      if (asset.image && typeof asset.image !== 'string') {
        const imgData = new FormData();
        imgData.append('module', 'Product');
        imgData.append('type', 'file');
        imgData.append('files[]', asset.image);
        const imgUrl = yield call(() => axiosMicro.post('/files/upload', imgData));
        asset.image = imgUrl?.data?.data[0];
      }
      if (asset.file && typeof asset.file !== 'string') {
        const imgData = new FormData();
        imgData.append('module', 'Product');
        imgData.append('type', 'file');
        imgData.append('files[]', asset.file);
        const imgUrl = yield call(() => axiosMicro.post('/files/upload', imgData));
        asset.file = imgUrl?.data?.data[0];
      }
    }
    const response = yield call(() => axiosMicro.patch(`/products/${payload.id}`, payload.data));
    yield put(Actions.updateProductSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateProductFailure(messages));
    }
  }
}

function* deleteProduct({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/products/${payload}`));
    yield put(Actions.deleteProductSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteProductFailure(messages));
    }
  }
}

function* getDetailProduct({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/products/${payload}`));
    yield put(Actions.getDetailProductSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getDetailProductFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllProductsRequest, getAllProducts);
  yield takeLatest(Actions.addProductRequest, addProduct);
  yield takeLatest(Actions.updateProductRequest, updateProduct);
  yield takeLatest(Actions.deleteProductRequest, deleteProduct);
  yield takeLatest(Actions.getDetailProductRequest, getDetailProduct);
}
