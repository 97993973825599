// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllCommentsRequest: false,
  isGetAllCommentsSuccess: false,
  isGetAllCommentsFailure: false,
  getAllCommentsState: {},
  //
  isCreateCommentRequest: false,
  isCreateCommentSuccess: false,
  isCreateCommentFailure: false,
  createCommentRes: {},
  //
  isCreateUserCommentRequest: false,
  isCreateUserCommentSuccess: false,
  isCreateUserCommentFailure: false,
  //
  isUpdateCommentRequest: false,
  isUpdateCommentSuccess: false,
  isUpdateCommentFailure: false,
  updateCommentRes: {},
  //
  isDeleteCommentRequest: false,
  isDeleteCommentSuccess: false,
  isDeleteCommentFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all comments
    [Actions.getAllCommentsRequest]: (state) => ({
      ...state,
      isGetAllCommentsRequest: true,
      isGetAllCommentsSuccess: false,
      isGetAllCommentsFailure: false,
    }),
    [Actions.getAllCommentsSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllCommentsRequest: false,
      isGetAllCommentsSuccess: true,
      isGetAllCommentsFailure: false,
      getAllCommentsState: payload,
    }),
    [Actions.getAllCommentsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllCommentsRequest: false,
      isGetAllCommentsSuccess: false,
      isGetAllCommentsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create comment
    [Actions.createCommentRequest]: (state) => ({
      ...state,
      isCreateCommentRequest: true,
      isCreateCommentSuccess: false,
      isCreateCommentFailure: false,
    }),
    [Actions.createCommentSuccess]: (state, { payload }) => {
      const getAllCommentsStateDataClone = [...state.getAllCommentsState.data];

      getAllCommentsStateDataClone.splice(
        getAllCommentsStateDataClone.findIndex(
          (item) => item.id === payload.data.comment_id,
        ) + 1,
        0,
        {
          ...payload.data,
          comment: {
            data: getAllCommentsStateDataClone.find(
              (item) => item.id === payload.data.comment_id,
            ),
          },
          post: getAllCommentsStateDataClone.find(
            (item) => item.id === payload.data.comment_id,
          ).post,
        },
      );

      return {
        ...state,
        isCreateCommentRequest: false,
        isCreateCommentSuccess: true,
        isCreateCommentFailure: false,
        getAllCommentsState: {
          data: [...getAllCommentsStateDataClone],
          meta: {
            ...state.getAllCommentsState.meta,
            pagination: {
              ...state.getAllCommentsState.meta.pagination,
              count: state.getAllCommentsState.meta.pagination.count + 1,
              total: state.getAllCommentsState.meta.pagination.total + 1,
            },
          },
        },
      };
    },
    [Actions.createCommentFailure]: (state, { payload }) => ({
      ...state,
      isCreateCommentRequest: false,
      isCreateCommentSuccess: false,
      isCreateCommentFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateCommentState]: (state) => ({
      ...state,
      isCreateCommentRequest: false,
      isCreateCommentSuccess: false,
      isCreateCommentFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Create user comment
    [Actions.createUserCommentRequest]: (state) => ({
      ...state,
      isCreateUserCommentRequest: true,
      isCreateUserCommentSuccess: false,
      isCreateUserCommentFailure: false,
    }),
    [Actions.createUserCommentSuccess]: (state) => ({
      ...state,
      isCreateUserCommentRequest: false,
      isCreateUserCommentSuccess: true,
      isCreateUserCommentFailure: false,
    }),
    [Actions.createUserCommentFailure]: (state, { payload }) => ({
      ...state,
      isCreateUserCommentRequest: false,
      isCreateUserCommentSuccess: false,
      isCreateUserCommentFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateUserCommentState]: (state) => ({
      ...state,
      isCreateUserCommentRequest: false,
      isCreateUserCommentSuccess: false,
      isCreateUserCommentFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update comment
    [Actions.updateCommentRequest]: (state) => ({
      ...state,
      isUpdateCommentRequest: true,
      isUpdateCommentSuccess: false,
      isUpdateCommentFailure: false,
    }),
    [Actions.updateCommentSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateCommentRequest: false,
      isUpdateCommentSuccess: true,
      isUpdateCommentFailure: false,
      getAllCommentsState: {
        ...state.getAllCommentsState,
        data: state.getAllCommentsState.data.map((item) => item.id === payload.data.id
          ? { ...item, message: payload.data.message }
          : item),
      },
    }),
    [Actions.updateCommentFailure]: (state, { payload }) => ({
      ...state,
      isUpdateCommentRequest: false,
      isUpdateCommentSuccess: false,
      isUpdateCommentFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateCommentState]: (state) => ({
      ...state,
      isUpdateCommentRequest: false,
      isUpdateCommentSuccess: false,
      isUpdateCommentFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete comment
    [Actions.deleteCommentRequest]: (state) => ({
      ...state,
      isDeleteCommentRequest: true,
      isDeleteCommentSuccess: false,
      isDeleteCommentFailure: false,
    }),
    [Actions.deleteCommentSuccess]: (state) => ({
      ...state,
      isDeleteCommentRequest: false,
      isDeleteCommentSuccess: true,
      isDeleteCommentFailure: false,
    }),
    [Actions.deleteCommentFailure]: (state, { payload }) => ({
      ...state,
      isDeleteCommentRequest: false,
      isDeleteCommentSuccess: false,
      isDeleteCommentFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteCommentState]: (state) => ({
      ...state,
      isDeleteCommentRequest: false,
      isDeleteCommentSuccess: false,
      isDeleteCommentFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetCommentState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
