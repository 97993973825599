const transaction = {
  'admin.transaction.hetmet': 'Tất cả giao dịch',
  'admin.transaction.default': 'Giao dịch mặc định',
  'admin.transaction.breadcrumb.heading': 'Quản lý giao dịch',
  'admin.transaction.breadcrumb.parent': 'Giao dịch',
  'admin.transaction.btn.add-new': 'Thêm giao dịch mới',
  'admin.transaction.noti.create-transaction.title.success': 'Thêm giao dịch mới thành công!',
  'admin.transaction.noti.create-transaction.title.failure': 'Thêm giao dịch mới không thành công! Vui lòng thử lại sau.',
  'admin.transaction.noti.update-transaction.title.success': 'Cập nhật giao dịch thành công!',
  'admin.transaction.noti.update-transaction.title.failure': 'Cập nhật giao dịch không thành công! Vui lòng thử lại sau.',
  'admin.transaction.noti.delete-transaction.title.success': 'Xóa giao dịch thành công!',
  'admin.transaction.noti.delete-transaction.title.failure': 'Xóa giao dịch không thành công! Vui lòng thử lại sau.',
  'admin.transaction.modal.add.title': 'Thêm giao dịch mới',
  'admin.transaction.modal.update.title': 'Cập nhật thông tin giao dịch',
  'admin.transaction.modal.approve.title': 'Duyệt thông tin giao dịch',
  'admin.transaction.modal.detail.title': 'Thông tin chi tiết giao dịch',
  'admin.transaction.modal.delete.title': 'Xác nhận xóa giao dịch!',
  'admin.transaction.modal.delete.sub-title': 'Bạn chắc chắn muốn xóa giao dịch này?',
};

const vnAdminTransactionLangLib = {
  ...transaction,
};

export default vnAdminTransactionLangLib;
