// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllProductsRequest = createAction('GET_ALL_PRODUCTS_REQUEST');
export const getAllProductsSuccess = createAction('GET_ALL_PRODUCTS_SUCCESS');
export const getAllProductsFailure = createAction('GET_ALL_PRODUCTS_FAILURE');

export const addProductRequest = createAction('ADD_PRODUCT_REQUEST');
export const addProductSuccess = createAction('ADD_PRODUCT_SUCCESS');
export const addProductFailure = createAction('ADD_PRODUCT_FAILURE');
export const resetAddProductState = createAction('RESET_ADD_PRODUCT_STATE');

export const updateProductRequest = createAction('UPDATE_PRODUCT_REQUEST');
export const updateProductSuccess = createAction('UPDATE_PRODUCT_SUCCESS');
export const updateProductFailure = createAction('UPDATE_PRODUCT_FAILURE');
export const resetUpdateProductState = createAction('RESET_UPDATE_PRODUCT_STATE');

export const deleteProductRequest = createAction('DELETE_PRODUCT_REQUEST');
export const deleteProductSuccess = createAction('DELETE_PRODUCT_SUCCESS');
export const deleteProductFailure = createAction('DELETE_PRODUCT_FAILURE');
export const resetDeleteProductState = createAction('RESET_DELETE_PRODUCT_STATE');

export const getDetailProductRequest = createAction('GET_DETAIL_PRODUCT_REQUEST');
export const getDetailProductSuccess = createAction('GET_DETAIL_PRODUCT_SUCCESS');
export const getDetailProductFailure = createAction('GET_DETAIL_PRODUCT_FAILURE');
export const resetGetDetailProductState = createAction(
  'RESET_GET_DETAIL_PRODUCT_STATE',
);

export const resetProductState = createAction('RESET_PRODUCT_STATE');
