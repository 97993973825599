const packages = {
  'admin.package.hetmet': 'Tất cả gói',
  'admin.package.default': 'Gói mặc định',
  'admin.package.breadcrumb.heading': 'Quản lý gói',
  'admin.package.breadcrumb.parent': 'Gói',
  'admin.package.btn.add-new': 'Thêm gói mới',
  'admin.package.noti.create-package.title.success': 'Thêm gói mới thành công!',
  'admin.package.noti.create-package.title.failure': 'Thêm gói mới không thành công! Vui lòng thử lại sau.',
  'admin.package.noti.update-package.title.success': 'Cập nhật gói thành công!',
  'admin.package.noti.update-package.title.failure': 'Cập nhật gói không thành công! Vui lòng thử lại sau.',
  'admin.package.noti.delete-package.title.success': 'Xóa gói thành công!',
  'admin.package.noti.delete-package.title.failure': 'Xóa gói không thành công! Vui lòng thử lại sau.',
  'admin.package.modal.add.title': 'Thêm gói mới',
  'admin.package.modal.update.title': 'Cập nhật thông tin gói',
  'admin.package.modal.detail.title': 'Thông tin chi tiết gói',
  'admin.package.modal.delete.title': 'Xác nhận xóa gói!',
  'admin.package.modal.delete.sub-title': 'Bạn chắc chắn muốn xóa gói này?',
};

const vnAdminPackageLangLib = {
  ...packages,
};

export default vnAdminPackageLangLib;
