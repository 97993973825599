const media = {
  'admin.media.hetmet': 'Thư viện ảnh - Media',
  'admin.media.breadcrumb.heading': 'Thư viện ảnh',
  'admin.media.breadcrumb.parent': 'Media',
  //
  'admin.media.btn.add-new': 'Thêm media mới',
  'admin.media.btn.refresh': 'Làm mới',
  'admin.media.noti-msg.add-success': 'Thêm tập tin mới thành công',
  'admin.media.noti-msg.edit-success': 'Sửa tập tin thành công',
  'admin.media.noti-msg.delete-success': 'Xóa tập tin thành công',
  //
  'admin.media.modal.add-new.title': 'Thêm media mới',
  //
  'admin.media.modal.details.title': 'Chi tiết đính kèm',
  'admin.media.modal.details.label.created_at': 'Đã tải lên vào lúc',
  'admin.media.modal.details.label.updated_at': 'Cập nhật gần đây vào lúc',
  'admin.media.modal.details.label.path': 'Tên tập tin',
  'admin.media.modal.details.label.type': 'Loại tập tin',
  'admin.media.modal.details.label.size': 'Dung lượng tệp',
  'admin.media.modal.details.label.name': 'Tiêu đề',
  'admin.media.modal.details.label.url': 'File URL',
  'admin.media.modal.details.btn-copy-url': 'Sao chép URL vào bộ nhớ tạm',
  'admin.media.modal.details.noti-copy-url-success': 'Sao chép URL thành công',
  'admin.media.modal.details.link-delete': 'Xóa vĩnh viễn',
  'admin.media.modal.details.note': 'Các trường bắt buộc được đánh dấu',
  //
  'admin.media.modal.delete.title': 'Xác nhận xóa tập tin',
  'admin.media.modal.delete.sub-title': 'Bạn chắc chắn sẽ xóa tập tin này?',
  'admin.media.modal.delete.noti-failure': 'Xóa tập tin không thành công!',
  //
  'modal.media.header.title': 'Thêm media',
  'modal.media.btn.select-media': 'Chọn media',
  'modal.media.btn.crop-media': 'Chỉnh sửa hình ảnh',
  'modal.media.dropdown.item.download': 'Tải xuống',
  'modal.media.dropdown.item.details': 'Xem chi tiết',
  'modal.media.dropdown.item.delete': 'Xóa',
};

const modalCropImage = {
  'modal-crop-image.title': 'Chỉnh sửa ảnh',
  'modal-crop-image.btn.rotation': 'Xoay ảnh',
  'modal-crop-image.btn.zoom': 'Thu - Phóng',
  'modal-crop-image.btn.download': 'Tải xuống',
  'modal-crop-image.btn.cancel': 'Hủy',
  'modal-crop-image.btn.done': 'Hoàn tất',
  'modal-crop-image.btn.preview': 'Xem trước',
  'modal-crop-image.btn.rotate-left': 'Xoay',
  'modal-crop-image.btn.flip-horizontal': 'Lật',
  'modal-crop-image.crop-image-success-title': 'Chỉnh sửa ảnh thành công!',
  'modal-crop-image.crop-image-failure-title': 'Chỉnh sửa ảnh không thành công! Vui lòng thử lại sau.',
};

const vnAdminMediaLangLib = {
  ...media,
  ...modalCropImage,
};

export default vnAdminMediaLangLib;
