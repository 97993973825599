// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllFeedbacksRequest = createAction('GET_ALL_FEEDBACKS_REQUEST');
export const getAllFeedbacksSuccess = createAction('GET_ALL_FEEDBACKS_SUCCESS');
export const getAllFeedbacksFailure = createAction('GET_ALL_FEEDBACKS_FAILURE');

export const createFeedbackRequest = createAction('CREATE_FEEDBACK_REQUEST');
export const createFeedbackSuccess = createAction('CREATE_FEEDBACK_SUCCESS');
export const createFeedbackFailure = createAction('CREATE_FEEDBACK_FAILURE');
export const resetCreateFeedbackState = createAction('RESET_CREATE_FEEDBACK_STATE');

export const updateFeedbackRequest = createAction('UPDATE_FEEDBACK_REQUEST');
export const updateFeedbackSuccess = createAction('UPDATE_FEEDBACK_SUCCESS');
export const updateFeedbackFailure = createAction('UPDATE_FEEDBACK_FAILURE');
export const resetUpdateFeedbackState = createAction('RESET_UPDATE_FEEDBACK_STATE');

export const deleteFeedbackRequest = createAction('DELETE_FEEDBACK_REQUEST');
export const deleteFeedbackSuccess = createAction('DELETE_FEEDBACK_SUCCESS');
export const deleteFeedbackFailure = createAction('DELETE_FEEDBACK_FAILURE');
export const resetDeleteFeedbackState = createAction('RESET_DELETE_FEEDBACK_STATE');

export const resetFeedbackState = createAction('RESET_FEEDBACK_STATE');
