export const defaultMenuType = 'menu-default';
export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;

export const defaultLocale = 'vn';
export const localeUS = 'us';
export const localeOptions = [
  { id: 'vn', name: 'common.lang-options.vn', direction: 'ltr' },
  { id: 'us', name: 'common.lang-options.us', direction: 'ltr' },
];

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light';
export const colorDark = 'dark';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';

export const defaultPasswordReset = 'Satek123@';
