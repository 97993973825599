// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getSettingsRequest = createAction('GET_SETTINGS_REQUEST');
export const getSettingsSuccess = createAction('GET_SETTINGS_SUCCESS');
export const getSettingsFailure = createAction('GET_SETTINGS_FAILURE');

export const getAllSettingsRequest = createAction('GET_ALL_SETTINGS_REQUEST');
export const getAllSettingsSuccess = createAction('GET_ALL_SETTINGS_SUCCESS');
export const getAllSettingsFailure = createAction('GET_ALL_SETTINGS_FAILURE');

export const createSettingRequest = createAction('CREATE_SETTING_REQUEST');
export const createSettingSuccess = createAction('CREATE_SETTING_SUCCESS');
export const createSettingFailure = createAction('CREATE_SETTING_FAILURE');
export const resetCreateSettingState = createAction('RESET_CREATE_SETTING_STATE');

export const saveSettingRequest = createAction('SAVE_SETTING_REQUEST');
export const saveSettingSuccess = createAction('SAVE_SETTING_SUCCESS');
export const saveSettingFailure = createAction('SAVE_SETTING_FAILURE');
export const resetSaveSettingState = createAction('RESET_SAVE_SETTING_STATE');

export const deleteSettingRequest = createAction('DELETE_SETTING_REQUEST');
export const deleteSettingSuccess = createAction('DELETE_SETTING_SUCCESS');
export const deleteSettingFailure = createAction('DELETE_SETTING_FAILURE');
export const resetDeleteSettingState = createAction('RESET_DELETE_SETTING_STATE');

export const getAllSettingRedirectRequest = createAction('GET_ALL_SETTINGS_REDIRECT_REQUEST');
export const getAllSettingRedirectSuccess = createAction('GET_ALL_SETTINGS_REDIRECT_SUCCESS');
export const getAllSettingRedirectFailure = createAction('GET_ALL_SETTINGS_REDIRECT_FAILURE');

export const createSettingRedirectRequest = createAction('CREATE_SETTING_REDIRECT_REQUEST');
export const createSettingRedirectSuccess = createAction('CREATE_SETTING_REDIRECT_SUCCESS');
export const createSettingRedirectFailure = createAction('CREATE_SETTING_REDIRECT_FAILURE');
export const resetCreateSettingRedirectState = createAction('RESET_CREATE_SETTING_REDIRECT_STATE');

export const updateSettingRedirectRequest = createAction('UPDATE_SETTING_REDIRECT_REQUEST');
export const updateSettingRedirectSuccess = createAction('UPDATE_SETTING_REDIRECT_SUCCESS');
export const updateSettingRedirectFailure = createAction('UPDATE_SETTING_REDIRECT_FAILURE');
export const resetUpdateSettingRedirectState = createAction('RESET_UPDATE_SETTING_REDIRECT_STATE');

export const deleteSettingRedirectRequest = createAction('DELETE_SETTING_REDIRECT_REQUEST');
export const deleteSettingRedirectSuccess = createAction('DELETE_SETTING_REDIRECT_SUCCESS');
export const deleteSettingRedirectFailure = createAction('DELETE_SETTING_REDIRECT_FAILURE');
export const resetDeleteSettingRedirectState = createAction('RESET_DELETE_SETTING_REDIRECT_STATE');

export const resetSettingsState = createAction('RESET_SETTINGS_STATE');
