/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllTransaction({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/transactions?${payload.params}` : '/transactions?limit=0',
    ));
    yield put(Actions.getAllTransactionSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllTransactionFailure(messages));
    }
  }
}

function* createTransaction({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/transactions', payload));
    yield put(Actions.createTransactionSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createTransactionFailure(messages));
    }
  }
}

function* updateTransaction({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/transactions/${payload.id}`, payload.data));
    yield put(Actions.updateTransactionSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateTransactionFailure(messages));
    }
  }
}

function* deleteTransaction({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/transactions/${payload}`));
    yield put(Actions.deleteTransactionSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteTransactionFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllTransactionRequest, getAllTransaction);
  yield takeLatest(Actions.createTransactionRequest, createTransaction);
  yield takeLatest(Actions.updateTransactionRequest, updateTransaction);
  yield takeLatest(Actions.deleteTransactionRequest, deleteTransaction);
}
