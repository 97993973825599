// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllAgenciesRequest = createAction('GET_ALL_AGENCIES_REQUEST');
export const getAllAgenciesSuccess = createAction('GET_ALL_AGENCIES_SUCCESS');
export const getAllAgenciesFailure = createAction('GET_ALL_AGENCIES_FAILURE');

export const createAgencyRequest = createAction('CREATE_AGENCY_REQUEST');
export const createAgencySuccess = createAction('CREATE_AGENCY_SUCCESS');
export const createAgencyFailure = createAction('CREATE_AGENCY_FAILURE');
export const resetCreateAgencyState = createAction('RESET_CREATE_AGENCY_STATE');

export const updateAgencyRequest = createAction('UPDATE_AGENCY_REQUEST');
export const updateAgencySuccess = createAction('UPDATE_AGENCY_SUCCESS');
export const updateAgencyFailure = createAction('UPDATE_AGENCY_FAILURE');
export const resetUpdateAgencyState = createAction('RESET_UPDATE_AGENCY_STATE');

export const deleteAgencyRequest = createAction('DELETE_AGENCY_REQUEST');
export const deleteAgencySuccess = createAction('DELETE_AGENCY_SUCCESS');
export const deleteAgencyFailure = createAction('DELETE_AGENCY_FAILURE');
export const resetDeleteAgencyState = createAction('RESET_DELETE_AGENCY_STATE');

export const attachUsersToAgencyRequest = createAction('ATTACH_USERS_TO_AGENCY_REQUEST');
export const attachUsersToAgencySuccess = createAction('ATTACH_USERS_TO_AGENCY_SUCCESS');
export const attachUsersToAgencyFailure = createAction('ATTACH_USERS_TO_AGENCY_FAILURE');
export const resetAttachUsersToAgencyState = createAction('RESET_ATTACH_USERS_TO_AGENCY_STATE');

export const detachUsersToAgencyRequest = createAction('DETACH_USERS_TO_AGENCY_REQUEST');
export const detachUsersToAgencySuccess = createAction('DETACH_USERS_TO_AGENCY_SUCCESS');
export const detachUsersToAgencyFailure = createAction('DETACH_USERS_TO_AGENCY_FAILURE');
export const resetDetachUsersToAgencyState = createAction('RESET_DETACH_USERS_TO_AGENCY_STATE');

export const getUsersAgencyRequest = createAction('GET_USERS_AGENCY_REQUEST');
export const getUsersAgencySuccess = createAction('GET_USERS_AGENCY_SUCCESS');
export const getUsersAgencyFailure = createAction('GET_USERS_AGENCY_FAILURE');
export const resetGetUsersAgencyState = createAction('RESET_GET_USERS_AGENCY_STATE');

export const resetAgencyState = createAction('RESET_AGENCY_STATE');
