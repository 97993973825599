import { useEffect } from 'react';

import { usLang, vnLang } from '~/configs/langs';
import { defaultLocale, localeUS } from '~/constants';
import { getCurrentLanguage } from '~/helpers/utils';

export const Helmet = ({
  title, titleEntire, isTranslate = true, children,
}) => {
  const currentLang = getCurrentLanguage();
  let titleTrans = '';
  let titleEntireTrans = '';

  if (isTranslate) {
    if (currentLang === defaultLocale) {
      if (title) {
        titleTrans = vnLang.messages[title];
      } else if (titleEntire) {
        titleEntireTrans = vnLang.messages[titleEntire];
      }
    } else if (currentLang === localeUS) {
      if (title) {
        titleTrans = usLang.messages[title];
      } else if (titleEntire) {
        titleEntireTrans = usLang.messages[titleEntire];
      }
    }
  } else {
    titleTrans = title;
    titleEntireTrans = titleEntire;
  }

  const NAME = process.env.REACT_APP_ENV === 'satek'
    ? 'Satek'
    : process.env.REACT_APP_ENV === 'nextgen'
      ? 'Nextgenes'
      : process.env.REACT_APP_ENV === 'htech'
        ? 'HTech'
        : process.env.REACT_APP_ENV === 'cellphones'
          ? 'Cellphones'
          : process.env.REACT_APP_ENV === 'muathuocgiasi'
            ? 'Mua thuốc giá sĩ'
            : '';

  document.title = titleEntireTrans
    || (titleTrans ? `${titleTrans} | ${NAME}` : `${NAME}`);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <>{children}</>;
};
