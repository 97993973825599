import { useSelector } from 'react-redux';

import './LoadingPage.scss';

export const LoadingPage = () => {
  const {
    getAllConfigState,
  } = useSelector((state) => state.config);

  return (
    <div id="loading">
      <div className="loading-inner">
        {
          getAllConfigState?.site_logo && (
            <img
              src={getAllConfigState?.site_logo}
              alt="Logo"
              style={{ width: '125px', height: 'auto', display: 'block' }}
            />
          )
        }
        <div className="loading-progress" />
      </div>
    </div>
  );
};
