// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllOrdersRequest = createAction('GET_ALL_ORDERS_REQUEST');
export const getAllOrdersSuccess = createAction('GET_ALL_ORDERS_SUCCESS');
export const getAllOrdersFailure = createAction('GET_ALL_ORDERS_FAILURE');

export const createOrderRequest = createAction('CREATE_ORDER_REQUEST');
export const createOrderSuccess = createAction('CREATE_ORDER_SUCCESS');
export const createOrderFailure = createAction('CREATE_ORDER_FAILURE');
export const resetCreateOrderState = createAction('RESET_CREATE_ORDER_STATE');

export const updateOrderRequest = createAction('UPDATE_ORDER_REQUEST');
export const updateOrderSuccess = createAction('UPDATE_ORDER_SUCCESS');
export const updateOrderFailure = createAction('UPDATE_ORDER_FAILURE');
export const resetUpdateOrderState = createAction('RESET_UPDATE_ORDER_STATE');

export const deleteOrderRequest = createAction('DELETE_ORDER_REQUEST');
export const deleteOrderSuccess = createAction('DELETE_ORDER_SUCCESS');
export const deleteOrderFailure = createAction('DELETE_ORDER_FAILURE');
export const resetDeleteOrderState = createAction('RESET_DELETE_ORDER_STATE');

export const resetOrderState = createAction('RESET_ORDER_STATE');
