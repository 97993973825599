// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  //
  isGetAllRolesRequest: false,
  isGetAllRolesSuccess: false,
  isGetAllRolesFailure: false,
  getAllRolesState: {},
  //
  isCreateRoleRequest: false,
  isCreateRoleSuccess: false,
  isCreateRoleFailure: false,
  createRoleResponse: {},
  //
  isDeleteRoleRequest: false,
  isDeleteRoleSuccess: false,
  isDeleteRoleFailure: false,
  //
  isGetAllPermissionsRequest: false,
  isGetAllPermissionsSuccess: false,
  isGetAllPermissionsFailure: false,
  getAllPermissionsState: {},
  //
  isAttachPermissionsToRoleRequest: false,
  isAttachPermissionsToRoleSuccess: false,
  isAttachPermissionsToRoleFailure: false,
  //
  isEditPermissionsToRoleRequest: false,
  isEditPermissionsToRoleSuccess: false,
  isEditPermissionsToRoleFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all roles
    [Actions.getAllRolesRequest]: (state) => ({
      ...state,
      isGetAllRolesRequest: true,
      isGetAllRolesSuccess: false,
      isGetAllRolesFailure: false,
    }),
    [Actions.getAllRolesSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllRolesRequest: false,
      isGetAllRolesSuccess: true,
      isGetAllRolesFailure: false,
      getAllRolesState: payload,
    }),
    [Actions.getAllRolesFailure]: (state, { payload }) => ({
      ...state,
      isGetAllRolesRequest: false,
      isGetAllRolesSuccess: false,
      isGetAllRolesFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create role
    [Actions.createRoleRequest]: (state) => ({
      ...state,
      isCreateRoleRequest: true,
      isCreateRoleSuccess: false,
      isCreateRoleFailure: false,
    }),
    [Actions.createRoleSuccess]: (state, { payload }) => ({
      ...state,
      isCreateRoleRequest: false,
      isCreateRoleSuccess: true,
      isCreateRoleFailure: false,
      createRoleResponse: payload,
      getAllRolesState:
        state.getAllRolesState?.meta?.pagination?.current_page === 1
          ? {
            ...state.getAllRolesState,
            data:
              state.getAllRolesState.meta.pagination.count
                === state.getAllRolesState.meta.pagination.per_page
                ? [payload.data].concat(
                  state.getAllRolesState.data.slice(
                    0,
                    state.getAllRolesState.data.length - 1,
                  ),
                )
                : [payload.data].concat(state.getAllRolesState.data),
            meta: {
              ...state.getAllRolesState.meta,
              pagination: {
                ...state.getAllRolesState.meta.pagination,
                count:
                  state.getAllRolesState.meta.pagination.count
                    === state.getAllRolesState.meta.pagination.per_page
                    ? state.getAllRolesState.meta.pagination.per_page
                    : state.getAllRolesState.meta.pagination.count + 1,
                total: state.getAllRolesState.meta.pagination.total + 1,
                total_pages: Math.ceil(
                  (state.getAllRolesState.meta.pagination.total + 1)
                  / state.getAllRolesState.meta.pagination.per_page,
                ),
              },
            },
          }
          : { ...state.getAllRolesState },
    }),
    [Actions.createRoleFailure]: (state, { payload }) => ({
      ...state,
      isCreateRoleRequest: false,
      isCreateRoleSuccess: false,
      isCreateRoleFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateRoleState]: (state) => ({
      ...state,
      isCreateRoleRequest: false,
      isCreateRoleSuccess: false,
      isCreateRoleFailure: false,
      createRoleResponse: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete role
    [Actions.deleteRoleRequest]: (state) => ({
      ...state,
      isDeleteRoleRequest: true,
      isDeleteRoleSuccess: false,
      isDeleteRoleFailure: false,
    }),
    [Actions.deleteRoleSuccess]: (state) => ({
      ...state,
      isDeleteRoleRequest: false,
      isDeleteRoleSuccess: true,
      isDeleteRoleFailure: false,
    }),
    [Actions.deleteRoleFailure]: (state, { payload }) => ({
      ...state,
      isDeleteRoleRequest: false,
      isDeleteRoleSuccess: false,
      isDeleteRoleFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteRoleState]: (state) => ({
      ...state,
      isDeleteRoleRequest: false,
      isDeleteRoleSuccess: false,
      isDeleteRoleFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Get all roles
    [Actions.getAllPermissionsRequest]: (state) => ({
      ...state,
      isGetAllPermissionsRequest: true,
      isGetAllPermissionsSuccess: false,
      isGetAllPermissionsFailure: false,
    }),
    [Actions.getAllPermissionsSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllPermissionsRequest: false,
      isGetAllPermissionsSuccess: true,
      isGetAllPermissionsFailure: false,
      getAllPermissionsState: payload,
    }),
    [Actions.getAllPermissionsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllPermissionsRequest: false,
      isGetAllPermissionsSuccess: false,
      isGetAllPermissionsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Attach permissions to role
    [Actions.attachPermissionsToRoleRequest]: (state) => ({
      ...state,
      isAttachPermissionsToRoleRequest: true,
      isAttachPermissionsToRoleSuccess: false,
      isAttachPermissionsToRoleFailure: false,
    }),
    [Actions.attachPermissionsToRoleSuccess]: (state, { payload }) => ({
      ...state,
      isAttachPermissionsToRoleRequest: false,
      isAttachPermissionsToRoleSuccess: true,
      isAttachPermissionsToRoleFailure: false,
      getAllRolesState: {
        ...state.getAllRolesState,
        data: state.getAllRolesState.data.map((item) => item.id === payload.data.id ? payload.data : item),
      },
    }),
    [Actions.attachPermissionsToRoleFailure]: (state, { payload }) => ({
      ...state,
      isAttachPermissionsToRoleRequest: false,
      isAttachPermissionsToRoleSuccess: false,
      isAttachPermissionsToRoleFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetAttachPermissionsToRoleState]: (state) => ({
      ...state,
      isAttachPermissionsToRoleRequest: false,
      isAttachPermissionsToRoleSuccess: false,
      isAttachPermissionsToRoleFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Edit permissions to role
    [Actions.editPermissionsToRoleRequest]: (state) => ({
      ...state,
      isEditPermissionsToRoleRequest: true,
      isEditPermissionsToRoleSuccess: false,
      isEditPermissionsToRoleFailure: false,
    }),
    [Actions.editPermissionsToRoleSuccess]: (state, { payload }) => ({
      ...state,
      isEditPermissionsToRoleRequest: false,
      isEditPermissionsToRoleSuccess: true,
      isEditPermissionsToRoleFailure: false,
      getAllRolesState: {
        ...state.getAllRolesState,
        data: state.getAllRolesState.data.map((item) => item.id === payload.data.id ? payload.data : item),
      },
    }),
    [Actions.editPermissionsToRoleFailure]: (state, { payload }) => ({
      ...state,
      isEditPermissionsToRoleRequest: false,
      isEditPermissionsToRoleSuccess: false,
      isEditPermissionsToRoleFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetEditPermissionsToRoleState]: (state) => ({
      ...state,
      isEditPermissionsToRoleRequest: false,
      isEditPermissionsToRoleSuccess: false,
      isEditPermissionsToRoleFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetRoleState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
