/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllSeos({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/seos?${payload.params}` : '/seos?limit=0',
    ));
    yield put(Actions.getAllSeosSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllSeosFailure(messages));
    }
  }
}

function* createSeo({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/seos', payload));
    yield put(Actions.createSeoSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createSeoFailure(messages));
    }
  }
}

function* updateSeo({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/seos/${payload.id}`, payload.body));
    yield put(Actions.updateSeoSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateSeoFailure(messages));
    }
  }
}

function* deleteSeo({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/seos/${payload}`));
    yield put(Actions.deleteSeoSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteSeoFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllSeosRequest, getAllSeos);
  yield takeLatest(Actions.createSeoRequest, createSeo);
  yield takeLatest(Actions.updateSeoRequest, updateSeo);
  yield takeLatest(Actions.deleteSeoRequest, deleteSeo);
}
