// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isShowModalMediaLibrary: false,
  isSelectMultiple: false,
  selectedMedia: [],
  selectedItemProps: [],
  type: '',
  isCropImage: false,
  isShowModalCropImage: false,
  imageCropItem: null,
};

const reducer = handleActions(
  {
    //
    [Actions.handleOpenModalMediaLibrary]: (state, { payload }) => ({
      ...state,
      isShowModalMediaLibrary: true,
      type: payload?.type || '',
      isCropImage: payload?.isCropImage || false,
    }),
    [Actions.handleOpenModalMediaLibraryMultiple]: (state, { payload }) => ({
      ...state,
      isShowModalMediaLibrary: true,
      isSelectMultiple: true,
      type: payload || '',
    }),
    [Actions.handleOpenModalMediaLibraryWithSelectedItem]: (
      state,
      { payload },
    ) => ({
      ...state,
      isShowModalMediaLibrary: true,
      type: payload?.type || '',
      selectedItemProps: payload?.item || [],
      isSelectMultiple: payload?.isMultiple || false,
    }),
    [Actions.handleCloseModalMediaLibrary]: (state) => ({
      ...state,
      isShowModalMediaLibrary: false,
      isSelectMultiple: false,
      isCropImage: false,
    }),
    [Actions.handleSelectedMedia]: (state, { payload }) => ({
      ...state,
      selectedMedia: payload,
    }),
    [Actions.handleOpenModalCropImage]: (state, { payload }) => ({
      ...state,
      isShowModalCropImage: true,
      imageCropItem: payload,
    }),
    [Actions.handleCloseModalCropImage]: (state) => ({
      ...state,
      isShowModalCropImage: false,
      imageCropItem: null,
    }),
    [Actions.resetSelectedMedia]: (state) => ({
      ...initialState,
      isShowModalCropImage: state.isShowModalCropImage,
      imageCropItem: state.imageCropItem,
    }),
  },
  initialState,
);

export default reducer;
