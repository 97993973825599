/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllArticle({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/articles?${payload.params}` : '/articles?limit=0',
    ));
    yield put(Actions.getAllArticleSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllArticleFailure(messages));
    }
  }
}

function* createArticle({ payload }) {
  try {
    if (payload.image) {
      const imgData = new FormData();
      imgData.append('module', 'Article');
      imgData.append('type', 'file');
      imgData.append('files[]', payload.image);
      const imgUrl = yield call(() => axiosMicro.post('/files/upload', imgData));
      payload.image = imgUrl?.data?.data[0];
    }
    const response = yield call(() => axiosMicro.post('/articles', payload));
    yield put(Actions.createArticleSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createArticleFailure(messages));
    }
  }
}

function* updateArticle({ payload }) {
  try {
    if (payload.data.image && typeof payload.data.image !== 'string') {
      const imgData = new FormData();
      imgData.append('module', 'Article');
      imgData.append('type', 'file');
      imgData.append('files[]', payload.data.image);
      const imgUrl = yield call(() => axiosMicro.post('/files/upload', imgData));
      payload.data.image = imgUrl?.data?.data[0];
    }
    const response = yield call(() => axiosMicro.patch(`/articles/${payload.id}`, payload.data));
    yield put(Actions.updateArticleSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateArticleFailure(messages));
    }
  }
}

function* deleteArticle({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/articles/${payload}`));
    yield put(Actions.deleteArticleSuccess(payload));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteArticleFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllArticleRequest, getAllArticle);
  yield takeLatest(Actions.createArticleRequest, createArticle);
  yield takeLatest(Actions.updateArticleRequest, updateArticle);
  yield takeLatest(Actions.deleteArticleRequest, deleteArticle);
}
