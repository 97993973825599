// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllWidgetRequest = createAction('GET_ALL_WIDGET_REQUEST');
export const getAllWidgetSuccess = createAction('GET_ALL_WIDGET_SUCCESS');
export const getAllWidgetFailure = createAction('GET_ALL_WIDGET_FAILURE');

export const createWidgetRequest = createAction('CREATE_WIDGET_REQUEST');
export const createWidgetSuccess = createAction('CREATE_WIDGET_SUCCESS');
export const createWidgetFailure = createAction('CREATE_WIDGET_FAILURE');
export const resetCreateWidgetState = createAction('RESET_CREATE_WIDGET_STATE');

export const updateWidgetRequest = createAction('UPDATE_WIDGET_REQUEST');
export const updateWidgetSuccess = createAction('UPDATE_WIDGET_SUCCESS');
export const updateWidgetFailure = createAction('UPDATE_WIDGET_FAILURE');
export const resetUpdateWidgetState = createAction('RESET_UPDATE_WIDGET_STATE');

export const deleteWidgetRequest = createAction('DELETE_WIDGET_REQUEST');
export const deleteWidgetSuccess = createAction('DELETE_WIDGET_SUCCESS');
export const deleteWidgetFailure = createAction('DELETE_WIDGET_FAILURE');
export const resetDeleteWidgetState = createAction('RESET_DELETE_WIDGET_STATE');

export const resetWidgetState = createAction('RESET_WIDGET_STATE');
