import usAdminDashboardLangLib from './dashboard';
import usAdminPostsLangLib from './posts';
import usAdminMediaLangLib from './media';
import usAdminSettingsLangLib from './settings';
import usAdminAppearanceLangLib from './appearance';
import usAdminPostTypeLangLib from './postType';
import usAdminFeedbackLangLib from './feedback';
import usAdminSEOLangLib from './seo';
import usAdminUserLangLib from './user';
import usAdminWarehouseLangLib from './warehouse';
import usAdminWidgetLangLib from './widget';
import usAdminCommentLangLib from './comment';
import usAdminRatingLangLib from './rating';
import usAdminAgencyLangLib from './agency';
import usAdminProductLangLib from './product';
import usAdminConfigLangLib from './config';

const usAdminLangLib = {
  ...usAdminDashboardLangLib,
  ...usAdminPostsLangLib,
  ...usAdminMediaLangLib,
  ...usAdminSettingsLangLib,
  ...usAdminAppearanceLangLib,
  ...usAdminPostTypeLangLib,
  ...usAdminFeedbackLangLib,
  ...usAdminSEOLangLib,
  ...usAdminUserLangLib,
  ...usAdminWarehouseLangLib,
  ...usAdminWidgetLangLib,
  ...usAdminCommentLangLib,
  ...usAdminRatingLangLib,
  ...usAdminAgencyLangLib,
  ...usAdminProductLangLib,
  ...usAdminConfigLangLib,
};

export default usAdminLangLib;
