// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isUploadFileRequest: false,
  isUploadFileSuccess: false,
  isUploadFileFailure: false,
  uploadFileState: {},
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    [Actions.uploadFileRequest]: (state) => ({
      ...state,
      isUploadFileRequest: true,
      isUploadFileSuccess: false,
      isUploadFileFailure: false,
    }),
    [Actions.uploadFileSuccess]: (state, { payload }) => ({
      ...state,
      isUploadFileRequest: false,
      isUploadFileSuccess: true,
      isUploadFileFailure: false,
      uploadFileState: payload,
    }),
    [Actions.uploadFileFailure]: (state, { payload }) => ({
      ...state,
      isUploadFileRequest: false,
      isUploadFileSuccess: false,
      isUploadFileFailure: true,
      errorMessages: payload,
    }),

    // #region
    [Actions.resetUploadFileState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
