const rating = {
  'admin.rating.hetmet': 'Rating',
  'admin.rating.breadcrumb.heading': 'Ratings List',
  'admin.rating.breadcrumb.parent': 'Rating',
  'admin.rating.noti.create-rating.success': 'Create rating successfully!',
  'admin.rating.noti.create-rating.failure': 'Create rating unsuccessfully!.',
  'admin.rating.noti.delete-rating.success': 'Delete rating successfully!',
  'admin.rating.noti.delete-rating.failure': 'Delete rating unsuccessfully!.',
  'admin.rating.column.created_at': 'Posted at',
  'admin.rating.column.content': 'Nội dung',
  'admin.rating.column.score': 'Score',
  'admin.rating.column.rating-for': 'Rating to',
  'admin.rating.column.author': 'Assessor',
  'admin.rating.label.client': 'Unregistered users',
  'admin.rating.modal.delete.title': 'Confirm delete rating!',
  'admin.rating.modal.delete.description': 'You definitely want to delete this review?',
};

const usAdminRatingLangLib = {
  ...rating,
};

export default usAdminRatingLangLib;
