/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllTypes({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/types?${payload.params}` : '/types?limit=0',
    ));
    yield put(Actions.getAllTypesSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllTypesFailure(messages));
    }
  }
}

function* createType({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/types', payload));
    yield put(Actions.createTypeSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createTypeFailure(messages));
    }
  }
}

function* updateType({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/types/${payload.id}`, payload.body));
    yield put(Actions.updateTypeSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateTypeFailure(messages));
    }
  }
}

function* deleteType({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/types/${payload}`));
    yield put(Actions.deleteTypeSuccess(payload));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteTypeFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllTypesRequest, getAllTypes);
  yield takeLatest(Actions.createTypeRequest, createType);
  yield takeLatest(Actions.updateTypeRequest, updateType);
  yield takeLatest(Actions.deleteTypeRequest, deleteType);
}
