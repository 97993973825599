const payment = {
  'admin.payment.hetmet': 'Tất cả thông tin thanh toán',
  'admin.payment.default': 'Thông tin thanh toán mặc định',
  'admin.payment.breadcrumb.heading': 'Quản lý thông tin thanh toán',
  'admin.payment.breadcrumb.parent': 'Thông tin thanh toán',
  'admin.payment.btn.add-new': 'Thêm thông tin thanh toán mới',
  'admin.payment.noti.create-payment.title.success': 'Thêm thông tin thanh toán mới thành công!',
  'admin.payment.noti.create-payment.title.failure': 'Thêm thông tin thanh toán mới không thành công! Vui lòng thử lại sau.',
  'admin.payment.noti.update-payment.title.success': 'Cập nhật thông tin thanh toán thành công!',
  'admin.payment.noti.update-payment.title.failure': 'Cập nhật thông tin thanh toán không thành công! Vui lòng thử lại sau.',
  'admin.payment.noti.delete-payment.title.success': 'Xóa thông tin thanh toán thành công!',
  'admin.payment.noti.delete-payment.title.failure': 'Xóa thông tin thanh toán không thành công! Vui lòng thử lại sau.',
  'admin.payment.modal.add.title': 'Thêm thông tin thanh toán mới',
  'admin.payment.modal.update.title': 'Cập nhật thông tin thông tin thanh toán',
  'admin.payment.modal.detail.title': 'Thông tin chi tiết thông tin thanh toán',
  'admin.payment.modal.delete.title': 'Xác nhận xóa thông tin thanh toán!',
  'admin.payment.modal.delete.sub-title': 'Bạn chắc chắn muốn xóa thông tin thanh toán này?',
};

const vnAdminPaymentLangLib = {
  ...payment,
};

export default vnAdminPaymentLangLib;
