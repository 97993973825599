const postType = {
  'admin.post-type.hetmet': 'All types of posts',
  'admin.post-type.col-left.title': 'Add new post type',
  'admin.post-type.noti.create-post-type.title.success': 'Add a successful post type!',
  'admin.post-type.noti.create-post-type.title.failure': 'Add unsuccessful post type! Please try again later.',
  'admin.post-type.noti.update-post-type.title.success': 'Update the type of successful post!',
  'admin.post-type.noti.update-post-type.title.failure': 'Update the type of post unsuccessful!Please try again later.',
  'admin.post-type.noti.delete-post-type.title.success': 'Delete a successful post type!',
  'admin.post-type.noti.delete-post-type.title.failure': 'Delete the type of post unsuccessful!Please try again later.',
  'admin.post-type.col-left.label.style': 'Style',
  'admin.post-type.col-left.label.active': 'Active sitemap',
  'admin.post-type.col-left.label.sort': 'Sort',
  'admin.post-type.col-left.label.title': 'Title',
  'admin.post-type.col-left.label.config': 'Features',
  'admin.post-type.col-left.placeholder.config.name': 'Function name change',
  'admin.post-type.col-left.error-msg.config.min': 'Choose at least 1 function!',
  'admin.post-type.col-left.label.slug': 'Slug',
  'admin.post-type.col-left.label.image': 'Image',
  'admin.post-type.col-left.label.description': 'Describe',
  'admin.post-type.col-left.description.slug': '"Slug" is the friendly path of the name.It usually only includes normal writing characters, numbers and dashes, not using Vietnamese.',
  'admin.post-type.col-left.btn.upload-image': 'Upload Image',
  'admin.post-type.col-left.btn.remove-image': 'Remove Image',
  'admin.post-type.modal.update.title': 'Update the type of post',
  'admin.post-type.modal.details.title': 'Details of the post type',
  'admin.post-type.modal.delete.title': 'Confirm delete post type!',
  'admin.post-type.modal.delete.description': 'You definitely want to delete this type of post?',
  'admin.post-type.style-list.article': 'Article',
  'admin.post-type.style-list.product': 'Product',
  'admin.post-type.style-list.course': 'Course',
  'admin.post-type.section-reload-page.title': 'Click the load to the content of the side bar.',
  'admin.post-type.section-reload-page.title-loading': 'Automatically reload the page after 2 seconds.',
};

const usAdminPostTypeLangLib = {
  ...postType,
};

export default usAdminPostTypeLangLib;
