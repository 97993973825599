const postType = {
  'admin.post-type.hetmet': 'Tất cả loại bài đăng',
  'admin.post-type.col-left.title': 'Thêm loại bài đăng mới',
  'admin.post-type.noti.create-post-type.title.success': 'Thêm loại bài đăng thành công!',
  'admin.post-type.noti.create-post-type.title.failure': 'Thêm loại bài đăng không thành công! Vui lòng thử lại sau.',
  'admin.post-type.noti.update-post-type.title.success': 'Cập nhật loại bài đăng thành công!',
  'admin.post-type.noti.update-post-type.title.failure': 'Cập nhật loại bài đăng không thành công! Vui lòng thử lại sau.',
  'admin.post-type.noti.delete-post-type.title.success': 'Xóa loại bài đăng thành công!',
  'admin.post-type.noti.delete-post-type.title.failure': 'Xóa loại bài đăng không thành công! Vui lòng thử lại sau.',
  'admin.post-type.col-left.label.style': 'Loại',
  'admin.post-type.col-left.label.sort': 'Thứ tự',
  'admin.post-type.col-left.label.title': 'Tiêu đề',
  'admin.post-type.col-left.label.active': 'Kích hoạt sitemap',
  'admin.post-type.col-left.label.config': 'Chức năng',
  'admin.post-type.col-left.placeholder.config.name': 'Đổi tên chức năng',
  'admin.post-type.col-left.error-msg.config.min': 'Chọn ít nhất 1 chức năng!',
  'admin.post-type.col-left.label.slug': 'Đường dẫn',
  'admin.post-type.col-left.label.image': 'Hình ảnh',
  'admin.post-type.col-left.label.description': 'Mô tả',
  'admin.post-type.col-left.description.slug': '“slug” là đường dẫn thân thiện của tên. Nó thường chỉ bao gồm kí tự viết thường, số và dấu gạch ngang, không dùng tiếng Việt.',
  'admin.post-type.col-left.btn.upload-image': 'Tải ảnh',
  'admin.post-type.col-left.btn.remove-image': 'Xóa ảnh',
  'admin.post-type.modal.update.title': 'Cập nhật loại bài đăng',
  'admin.post-type.modal.details.title': 'Chi tiết loại bài đăng',
  'admin.post-type.modal.delete.title': 'Xác nhận xóa loại bài đăng!',
  'admin.post-type.modal.delete.description': 'Bạn chắc chắn muốn xóa loại bài đăng này?',
  'admin.post-type.style-list.article': 'Bài viết',
  'admin.post-type.style-list.product': 'Sản phẩm',
  'admin.post-type.style-list.course': 'Khóa học',
  'admin.post-type.section-reload-page.title': 'Bấm vào tải để tại nội dung thanh bên.',
  'admin.post-type.section-reload-page.title-loading': 'Tự động tải lại trang sau 2 giây.',
};

const vnAdminPostTypeLangLib = {
  ...postType,
};

export default vnAdminPostTypeLangLib;
