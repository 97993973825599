const product = {
  'admin.product.hetmet': 'All products',
  'admin.product.breadcrumb.heading': 'Manage products',
  'admin.product.breadcrumb.parent': 'Products',
  'admin.product.btn.add-new': 'Add new product',
  'admin.product.noti.create-product.title.success': 'Add new products successful!',
  'admin.product.noti.create-product.title.failure': 'Adding new products failed! Please try again later.',
  'admin.product.noti.update-product.title.success': 'Successful products update!',
  'admin.product.noti.update-product.title.failure': 'Membership updates are unsuccessful! Please try again later.',
  'admin.product.noti.delete-product.title.success': 'Delete products successfully!',
  'admin.product.noti.delete-product.title.failure': 'Delete products unsuccessful! Please try again later.',
  'admin.product.modal.add.title': 'Add new product',
  'admin.product.modal.update.title': 'Update product information',
  'admin.product.modal.detail.title': 'Product details',
  'admin.product.modal.delete.title': 'Confirm to delete product!',
  'admin.product.modal.delete.sub-title': 'You definitely want to delete this product?',
  'admin.product.modal.reset-password.title': 'Confirm reset password for this product!',
};

const category = {
  'admin.category.hetmet': 'All categories',
  'admin.category.breadcrumb.heading': 'Manage categories',
  'admin.category.breadcrumb.parent': 'Categories',
  'admin.category.btn.add-new': 'Add new category',
  'admin.category.noti.create-category.title.success': 'Add new categories successful!',
  'admin.category.noti.create-category.title.failure': 'Adding new categories failed! Please try again later.',
  'admin.category.noti.update-category.title.success': 'Successful categories update!',
  'admin.category.noti.update-category.title.failure': 'Category updates are unsuccessful! Please try again later.',
  'admin.category.noti.delete-category.title.success': 'Delete categories successfully!',
  'admin.category.noti.delete-category.title.failure': 'Delete categories unsuccessful! Please try again later.',
  'admin.category.modal.add.title': 'Add new category',
  'admin.category.modal.update.title': 'Update category information',
  'admin.category.modal.detail.title': 'Category details',
  'admin.category.modal.delete.title': 'Confirm to delete category!',
  'admin.category.modal.delete.sub-title': 'You definitely want to delete this category?',
  'admin.category.modal.reset-password.title': 'Confirm reset password for this category!',
};

const usAdminProductLangLib = {
  ...product,
  ...category,
};

export default usAdminProductLangLib;
