// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllUsersHasRoleRequest = createAction('GET_ALL_USERS_HAS_ROLE_REQUEST');
export const getAllUsersHasRoleSuccess = createAction('GET_ALL_USERS_HAS_ROLE_SUCCESS');
export const getAllUsersHasRoleFailure = createAction('GET_ALL_USERS_HAS_ROLE_FAILURE');

export const getAllUsersNoRoleRequest = createAction('GET_ALL_USERS_NO_ROLE_REQUEST');
export const getAllUsersNoRoleSuccess = createAction('GET_ALL_USERS_NO_ROLE_SUCCESS');
export const getAllUsersNoRoleFailure = createAction('GET_ALL_USERS_NO_ROLE_FAILURE');
export const resetGetAllUsersNoRoleState = createAction('RESET_GET_ALL_USERS_NO_ROLE_STATE');

export const syncUserRolesRequest = createAction('SYNC_USER_ROLES_REQUEST');
export const syncUserRolesSuccess = createAction('SYNC_USER_ROLES_SUCCESS');
export const syncUserRolesFailure = createAction('SYNC_USER_ROLES_FAILURE');
export const resetSyncUserRolesState = createAction('RESET_SYNC_USER_ROLES_STATE');

export const revokeRolesFromUserRequest = createAction('REVOKE_ROLES_FROM_USER_REQUEST');
export const revokeRolesFromUserSuccess = createAction('REVOKE_ROLES_FROM_USER_SUCCESS');
export const revokeRolesFromUserFailure = createAction('REVOKE_ROLES_FROM_USER_FAILURE');
export const resetRevokeRolesFromUserState = createAction('RESET_REVOKE_ROLES_FROM_USER_STATE');

export const resetPermissionState = createAction('RESET_PERMISSION_STATE');
