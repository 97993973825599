// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllConfigRequest: false,
  isGetAllConfigSuccess: false,
  isGetAllConfigFailure: false,
  getAllConfigState: {},
  //
  isCreateConfigRequest: false,
  isCreateConfigSuccess: false,
  isCreateConfigFailure: false,
  //
  isCreateWatermarkConfigRequest: false,
  isCreateWatermarkConfigSuccess: false,
  isCreateWatermarkConfigFailure: false,
  getAllWatermarkConfigState: {},
  //
  isUpdateConfigRequest: false,
  isUpdateConfigSuccess: false,
  isUpdateConfigFailure: false,
  //
  isDeleteConfigRequest: false,
  isDeleteConfigSuccess: false,
  isDeleteConfigFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all categories
    [Actions.getAllConfigRequest]: (state) => ({
      ...state,
      isGetAllConfigRequest: true,
      isGetAllConfigSuccess: false,
      isGetAllConfigFailure: false,
    }),
    [Actions.getAllConfigSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllConfigRequest: false,
      isGetAllConfigSuccess: true,
      isGetAllConfigFailure: false,
      getAllConfigState: {
        ...payload.data,
      },
    }),
    [Actions.getAllConfigFailure]: (state, { payload }) => ({
      ...state,
      isGetAllConfigRequest: false,
      isGetAllConfigSuccess: false,
      isGetAllConfigFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create config
    [Actions.createConfigRequest]: (state) => ({
      ...state,
      isCreateConfigRequest: true,
      isCreateConfigSuccess: false,
      isCreateConfigFailure: false,
    }),
    [Actions.createConfigSuccess]: (state) => ({
      ...state,
      isCreateConfigRequest: false,
      isCreateConfigSuccess: true,
      isCreateConfigFailure: false,
      // getAllConfigState: {
      //   payload.data,
      //   ...state.getAllConfigState,
      // },
    }),
    [Actions.createConfigFailure]: (state, { payload }) => ({
      ...state,
      isCreateConfigRequest: false,
      isCreateConfigSuccess: false,
      isCreateConfigFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateConfigState]: (state) => ({
      ...state,
      isCreateConfigRequest: false,
      isCreateConfigSuccess: false,
      isCreateConfigFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Create watermark config
    [Actions.createWatermarkConfigRequest]: (state) => ({
      ...state,
      isCreateWatermarkConfigRequest: true,
      isCreateWatermarkConfigSuccess: false,
      isCreateWatermarkConfigFailure: false,
    }),
    [Actions.createWatermarkConfigSuccess]: (state, { payload }) => ({
      ...state,
      isCreateWatermarkConfigRequest: false,
      isCreateWatermarkConfigSuccess: true,
      isCreateWatermarkConfigFailure: false,
      getAllWatermarkConfigState: {
        ...state.getAllWatermarkConfigState,
        data: [
          payload.data,
          ...state.getAllWatermarkConfigState.data,
        ],
      },
    }),
    [Actions.createWatermarkConfigFailure]: (state, { payload }) => ({
      ...state,
      isCreateWatermarkConfigRequest: false,
      isCreateWatermarkConfigSuccess: false,
      isCreateWatermarkConfigFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateWatermarkConfigState]: (state) => ({
      ...state,
      isCreateWatermarkConfigRequest: false,
      isCreateWatermarkConfigSuccess: false,
      isCreateWatermarkConfigFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update watermark config
    [Actions.updateWatermarkConfigRequest]: (state) => ({
      ...state,
      isUpdateConfigRequest: true,
      isUpdateConfigSuccess: false,
      isUpdateConfigFailure: false,
    }),
    // #endregion
    // #region : Update config
    [Actions.updateConfigRequest]: (state) => ({
      ...state,
      isUpdateConfigRequest: true,
      isUpdateConfigSuccess: false,
      isUpdateConfigFailure: false,
    }),
    [Actions.updateConfigSuccess]: (state) => ({
      ...state,
      isUpdateConfigRequest: false,
      isUpdateConfigSuccess: true,
      isUpdateConfigFailure: false,
    }),
    [Actions.updateConfigFailure]: (state, { payload }) => ({
      ...state,
      isUpdateConfigRequest: false,
      isUpdateConfigSuccess: false,
      isUpdateConfigFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateConfigState]: (state) => ({
      ...state,
      isUpdateConfigRequest: false,
      isUpdateConfigSuccess: false,
      isUpdateConfigFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete config
    [Actions.deleteConfigRequest]: (state) => ({
      ...state,
      isDeleteConfigRequest: true,
      isDeleteConfigSuccess: false,
      isDeleteConfigFailure: false,
    }),
    [Actions.deleteConfigSuccess]: (state) => ({
      ...state,
      isDeleteConfigRequest: false,
      isDeleteConfigSuccess: true,
      isDeleteConfigFailure: false,
    }),
    [Actions.deleteConfigFailure]: (state, { payload }) => ({
      ...state,
      isDeleteConfigRequest: false,
      isDeleteConfigSuccess: false,
      isDeleteConfigFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteConfigState]: (state) => ({
      ...state,
      isDeleteConfigRequest: false,
      isDeleteConfigSuccess: false,
      isDeleteConfigFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetConfigState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
