const warehouse = {
  'admin.warehouse.hetmet': 'Quản lý Kho',
  'admin.warehouse.breadcrumb.heading': 'Quản lý Kho',
  'admin.warehouse.breadcrumb.parent': 'Kho',
  'admin.warehouse.btn.add': 'Thêm Kho',
  'admin.warehouse.noti.create-warehouse.success': 'Thêm Kho thành công!',
  'admin.warehouse.noti.create-warehouse.failure': 'Thêm Kho thất bại! Vui lòng thử lại sau.',
  'admin.warehouse.noti.update-warehouse.success': 'Cập nhật Kho thành công!',
  'admin.warehouse.noti.update-warehouse.failure': 'Cập nhật Kho thất bại! Vui lòng thử lại sau.',
  'admin.warehouse.noti.delete-warehouse.success': 'Xóa Kho thành công',
  'admin.warehouse.noti.delete-warehouse.failure': 'Xóa Kho thất bại! Vui lòng thử lại sau.',
  'admin.warehouse.modal.action.title.create': 'Thêm mới',
  'admin.warehouse.modal.action.title.update': 'Cập nhật',
  'admin.warehouse.modal.action.title.details': 'Chi tiết',
  'admin.warehouse.modal.action.btn.create': 'Thêm',
  'admin.warehouse.modal.action.btn.update': 'Cập nhật',
  'admin.warehouse.modal.action.label.name': 'Tên',
  'admin.warehouse.modal.action.label.phone': 'SĐT',
  'admin.warehouse.modal.action.label.email': 'Email',
  'admin.warehouse.modal.action.label.province': 'Tỉnh/Thành Phố',
  'admin.warehouse.modal.action.label.district': 'Quận/Huyện',
  'admin.warehouse.modal.action.label.ward': 'Phường/Xã',
  'admin.warehouse.modal.action.label.address': 'Địa chỉ',
  'admin.warehouse.modal.delete.title': 'Xác nhận xóa Kho!',
  'admin.warehouse.modal.delete.description': 'Tất cả dữ liệu được liên kết với kho này sẽ bị xóa vĩnh viễn, bạn chắc chắn ?',
  'admin.warehouse.modal.views.title.products': 'Danh sách sản phẩm',
  'admin.warehouse.modal.views.title.product-history': 'Lịch sử sản phẩm',
  'admin.warehouse.column.name': 'Tên',
  'admin.warehouse.column.phone': 'SĐT',
  'admin.warehouse.column.email': 'Email',
  'admin.warehouse.column.province': 'Tỉnh/Thành Phố',
  'admin.warehouse.column.district': 'Quận/Huyện',
  'admin.warehouse.column.ward': 'Phường/Xã',
  'admin.warehouse.column.address': 'Địa chỉ',
  'admin.warehouse.product.column.code': 'Mã sản phẩm',
  'admin.warehouse.product.column.name': 'Tên sản phẩm',
  'admin.warehouse.product.column.price': 'Giá',
  'admin.warehouse.product.column.number': 'Số lượng',
  'admin.warehouse.product.column.in-stock': 'Tồn kho',
  'admin.warehouse.product.column.export': 'Xuất kho',
  'admin.warehouse.product.column.expiry': 'Hạn sử dụng',
  'admin.warehouse.product.column.expired': 'Hết hạn',
  'admin.warehouse.product.column.type': 'Loại',
  'admin.warehouse.product.column.time': 'Thời gian',
  'admin.warehouse.product.column.revenue': 'Doanh thu',
  'admin.warehouse.product.column.profit': 'Lợi nhuận',
  'admin.warehouse.product.column.status': 'Trạng thái',
  'admin.warehouse.product.column.status-1': 'Hoành thành',
  'admin.warehouse.product.column.status-2': 'Đã hủy',
  'admin.warehouse.type.item.website': 'Website',
  'admin.warehouse.type.item.article': 'Bài viết',
  'admin.warehouse.type.item.profile': 'Profile',
  'admin.warehouse.type.item.book': 'Sách',
  'admin.warehouse.error-msg.name': 'Vui lòng điền trường này!',
  'admin.warehouse.error-msg.phone': 'Số điện thoại không đúng định dạng!',
  'admin.warehouse.error-msg.email': 'Email không đúng định dạng!',
  'admin.warehouse.error-msg.province': 'Vui lòng điền trường này!',
  'admin.warehouse.error-msg.district': 'Vui lòng điền trường này!',
  'admin.warehouse.error-msg.ward': 'Vui lòng điền trường này!',
  'admin.warehouse.error-msg.address': 'Vui lòng điền trường này!',
};

const warehouseChange = {
  'admin.warehouse-change.hetmet': 'Quản lý Xuất Nhập Kho',
  'admin.warehouse-change.breadcrumb.heading': 'Quản lý Xuất Nhập Kho',
  'admin.warehouse-change.breadcrumb.parent': 'Xuất Nhập Kho',
  'admin.warehouse-change.btn.add': 'Tạo phiếu Xuất Nhập Kho',
  'admin.warehouse-change.noti.create-warehouse.success': 'Thêm phiếu Xuất Nhập Kho thành công!',
  'admin.warehouse-change.noti.create-warehouse.failure': 'Thêm phiếu Xuất Nhập Kho thất bại! Vui lòng thử lại sau.',
  'admin.warehouse-change.noti.update-warehouse.success': 'Cập nhật phiếu Xuất Nhập Kho thành công!',
  'admin.warehouse-change.noti.update-warehouse.failure': 'Cập nhật phiếu Xuất Nhập Kho thất bại! Vui lòng thử lại sau.',
  'admin.warehouse-change.noti.cancel-warehouse.success': 'Hủy phiếu Xuất Nhập Kho thành công',
  'admin.warehouse-change.noti.cancel-warehouse.failure': 'Hủy phiếu Xuất Nhập Kho thất bại! Vui lòng thử lại sau.',
  'admin.warehouse-change.modal.action.title.create': 'Thêm mới',
  'admin.warehouse-change.modal.action.title.update': 'Cập nhật',
  'admin.warehouse-change.modal.action.title.details': 'Chi tiết',
  'admin.warehouse-change.modal.action.btn.create': 'Thêm',
  'admin.warehouse-change.modal.action.btn.update': 'Cập nhật',
  'admin.warehouse-change.modal.action.label.name': 'Tên',
  'admin.warehouse-change.modal.action.label.code': 'Mã xuất nhập',
  'admin.warehouse-change.modal.action.label.style': 'Loại',
  'admin.warehouse-change.modal.action.label.date': 'Ngày',
  'admin.warehouse-change.modal.action.label.number': 'Số lượng',
  'admin.warehouse-change.modal.action.label.money': 'Tổng tiền',
  'admin.warehouse-change.modal.action.label.supplier': 'Nhà cung cấp',
  'admin.warehouse-change.modal.action.label.warehouse': 'Kho',
  'admin.warehouse-change.modal.action.label.warehouse-search': 'Tìm kiếm Kho',
  'admin.warehouse-change.modal.action.label.user': 'Nhân viên',
  'admin.warehouse-change.modal.action.label.order': 'Đơn hàng',
  'admin.warehouse-change.modal.action.label.order-checked': 'Sử dụng đơn hàng',
  'admin.warehouse-change.modal.action.label.order-search': 'Tìm kiếm đơn hàng',
  'admin.warehouse-change.modal.action.label.search-result': 'Kết quả tìm kiếm',
  'admin.warehouse-change.modal.action.label.search-selected': 'Đã chọn',
  'admin.warehouse-change.modal.action.label.status': 'Trạng thái',
  'admin.warehouse-change.modal.action.label.status-1': 'Hoàn thành',
  'admin.warehouse-change.modal.action.label.status-2': 'Đã hủy',
  'admin.warehouse-change.modal.action.label.note': 'Ghi chú',
  'admin.warehouse-change.modal.action.label.profit': 'Lợi nhuận',
  'admin.warehouse-change.modal.action.label.products': 'Danh sách sản phẩm',
  'admin.warehouse-change.modal.action.label.product-code': 'Mã sản phẩm',
  'admin.warehouse-change.modal.action.label.product-price': 'Giá',
  'admin.warehouse-change.modal.action.label.product-number': 'Số lượng',
  'admin.warehouse-change.modal.action.label.product-expiry': 'Hạn sử dụng',
  'admin.warehouse-change.modal.action.label.products-search': 'Tìm kiếm sản phẩm',
  'admin.warehouse-change.modal.action.label.order-code': 'Mã đơn hàng',
  'admin.warehouse-change.modal.column.code': 'Mã sản phẩm',
  'admin.warehouse-change.modal.column.name': 'Tên sản phẩm',
  'admin.warehouse-change.modal.column.number': 'Số lượng',
  'admin.warehouse-change.modal.column.price': 'Giá',
  'admin.warehouse-change.modal.column.money': 'Tổng tiền',
  'admin.warehouse-change.modal.column.in-stock': 'Tồn kho',
  'admin.warehouse-change.modal.column.export': 'Xuất kho',
  'admin.warehouse-change.modal.column.expiry': 'Hạn sử dụng',
  'admin.warehouse-change.modal.column.expiried': 'Hết hạn',
  'admin.warehouse-change.modal.cancel.title': 'Xác nhận hủy!',
  'admin.warehouse-change.modal.cancel.description': 'Bạn có chắc chắn muốn hủy phiếu Xuất Nhập Kho này?',
  'admin.warehouse-change.modal.views.title.products': 'Danh sách sản phẩm',
  'admin.warehouse-change.column.code': 'Mã xuất nhập',
  'admin.warehouse-change.column.style': 'Loại',
  'admin.warehouse-change.column.date': 'Ngày',
  'admin.warehouse-change.column.number': 'Số lượng',
  'admin.warehouse-change.column.money': 'Tổng tiền',
  'admin.warehouse-change.column.supplier': 'Nhà cung cấp',
  'admin.warehouse-change.column.warehouse': 'Kho',
  'admin.warehouse-change.column.user': 'Nhân viên',
  'admin.warehouse-change.column.order': 'Đơn hàng',
  'admin.warehouse-change.column.status': 'Trạng thái',
  'admin.warehouse-change.column.status-1': 'Hoàn thành',
  'admin.warehouse-change.column.status-2': 'Đã hủy',
  'admin.warehouse-change.column.note': 'Ghi chú',
  'admin.warehouse-change.product.column.code': 'Mã sản phẩm',
  'admin.warehouse-change.product.column.name': 'Tên sản phẩm',
  'admin.warehouse-change.product.column.price': 'Giá',
  'admin.warehouse-change.product.column.number': 'Số lượng',
  'admin.warehouse-change.type.item.website': 'Website',
  'admin.warehouse-change.type.item.article': 'Bài viết',
  'admin.warehouse-change.type.item.profile': 'Profile',
  'admin.warehouse-change.type.item.book': 'Sách',
  'admin.warehouse-change.error-msg.style': 'Vui lòng điền trường này!',
  'admin.warehouse-change.error-msg.warehouse': 'Vui lòng chọn kho!',
  'admin.warehouse-change.error-msg.products': 'Vui lòng chọn sản phẩm/đơn hàng!',
  'admin.warehouse-change.style-list.import': 'Nhập kho',
  'admin.warehouse-change.style-list.export': 'Xuất kho',
};

const supplier = {
  'admin.supplier.hetmet': 'Quản lý Nhà cung cấp',
  'admin.supplier.breadcrumb.heading': 'Quản lý Nhà cung cấp',
  'admin.supplier.breadcrumb.parent': 'Nhà cung cấp',
  'admin.supplier.btn.add': 'Thêm Nhà cung cấp',
  'admin.supplier.noti.create-supplier.success': 'Thêm Nhà cung cấp thành công!',
  'admin.supplier.noti.create-supplier.failure': 'Thêm Nhà cung cấp thất bại! Vui lòng thử lại sau.',
  'admin.supplier.noti.update-supplier.success': 'Cập nhật Nhà cung cấp thành công!',
  'admin.supplier.noti.update-supplier.failure': 'Cập nhật Nhà cung cấp thất bại! Vui lòng thử lại sau.',
  'admin.supplier.noti.delete-supplier.success': 'Xóa Nhà cung cấp thành công',
  'admin.supplier.noti.delete-supplier.failure': 'Xóa Nhà cung cấp thất bại! Vui lòng thử lại sau.',
  'admin.supplier.modal.action.title.create': 'Thêm mới',
  'admin.supplier.modal.action.title.update': 'Cập nhật',
  'admin.supplier.modal.action.title.details': 'Chi tiết',
  'admin.supplier.modal.action.btn.create': 'Thêm',
  'admin.supplier.modal.action.btn.update': 'Cập nhật',
  'admin.supplier.modal.action.label.name': 'Tên',
  'admin.supplier.modal.action.label.phone': 'SĐT',
  'admin.supplier.modal.action.label.email': 'Email',
  'admin.supplier.modal.action.label.province': 'Tỉnh/Thành Phố',
  'admin.supplier.modal.action.label.district': 'Quận/Huyện',
  'admin.supplier.modal.action.label.ward': 'Phường/Xã',
  'admin.supplier.modal.action.label.address': 'Địa chỉ',
  'admin.supplier.modal.delete.title': 'Xác nhận xóa Nhà cung cấp!',
  'admin.supplier.modal.delete.description': 'Bạn có chắc chắn muốn xóa Nhà cung cấp này?',
  'admin.supplier.modal.views.title.products': 'Danh sách sản phẩm',
  'admin.supplier.modal.views.title.history': 'Lịch sử nhập hàng',
  'admin.supplier.column.name': 'Tên',
  'admin.supplier.column.phone': 'SĐT',
  'admin.supplier.column.email': 'Email',
  'admin.supplier.column.province': 'Tỉnh/Thành Phố',
  'admin.supplier.column.district': 'Quận/Huyện',
  'admin.supplier.column.ward': 'Phường/Xã',
  'admin.supplier.column.address': 'Địa chỉ',
  'admin.supplier.product.column.code': 'Mã sản phẩm',
  'admin.supplier.product.column.name': 'Tên sản phẩm',
  'admin.supplier.product.column.price': 'Giá',
  'admin.supplier.product.column.number': 'Số lượng',
  'admin.supplier.type.item.website': 'Website',
  'admin.supplier.type.item.article': 'Bài viết',
  'admin.supplier.type.item.profile': 'Profile',
  'admin.supplier.type.item.book': 'Sách',
  'admin.supplier.error-msg.name': 'Vui lòng điền trường này!',
  'admin.supplier.error-msg.phone': 'Số điện thoại không đúng định dạng!',
  'admin.supplier.error-msg.email': 'Email không đúng định dạng!',
  'admin.supplier.error-msg.province': 'Vui lòng điền trường này!',
  'admin.supplier.error-msg.district': 'Vui lòng điền trường này!',
  'admin.supplier.error-msg.ward': 'Vui lòng điền trường này!',
  'admin.supplier.error-msg.address': 'Vui lòng điền trường này!',
  'admin.supplier.column.import-code': 'Mã nhập',
};

const vnAdminWarehouseLangLib = {
  ...warehouse,
  ...warehouseChange,
  ...supplier,
};

export default vnAdminWarehouseLangLib;
