import vnAuthLoginLangLib from './auth/login';
import vnAdminLangLib from './admin';
import vnOtherLangLib from './other';

const vnPageLangLib = {
  ...vnAuthLoginLangLib,
  ...vnAdminLangLib,
  ...vnOtherLangLib,
};

export default vnPageLangLib;
