// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllGroupsRequest = createAction('GET_ALL_GROUPS_REQUEST');
export const getAllGroupsSuccess = createAction('GET_ALL_GROUPS_SUCCESS');
export const getAllGroupsFailure = createAction('GET_ALL_GROUPS_FAILURE');

export const createGroupRequest = createAction('CREATE_GROUP_REQUEST');
export const createGroupSuccess = createAction('CREATE_GROUP_SUCCESS');
export const createGroupFailure = createAction('CREATE_GROUP_FAILURE');
export const resetCreateGroupState = createAction('RESET_CREATE_GROUP_STATE');

export const updateGroupRequest = createAction('UPDATE_GROUP_REQUEST');
export const updateGroupSuccess = createAction('UPDATE_GROUP_SUCCESS');
export const updateGroupFailure = createAction('UPDATE_GROUP_FAILURE');
export const resetUpdateGroupState = createAction('RESET_UPDATE_GROUP_STATE');

export const deleteGroupRequest = createAction('DELETE_GROUP_REQUEST');
export const deleteGroupSuccess = createAction('DELETE_GROUP_SUCCESS');
export const deleteGroupFailure = createAction('DELETE_GROUP_FAILURE');
export const resetDeleteGroupState = createAction('RESET_DELETE_GROUP_STATE');

export const resetGroupState = createAction('RESET_GROUP_STATE');
