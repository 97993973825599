// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllMembersRequest = createAction('GET_ALL_MEMBERS_REQUEST');
export const getAllMembersSuccess = createAction('GET_ALL_MEMBERS_SUCCESS');
export const getAllMembersFailure = createAction('GET_ALL_MEMBERS_FAILURE');

export const addMemberRequest = createAction('ADD_MEMBER_REQUEST');
export const addMemberSuccess = createAction('ADD_MEMBER_SUCCESS');
export const addMemberFailure = createAction('ADD_MEMBER_FAILURE');
export const resetAddMemberState = createAction('RESET_ADD_MEMBER_STATE');

export const updateMemberRequest = createAction('UPDATE_MEMBER_REQUEST');
export const updateMemberSuccess = createAction('UPDATE_MEMBER_SUCCESS');
export const updateMemberFailure = createAction('UPDATE_MEMBER_FAILURE');
export const resetUpdateMemberState = createAction('RESET_UPDATE_MEMBER_STATE');

export const deleteMemberRequest = createAction('DELETE_MEMBER_REQUEST');
export const deleteMemberSuccess = createAction('DELETE_MEMBER_SUCCESS');
export const deleteMemberFailure = createAction('DELETE_MEMBER_FAILURE');
export const resetDeleteMemberState = createAction('RESET_DELETE_MEMBER_STATE');

export const getDetailMemberRequest = createAction('GET_DETAIL_MEMBER_REQUEST');
export const getDetailMemberSuccess = createAction('GET_DETAIL_MEMBER_SUCCESS');
export const getDetailMemberFailure = createAction('GET_DETAIL_MEMBER_FAILURE');
export const resetGetDetailMemberState = createAction(
  'RESET_GET_DETAIL_MEMBER_STATE',
);

export const checkEmailExistRequest = createAction('CHECK_EMAIL_EXIST_REQUEST');
export const checkEmailExistSuccess = createAction('CHECK_EMAIL_EXIST_SUCCESS');
export const checkEmailExistFailure = createAction('CHECK_EMAIL_EXIST_FAILURE');
export const resetCheckEmailExistState = createAction(
  'RESET_CHECK_EMAIL_EXIST_STATE',
);

export const resetPasswordMemberRequest = createAction(
  'RESET_PASSWORD_MEMBER_REQUEST',
);
export const resetPasswordMemberSuccess = createAction(
  'RESET_PASSWORD_MEMBER_SUCCESS',
);
export const resetPasswordMemberFailure = createAction(
  'RESET_PASSWORD_MEMBER_FAILURE',
);
export const resetResetPasswordMemberState = createAction(
  'RESET_RESET_PASSWORD_MEMBER_STATE',
);

export const resetMemberState = createAction('RESET_MEMBER_STATE');
