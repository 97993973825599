const article = {
  'admin.article.hetmet': 'Tất cả bài viết',
  'admin.article.default': 'Bài viết mặc định',
  'admin.article.breadcrumb.heading': 'Quản lý bài viết',
  'admin.article.breadcrumb.parent': 'Bài viết',
  'admin.article.btn.add-new': 'Thêm bài viết mới',
  'admin.article.noti.create-article.title.success': 'Thêm bài viết mới thành công!',
  'admin.article.noti.create-article.title.failure': 'Thêm bài viết mới không thành công! Vui lòng thử lại sau.',
  'admin.article.noti.update-article.title.success': 'Cập nhật bài viết thành công!',
  'admin.article.noti.update-article.title.failure': 'Cập nhật bài viết không thành công! Vui lòng thử lại sau.',
  'admin.article.noti.delete-article.title.success': 'Xóa bài viết thành công!',
  'admin.article.noti.delete-article.title.failure': 'Xóa bài viết không thành công! Vui lòng thử lại sau.',
  'admin.article.modal.add.title': 'Thêm bài viết mới',
  'admin.article.modal.update.title': 'Cập nhật thông tin bài viết',
  'admin.article.modal.detail.title': 'Thông tin chi tiết bài viết',
  'admin.article.modal.delete.title': 'Xác nhận xóa bài viết!',
  'admin.article.modal.delete.sub-title': 'Bạn chắc chắn muốn xóa bài viết này?',
};

const vnAdminPaymentLangLib = {
  ...article,
};

export default vnAdminPaymentLangLib;
