// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllTypesRequest = createAction('GET_ALL_TYPES_REQUEST');
export const getAllTypesSuccess = createAction('GET_ALL_TYPES_SUCCESS');
export const getAllTypesFailure = createAction('GET_ALL_TYPES_FAILURE');

export const createTypeRequest = createAction('CREATE_TYPE_REQUEST');
export const createTypeSuccess = createAction('CREATE_TYPE_SUCCESS');
export const createTypeFailure = createAction('CREATE_TYPE_FAILURE');
export const resetCreateTypeState = createAction('RESET_CREATE_TYPE_STATE');

export const updateTypeRequest = createAction('UPDATE_TYPE_REQUEST');
export const updateTypeSuccess = createAction('UPDATE_TYPE_SUCCESS');
export const updateTypeFailure = createAction('UPDATE_TYPE_FAILURE');
export const resetUpdateTypeState = createAction('RESET_UPDATE_TYPE_STATE');

export const deleteTypeRequest = createAction('DELETE_TYPE_REQUEST');
export const deleteTypeSuccess = createAction('DELETE_TYPE_SUCCESS');
export const deleteTypeFailure = createAction('DELETE_TYPE_FAILURE');
export const resetDeleteTypeState = createAction('RESET_DELETE_TYPE_STATE');

export const resetTypeState = createAction('RESET_TYPE_STATE');
