// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllPackageRequest: false,
  isGetAllPackageSuccess: false,
  isGetAllPackageFailure: false,
  getAllPackageState: {},
  //
  isCreatePackageRequest: false,
  isCreatePackageSuccess: false,
  isCreatePackageFailure: false,
  //
  isUpdatePackageRequest: false,
  isUpdatePackageSuccess: false,
  isUpdatePackageFailure: false,
  //
  isDeletePackageRequest: false,
  isDeletePackageSuccess: false,
  isDeletePackageFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all categories
    [Actions.getAllPackageRequest]: (state) => ({
      ...state,
      isGetAllPackageRequest: true,
      isGetAllPackageSuccess: false,
      isGetAllPackageFailure: false,
    }),
    [Actions.getAllPackageSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllPackageRequest: false,
      isGetAllPackageSuccess: true,
      isGetAllPackageFailure: false,
      getAllPackageState: {
        ...payload,
      },
    }),
    [Actions.getAllPackageFailure]: (state, { payload }) => ({
      ...state,
      isGetAllPackageRequest: false,
      isGetAllPackageSuccess: false,
      isGetAllPackageFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create config
    [Actions.createPackageRequest]: (state) => ({
      ...state,
      isCreatePackageRequest: true,
      isCreatePackageSuccess: false,
      isCreatePackageFailure: false,
    }),
    [Actions.createPackageSuccess]: (state, { payload }) => ({
      ...state,
      isCreatePackageRequest: false,
      isCreatePackageSuccess: true,
      isCreatePackageFailure: false,
      getAllPackageState: {
        ...state.getAllPackageState,
        data: [
          ...state.getAllPackageState?.data,
          payload.data,
        ],
      },
    }),
    [Actions.createPackageFailure]: (state, { payload }) => ({
      ...state,
      isCreatePackageRequest: false,
      isCreatePackageSuccess: false,
      isCreatePackageFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreatePackageState]: (state) => ({
      ...state,
      isCreatePackageRequest: false,
      isCreatePackageSuccess: false,
      isCreatePackageFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update config
    [Actions.updatePackageRequest]: (state) => ({
      ...state,
      isUpdatePackageRequest: true,
      isUpdatePackageSuccess: false,
      isUpdatePackageFailure: false,
    }),
    [Actions.updatePackageSuccess]: (state) => ({
      ...state,
      isUpdatePackageRequest: false,
      isUpdatePackageSuccess: true,
      isUpdatePackageFailure: false,
      // getAllPackageState: {
      //   ...state.getAllPackageState,
      //   data: [
      //     payload.data,
      //     ...state.getAllPackageState?.data,
      //   ],
      // },
    }),
    [Actions.updatePackageFailure]: (state, { payload }) => ({
      ...state,
      isUpdatePackageRequest: false,
      isUpdatePackageSuccess: false,
      isUpdatePackageFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdatePackageState]: (state) => ({
      ...state,
      isUpdatePackageRequest: false,
      isUpdatePackageSuccess: false,
      isUpdatePackageFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete config
    [Actions.deletePackageRequest]: (state) => ({
      ...state,
      isDeletePackageRequest: true,
      isDeletePackageSuccess: false,
      isDeletePackageFailure: false,
    }),
    [Actions.deletePackageSuccess]: (state) => ({
      ...state,
      isDeletePackageRequest: false,
      isDeletePackageSuccess: true,
      isDeletePackageFailure: false,
    }),
    [Actions.deletePackageFailure]: (state, { payload }) => ({
      ...state,
      isDeletePackageRequest: false,
      isDeletePackageSuccess: false,
      isDeletePackageFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeletePackageState]: (state) => ({
      ...state,
      isDeletePackageRequest: false,
      isDeletePackageSuccess: false,
      isDeletePackageFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetPackageState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
