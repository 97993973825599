/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllPackage({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/packages?${payload.params}` : '/packages?limit=0',
    ));
    yield put(Actions.getAllPackageSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllPackageFailure(messages));
    }
  }
}

function* createPackage({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/packages', payload));
    yield put(Actions.createPackageSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createPackageFailure(messages));
    }
  }
}

function* updatePackage({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/packages/${payload.id}`, payload.data));
    yield put(Actions.updatePackageSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updatePackageFailure(messages));
    }
  }
}

function* deletePackage({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/packages/${payload}`));
    yield put(Actions.deletePackageSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deletePackageFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllPackageRequest, getAllPackage);
  yield takeLatest(Actions.createPackageRequest, createPackage);
  yield takeLatest(Actions.updatePackageRequest, updatePackage);
  yield takeLatest(Actions.deletePackageRequest, deletePackage);
}
