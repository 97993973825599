// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllCommentsRequest = createAction('GET_ALL_COMMENTS_REQUEST');
export const getAllCommentsSuccess = createAction('GET_ALL_COMMENTS_SUCCESS');
export const getAllCommentsFailure = createAction('GET_ALL_COMMENTS_FAILURE');

export const createCommentRequest = createAction('CREATE_COMMENT_REQUEST');
export const createCommentSuccess = createAction('CREATE_COMMENT_SUCCESS');
export const createCommentFailure = createAction('CREATE_COMMENT_FAILURE');
export const resetCreateCommentState = createAction('RESET_CREATE_COMMENT_STATE');

export const createUserCommentRequest = createAction('CREATE_USER_COMMENT_REQUEST');
export const createUserCommentSuccess = createAction('CREATE_USER_COMMENT_SUCCESS');
export const createUserCommentFailure = createAction('CREATE_USER_COMMENT_FAILURE');
export const resetCreateUserCommentState = createAction('RESET_CREATE_USER_COMMENT_STATE');

export const updateCommentRequest = createAction('UPDATE_COMMENT_REQUEST');
export const updateCommentSuccess = createAction('UPDATE_COMMENT_SUCCESS');
export const updateCommentFailure = createAction('UPDATE_COMMENT_FAILURE');
export const resetUpdateCommentState = createAction('RESET_UPDATE_COMMENT_STATE');

export const deleteCommentRequest = createAction('DELETE_COMMENT_REQUEST');
export const deleteCommentSuccess = createAction('DELETE_COMMENT_SUCCESS');
export const deleteCommentFailure = createAction('DELETE_COMMENT_FAILURE');
export const resetDeleteCommentState = createAction('RESET_DELETE_COMMENT_STATE');

export const resetCommentState = createAction('RESET_COMMENT_STATE');
