const media = {
  'admin.media.hetmet': 'Library - Media',
  'admin.media.breadcrumb.heading': 'Library',
  'admin.media.breadcrumb.parent': 'Media',
  //
  'admin.media.btn.add-new': 'Add New',
  'admin.media.btn.refresh': 'Refresh',
  'admin.media.noti-msg.add-success': 'Add new successful files',
  'admin.media.noti-msg.edit-success': 'Repair successful file',
  'admin.media.noti-msg.delete-success': 'Delete successful files',
  //
  'admin.media.modal.add-new.title': 'Upload New Media',
  //
  'admin.media.modal.details.title': 'Attachment details',
  'admin.media.modal.details.label.created_at': 'Uploaded at the time',
  'admin.media.modal.details.label.updated_at': 'Recent update at the time',
  'admin.media.modal.details.label.path': 'File name',
  'admin.media.modal.details.label.type': 'File type',
  'admin.media.modal.details.label.size': 'File capacity',
  'admin.media.modal.details.label.name': 'Title',
  'admin.media.modal.details.label.url': 'File URL',
  'admin.media.modal.details.btn-copy-url': 'Copy the URL into temporary memory',
  'admin.media.modal.details.noti-copy-url-success': 'Copy the successful URL',
  'admin.media.modal.details.link-delete': 'Permanently deleted',
  'admin.media.modal.details.note': 'Mandatory schools are marked',
  //
  'admin.media.modal.delete.title': 'Confirmation of file deletion',
  'admin.media.modal.delete.sub-title': 'You will definitely delete this file?',
  'admin.media.modal.delete.noti-failure': 'Delete unsuccessful files!',
  //
  'modal.media.header.title': 'Add Media',
  'modal.media.btn.select-media': 'Choose Media',
  'modal.media.btn.crop-media': 'Edit image',
  'modal.media.dropdown.item.download': 'Download',
  'modal.media.dropdown.item.details': 'See details',
  'modal.media.dropdown.item.delete': 'Delete',
};

const modalCropImage = {
  'modal-crop-image.title': 'Edit Image',
  'modal-crop-image.btn.rotation': 'Rotation',
  'modal-crop-image.btn.zoom': 'Zoom',
  'modal-crop-image.btn.download': 'Download',
  'modal-crop-image.btn.done': 'Done',
  'modal-crop-image.btn.cancel': 'Cancel',
  'modal-crop-image.btn.preview': 'Preview',
  'modal-crop-image.btn.rotate-left': 'Rotate',
  'modal-crop-image.btn.flip-horizontal': 'Flip',
  'modal-crop-image.crop-image-success-title': 'Successful photo editing!',
  'modal-crop-image.crop-image-failure-title': 'Unprecedent photo editing!Please try again later.',
};

const usAdminMediaLangLib = {
  ...media,
  ...modalCropImage,
};

export default usAdminMediaLangLib;
