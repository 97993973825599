// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  //
  isGetAllProductsRequest: false,
  isGetAllProductsSuccess: false,
  isGetAllProductsFailure: false,
  getAllProductsState: {},
  //
  isAddProductRequest: false,
  isAddProductSuccess: false,
  isAddProductFailure: false,
  //
  isUpdateProductRequest: false,
  isUpdateProductSuccess: false,
  isUpdateProductFailure: false,
  //
  isDeleteProductRequest: false,
  isDeleteProductSuccess: false,
  isDeleteProductFailure: false,
  //
  isGetDetailProductRequest: false,
  isGetDetailProductSuccess: false,
  isGetDetailProductFailure: false,
  getDetailProductState: {},
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all products
    [Actions.getAllProductsRequest]: (state) => ({
      ...state,
      isGetAllProductsRequest: true,
      isGetAllProductsSuccess: false,
      isGetAllProductsFailure: false,
    }),
    [Actions.getAllProductsSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllProductsRequest: false,
      isGetAllProductsSuccess: true,
      isGetAllProductsFailure: false,
      getAllProductsState: payload,
    }),
    [Actions.getAllProductsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllProductsRequest: false,
      isGetAllProductsSuccess: false,
      isGetAllProductsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Add product
    [Actions.addProductRequest]: (state) => ({
      ...state,
      isAddProductRequest: true,
      isAddProductSuccess: false,
      isAddProductFailure: false,
    }),
    [Actions.addProductSuccess]: (state, { payload }) => ({
      ...state,
      isAddProductRequest: false,
      isAddProductSuccess: true,
      isAddProductFailure: false,
      getAllProductsState:
        state.getAllProductsState?.meta?.pagination?.current_page === 1
          ? {
            ...state.getAllProductsState,
            data:
              state.getAllProductsState.meta.pagination.count
                === state.getAllProductsState.meta.pagination.per_page
                ? [payload.data].concat(
                  state.getAllProductsState.data.slice(
                    0,
                    state.getAllProductsState.data.length - 1,
                  ),
                )
                : [payload.data].concat(state.getAllProductsState.data),
            meta: {
              ...state.getAllProductsState.meta,
              pagination: {
                ...state.getAllProductsState.meta.pagination,
                count:
                  state.getAllProductsState.meta.pagination.count
                    === state.getAllProductsState.meta.pagination.per_page
                    ? state.getAllProductsState.meta.pagination.per_page
                    : state.getAllProductsState.meta.pagination.count + 1,
                total: state.getAllProductsState.meta.pagination.total + 1,
                total_pages: Math.ceil(
                  (state.getAllProductsState.meta.pagination.total + 1)
                  / state.getAllProductsState.meta.pagination.per_page,
                ),
              },
            },
          }
          : { ...state.getAllProductsState },
    }),
    [Actions.addProductFailure]: (state, { payload }) => ({
      ...state,
      isAddProductRequest: false,
      isAddProductSuccess: false,
      isAddProductFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetAddProductState]: (state) => ({
      ...state,
      isAddProductRequest: false,
      isAddProductSuccess: false,
      isAddProductFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update product
    [Actions.updateProductRequest]: (state) => ({
      ...state,
      isUpdateProductRequest: true,
      isUpdateProductSuccess: false,
      isUpdateProductFailure: false,
    }),
    [Actions.updateProductSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateProductRequest: false,
      isUpdateProductSuccess: true,
      isUpdateProductFailure: false,
      getAllProductsState: {
        ...state.getAllProductsState,
        data: state.getAllProductsState.data.map((item) => item.id === payload.data.id ? payload.data : item),
      },
    }),
    [Actions.updateProductFailure]: (state, { payload }) => ({
      ...state,
      isUpdateProductRequest: false,
      isUpdateProductSuccess: false,
      isUpdateProductFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateProductState]: (state) => ({
      ...state,
      isUpdateProductRequest: false,
      isUpdateProductSuccess: false,
      isUpdateProductFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete product
    [Actions.deleteProductRequest]: (state) => ({
      ...state,
      isDeleteProductRequest: true,
      isDeleteProductSuccess: false,
      isDeleteProductFailure: false,
    }),
    [Actions.deleteProductSuccess]: (state) => ({
      ...state,
      isDeleteProductRequest: false,
      isDeleteProductSuccess: true,
      isDeleteProductFailure: false,
    }),
    [Actions.deleteProductFailure]: (state, { payload }) => ({
      ...state,
      isDeleteProductRequest: false,
      isDeleteProductSuccess: false,
      isDeleteProductFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteProductState]: (state) => ({
      ...state,
      isDeleteProductRequest: false,
      isDeleteProductSuccess: false,
      isDeleteProductFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Get detail product
    [Actions.getDetailProductRequest]: (state) => ({
      ...state,
      isGetDetailProductRequest: true,
      isGetDetailProductSuccess: false,
      isGetDetailProductFailure: false,
    }),
    [Actions.getDetailProductSuccess]: (state, { payload }) => ({
      ...state,
      isGetDetailProductRequest: false,
      isGetDetailProductSuccess: true,
      isGetDetailProductFailure: false,
      getDetailProductState: payload,
    }),
    [Actions.getDetailProductFailure]: (state, { payload }) => ({
      ...state,
      isGetDetailProductRequest: false,
      isGetDetailProductSuccess: false,
      isGetDetailProductFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetDetailProductState]: (state) => ({
      ...state,
      isGetDetailProductRequest: false,
      isGetDetailProductSuccess: false,
      isGetDetailProductFailure: false,
      getDetailProductState: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetProductState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
