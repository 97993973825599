// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllTransactionRequest = createAction('GET_ALL_TRANSACTION_REQUEST');
export const getAllTransactionSuccess = createAction('GET_ALL_TRANSACTION_SUCCESS');
export const getAllTransactionFailure = createAction('GET_ALL_TRANSACTION_FAILURE');

export const createTransactionRequest = createAction('CREATE_TRANSACTION_REQUEST');
export const createTransactionSuccess = createAction('CREATE_TRANSACTION_SUCCESS');
export const createTransactionFailure = createAction('CREATE_TRANSACTION_FAILURE');
export const resetCreateTransactionState = createAction('RESET_CREATE_TRANSACTION_STATE');

export const updateTransactionRequest = createAction('UPDATE_TRANSACTION_REQUEST');
export const updateTransactionSuccess = createAction('UPDATE_TRANSACTION_SUCCESS');
export const updateTransactionFailure = createAction('UPDATE_TRANSACTION_FAILURE');
export const resetUpdateTransactionState = createAction('RESET_UPDATE_TRANSACTION_STATE');

export const deleteTransactionRequest = createAction('DELETE_TRANSACTION_REQUEST');
export const deleteTransactionSuccess = createAction('DELETE_TRANSACTION_SUCCESS');
export const deleteTransactionFailure = createAction('DELETE_TRANSACTION_FAILURE');
export const resetDeleteTransactionState = createAction('RESET_DELETE_TRANSACTION_STATE');

export const resetTransactionState = createAction('RESET_TRANSACTION_STATE');
