// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllTransactionRequest: false,
  isGetAllTransactionSuccess: false,
  isGetAllTransactionFailure: false,
  getAllTransactionState: {},
  //
  isCreateTransactionRequest: false,
  isCreateTransactionSuccess: false,
  isCreateTransactionFailure: false,
  //
  isUpdateTransactionRequest: false,
  isUpdateTransactionSuccess: false,
  isUpdateTransactionFailure: false,
  //
  isDeleteTransactionRequest: false,
  isDeleteTransactionSuccess: false,
  isDeleteTransactionFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all categories
    [Actions.getAllTransactionRequest]: (state) => ({
      ...state,
      isGetAllTransactionRequest: true,
      isGetAllTransactionSuccess: false,
      isGetAllTransactionFailure: false,
    }),
    [Actions.getAllTransactionSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllTransactionRequest: false,
      isGetAllTransactionSuccess: true,
      isGetAllTransactionFailure: false,
      getAllTransactionState: {
        ...payload,
      },
    }),
    [Actions.getAllTransactionFailure]: (state, { payload }) => ({
      ...state,
      isGetAllTransactionRequest: false,
      isGetAllTransactionSuccess: false,
      isGetAllTransactionFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create config
    [Actions.createTransactionRequest]: (state) => ({
      ...state,
      isCreateTransactionRequest: true,
      isCreateTransactionSuccess: false,
      isCreateTransactionFailure: false,
    }),
    [Actions.createTransactionSuccess]: (state, { payload }) => ({
      ...state,
      isCreateTransactionRequest: false,
      isCreateTransactionSuccess: true,
      isCreateTransactionFailure: false,
      getAllTransactionState: {
        ...state.getAllTransactionState,
        data: [
          ...state.getAllTransactionState?.data,
          payload.data,
        ],
      },
    }),
    [Actions.createTransactionFailure]: (state, { payload }) => ({
      ...state,
      isCreateTransactionRequest: false,
      isCreateTransactionSuccess: false,
      isCreateTransactionFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateTransactionState]: (state) => ({
      ...state,
      isCreateTransactionRequest: false,
      isCreateTransactionSuccess: false,
      isCreateTransactionFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update config
    [Actions.updateTransactionRequest]: (state) => ({
      ...state,
      isUpdateTransactionRequest: true,
      isUpdateTransactionSuccess: false,
      isUpdateTransactionFailure: false,
    }),
    [Actions.updateTransactionSuccess]: (state) => ({
      ...state,
      isUpdateTransactionRequest: false,
      isUpdateTransactionSuccess: true,
      isUpdateTransactionFailure: false,
    }),
    [Actions.updateTransactionFailure]: (state, { payload }) => ({
      ...state,
      isUpdateTransactionRequest: false,
      isUpdateTransactionSuccess: false,
      isUpdateTransactionFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateTransactionState]: (state) => ({
      ...state,
      isUpdateTransactionRequest: false,
      isUpdateTransactionSuccess: false,
      isUpdateTransactionFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete config
    [Actions.deleteTransactionRequest]: (state) => ({
      ...state,
      isDeleteTransactionRequest: true,
      isDeleteTransactionSuccess: false,
      isDeleteTransactionFailure: false,
    }),
    [Actions.deleteTransactionSuccess]: (state) => ({
      ...state,
      isDeleteTransactionRequest: false,
      isDeleteTransactionSuccess: true,
      isDeleteTransactionFailure: false,
    }),
    [Actions.deleteTransactionFailure]: (state, { payload }) => ({
      ...state,
      isDeleteTransactionRequest: false,
      isDeleteTransactionSuccess: false,
      isDeleteTransactionFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteTransactionState]: (state) => ({
      ...state,
      isDeleteTransactionRequest: false,
      isDeleteTransactionSuccess: false,
      isDeleteTransactionFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetTransactionState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
